import { faCheck, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, BoxProps } from '@mui/material';

interface IMarkIconProps extends BoxProps {
  mark: boolean;
}

export const MarkIcon = ({ mark, ...boxProps }: IMarkIconProps) => {
  return (
    <Box
      width="24px"
      height="24px"
      display="flex"
      alignItems="center"
      justifyContent="center"
      borderRadius="100%"
      bgcolor={mark ? '#22bb2826' : '#e7222226'}
      {...boxProps}
    >
      <FontAwesomeIcon icon={mark ? faCheck : faX} color={mark ? '#22bb28' : '#de2929'} />
    </Box>
  );
};

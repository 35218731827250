import { Box } from '@mui/material';

import { Spinner } from 'src/components';

export const AppLoading = () => {
  return (
    <Box width="100vw" height="100vh" display="flex" justifyContent="center" alignItems="center">
      <Spinner type="dots" mr="5px" /> Loading Alfred Admin panel app...
    </Box>
  );
};

import styled from '@emotion/styled';

import { Link, Table, TableBody, TableCell, TableHead, TableHeadCell, TableRow } from 'src/components';
import { IGetCodesTeamRes } from 'src/types';

const StyledTableRow = styled(TableRow)`
  background-color: #f2f6f7;
`;

interface IAccessCodeDetailsRowProps {
  code: IGetCodesTeamRes;
}

export const AccessCodeDetailsRow = ({ code }: IAccessCodeDetailsRowProps) => {
  const { id, name, seats, users } = code;

  return (
    <>
      <TableRow>
        <TableCell>
          <Link to={`/support/teams/${id}/general-information`}>
            {name} ({users.length || 0}/{seats || 0})
          </Link>
        </TableCell>
        <TableCell>
          <Table placeholder={!users.length && 'No users found'}>
            <TableHead secondary>
              <TableRow>
                <TableHeadCell secondary>Name</TableHeadCell>
                <TableHeadCell secondary>Email</TableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map(({ id, name, email }) => (
                <StyledTableRow key={id}>
                  <TableCell>
                    <Link to={`/support/users/${id}/details`}>{name}</Link>
                  </TableCell>
                  <TableCell>{email}</TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableCell>
      </TableRow>
    </>
  );
};

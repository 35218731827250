import ReactToggle, { ToggleProps as ReactToggleProps } from 'react-toggle';
import styled from '@emotion/styled';

import { propsBlackList } from 'src/helpers';

interface IStyledToggleProps extends ReactToggleProps {
  checkedBgColor: string;
  uncheckedBgColor: string;
}

const BLACK_LIST_PROPS = ['uncheckedBgColor', 'checkedBgColor'];

const StyledToggle = styled(ReactToggle, propsBlackList(BLACK_LIST_PROPS))<IStyledToggleProps>`
  &:not(.react-toggle--disabled) {
    &:hover {
      .react-toggle-track {
        background-color: ${(props) => props.uncheckedBgColor};
      }
    }
  }

  .react-toggle-track {
    width: 45px;
    height: 20px;
    border-radius: 9px;
    background-color: ${(props) => props.uncheckedBgColor};
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  .react-toggle-thumb {
    box-shadow: none !important;
  }

  .react-toggle-thumb {
    height: 18px;
    width: 18px;
    border: 2px solid ${(props) => props.uncheckedBgColor};
  }

  &.react-toggle--checked {
    &:hover {
      .react-toggle-track {
        background-color: ${(props) => props.checkedBgColor};
      }
    }

    .react-toggle-track {
      background-color: ${(props) => props.checkedBgColor};
    }

    .react-toggle-thumb {
      border-color: ${(props) => props.checkedBgColor};
    }
  }
`;

interface IToggleProps extends ReactToggleProps {
  name?: string;
  defaultChecked?: boolean;
  checkedBgColor?: string;
  uncheckedBgColor?: string;
}

export const Toggle = ({ checkedBgColor = '#2361fc', uncheckedBgColor = '#c4c6d5', ...restProps }: IToggleProps) => {
  return (
    <StyledToggle
      checkedBgColor={checkedBgColor}
      uncheckedBgColor={uncheckedBgColor}
      data-bgcolor-checked={checkedBgColor}
      data-bgcolor-unchecked={uncheckedBgColor}
      icons={false}
      {...restProps}
    />
  );
};

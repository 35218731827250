import { components as ReactSelectComponents, GroupBase, SelectComponentsConfig, StylesConfig } from 'react-select';
import styled from '@emotion/styled';
import { faCaretDown, faCaretUp, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import { ISelectOption, TJustifyContent } from 'src/types';
import { Typography } from '../ui';

interface IDefaultStyles {
  minWidth?: string;
  maxWidth?: string;
  valueContainerPosition?: TJustifyContent;
}

export const defaultStyles = ({
  minWidth,
  maxWidth,
  valueContainerPosition,
}: IDefaultStyles): StylesConfig<ISelectOption<unknown>, boolean, GroupBase<ISelectOption<unknown>>> => ({
  menu: (provided) => ({
    ...provided,
    minWidth: minWidth || '160px',
    maxWidth: maxWidth || 'auto',
    backgroundColor: '#ffffff',
    boxShadow: '#0000002c 0px 6px 12px',
    backgroundClip: 'padding-box',
    border: 'none',
    borderRadius: '4px',
    padding: '10px',
    color: '#262a79',
  }),
  menuList: (provided) => ({
    ...provided,
    padding: '0px',
  }),
  control: (provided) => ({
    ...provided,
    minWidth: minWidth || '160px',
    maxWidth: maxWidth || 'auto',
    backgroundColor: '#ffffff',
    border: '1px solid #ccc',
    borderRadius: '4px',
    boxShadow: 'none',
    display: 'flex',
    fontSize: '14px',
    minHeight: '30px',
    padding: '0 5px',
    ['&:hover']: {
      outline: 'none',
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: '32px',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: '#ffffff !important',
    cursor: 'pointer',

    svg: {
      filter: 'drop-shadow(0 0 1px #000)',
    },

    ['&:hover']: {
      backgroundColor: 'transparent',
      color: '#ffffff',
    },
  }),
  option: (provided, { isSelected }) => ({
    ...provided,
    backgroundColor: 'transparent !important',
    cursor: 'pointer',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '14px',
    lineHeight: '14px',
    padding: '10px',
    transition: '0.2s',
    color: isSelected ? '#2361fc' : '#262a79',
    ['&:hover']: {
      color: '#2361fc',
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    display: 'flex',
    justifyContent: valueContainerPosition || 'flex-start',
    padding: '0 0 0 4px',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: 5,
  }),
});

const RemoveAll = styled(Box)`
  pointer-events: all !important;
  color: #999;
  cursor: pointer;
  font-size: 18px;
  margin-top: -3px;
`;

interface IDefaultComponents {
  label?: string;
}

export const defaultComponents = ({
  label,
}: IDefaultComponents): SelectComponentsConfig<ISelectOption<unknown>, boolean, GroupBase<ISelectOption<unknown>>> => ({
  DropdownIndicator: (props) => (
    <ReactSelectComponents.DropdownIndicator {...props}>
      <Box width="16px" display="flex" justifyContent="center">
        <FontAwesomeIcon size="sm" icon={props.selectProps.menuIsOpen ? faCaretUp : faCaretDown} color="#2361fc" />
      </Box>
    </ReactSelectComponents.DropdownIndicator>
  ),
  Option: ({ children, ...props }) => (
    <ReactSelectComponents.Option {...props}>
      {children} {props.isSelected && <FontAwesomeIcon size="xs" icon={faCheck} />}
    </ReactSelectComponents.Option>
  ),
  ValueContainer: ({ children, ...props }) => (
    <ReactSelectComponents.ValueContainer {...props}>
      {label} <Box display="inherit">{children}</Box>
    </ReactSelectComponents.ValueContainer>
  ),
  NoOptionsMessage: ({ ...props }) => (
    <ReactSelectComponents.NoOptionsMessage {...props}>
      <Typography>No options</Typography>
    </ReactSelectComponents.NoOptionsMessage>
  ),
  ClearIndicator: (props) => (
    <ReactSelectComponents.ClearIndicator {...props}>
      {props.hasValue && <RemoveAll>×</RemoveAll>}
    </ReactSelectComponents.ClearIndicator>
  ),
});

import styled from '@emotion/styled';
import { Tooltip as MuiTooltip, TooltipProps } from '@mui/material';

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip
    componentsProps={{ popper: { style: { zIndex: 9999999999, maxWidth: '350px', pointerEvents: 'none' } } }}
    classes={{ tooltip: className }}
    arrow
    placement="top"
    {...props}
  >
    {props.children}
  </MuiTooltip>
))`
  background-color: #000;
  font-size: 12px;
  line-height: 1.4;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  text-align: center;
  max-width: 350px;
  font-weight: 400;
  opacity: 0.9 !important;

  .MuiTooltip-arrow {
    &:before {
      background-color: #000;
    }
  }
`;

export const Tooltip = ({ children, ...props }: TooltipProps) => {
  return <StyledTooltip {...props}>{children}</StyledTooltip>;
};

import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';

import { Link, TableCell, TableRow, Typography } from 'src/components';
import { useDeleteAdmin } from 'src/reactQueries';
import { openModal } from 'src/store';
import { IAdmin } from 'src/types';

interface ManageAdminsTableRowProps {
  admin: IAdmin;
}

export const ManageAdminsTableRow = ({ admin }: ManageAdminsTableRowProps) => {
  const dispatch = useDispatch();
  const { createdAt, email, id, name } = admin ?? {};

  const { deleteAdmin } = useDeleteAdmin();

  const onRemoveUser = () =>
    dispatch(
      openModal({
        headerText: `Remove Admin access?`,
        descriptionText: `Are you sure you would like to remove Admin access for ${name}?`,
        onConfirm: () => deleteAdmin(id),
      }),
    );

  return (
    <>
      <TableRow hoverBgColor="#ebecf2">
        <TableCell>
          <Link to={`/support/users/${id}/details`}>{name}</Link>
        </TableCell>
        <TableCell>{email}</TableCell>
        <TableCell>{id}</TableCell>
        <TableCell>{dayjs(createdAt).format('DD MMM, YYYY')}</TableCell>
        <TableCell contentStyle={{ width: '100%' }}>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Link to={`/manage-admins/${id}`} medium>
              Edit
            </Link>
            <Typography onClick={onRemoveUser} color="#FF4D4F" medium pointer underline>
              Delete
            </Typography>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};

import { CancelSubscriptionReason } from '../enums';

export const cancelReasonsOptions = Object.values(CancelSubscriptionReason).reduce(
  (gen, reason) => {
    gen.push({ label: reason, value: reason });

    return gen;
  },
  [{ label: 'All', value: 'all' }] as Array<{ value: CancelSubscriptionReason | 'all'; label: string }>,
);

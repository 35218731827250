import { useMemo, useState } from 'react';
import styled from '@emotion/styled';

import {
  Block,
  BlockHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Typography,
} from 'src/components';
import { getPercentage, separateNumWithComma, sortTable } from 'src/helpers';
import { useAppSelector } from 'src/hooks';
import { useGetPauseReasonsStats } from 'src/reactQueries';
import { TSortOrder } from 'src/types';

const StyledTable = styled(Table)`
  border-collapse: separate;
`;

const StyledTableHeadCell = styled(TableHeadCell)`
  padding: 14px 10px !important;
`;

type TSortFields = 'seats' | 'value';

export const PauseReasonsStats = () => {
  const { fromDate, toDate } = useAppSelector(({ subscriptionPauses }) => subscriptionPauses.subscriptionPausesRequest);

  const { pauseReasonsStats, isLoading } = useGetPauseReasonsStats({ toDate, fromDate });

  const [sortOrder, setSortOrder] = useState<TSortOrder>('DESC');
  const [sortField, setSortField] = useState<TSortFields>('value');

  const stats = pauseReasonsStats?.data || [];

  const totalSeats = pauseReasonsStats?.totalSeats || 0;
  const totalCustomers = pauseReasonsStats?.total || 0;

  const sortedStats = useMemo(() => sortTable(stats, sortField, sortOrder), [sortField, sortOrder, stats]);

  const sortingProps = {
    sortField,
    sortOrder,
    onSortFieldChange: (field: TSortFields, order: TSortOrder) => {
      setSortField(field);
      setSortOrder(order);
    },
  };

  return (
    <Block>
      <BlockHeader py="0" height="64px" bgcolor="white">
        <Typography medium fontSize="20px">
          Pause Reasons Stats
        </Typography>
      </BlockHeader>
      <StyledTable placeholder={!stats?.length && 'No user summary found'} processing={isLoading} disabled={isLoading}>
        <TableHead secondary>
          <TableRow>
            <StyledTableHeadCell secondary width="70%" />
            <StyledTableHeadCell name="customers" sorting={sortingProps} secondary>
              Customers
            </StyledTableHeadCell>
            <StyledTableHeadCell name="seats" sorting={sortingProps} justifyContent="flex-end" secondary>
              Users
            </StyledTableHeadCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {sortedStats.map(({ label, seats, customers }) => (
            <TableRow key={label}>
              <TableCell>
                <Typography>{label}</Typography>
              </TableCell>
              <TableCell>
                <Typography>
                  {separateNumWithComma(customers)}{' '}
                  <Typography fontSize="12px" inline>
                    ({getPercentage(customers, totalCustomers)}%)
                  </Typography>
                </Typography>
              </TableCell>
              <TableCell justifyContent="flex-end">
                <Typography>
                  {separateNumWithComma(seats)}{' '}
                  <Typography fontSize="12px" inline>
                    ({getPercentage(seats, totalSeats)}%)
                  </Typography>
                </Typography>
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell>
              <Typography semibold>Total</Typography>
            </TableCell>
            <TableCell>
              <Typography semibold>{separateNumWithComma(totalCustomers)} </Typography>
            </TableCell>
            <TableCell justifyContent="flex-end">
              <Typography semibold>{separateNumWithComma(totalSeats)}</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </StyledTable>
    </Block>
  );
};

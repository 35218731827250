import { adminApi } from 'src/api/axios';
import {
  IExtendTrialReq,
  IGetTeamInvitesReq,
  IGetTeamInvitesRes,
  IGetTeamMembersReq,
  IGetTeamMembersRes,
  IGetTeamRes,
  IGetTeamsReq,
  IGetTeamsRes,
  IGetTeamStripeInfoReq,
  IGetTeamStripeInfoRes,
  IReferredTeamsReq,
  IReferredTeamsRes,
  ITeamWhiteLabel,
  IUpdateTeamPlanDetails,
} from 'src/types';

export const getTeams = (params: IGetTeamsReq): Promise<IGetTeamsRes> => adminApi.get(`/teams`, { params });

export const getTeam = (teamId: number): Promise<IGetTeamRes> => adminApi.get(`/teams/${teamId}`);

export const getTeamMembers = (teamId: number, params: IGetTeamMembersReq): Promise<IGetTeamMembersRes> =>
  adminApi.get(`/teams/${teamId}/members`, { params });

export const getTeamStripeInfo = (teamId: number, params: IGetTeamStripeInfoReq): Promise<IGetTeamStripeInfoRes> =>
  adminApi.get(`/teams/${teamId}/stripe-info`, { params });

export const getTeamInvites = (teamId: number, params: IGetTeamInvitesReq): Promise<IGetTeamInvitesRes> =>
  adminApi.get(`/teams/${teamId}/invites`, { params });

export const postExtendTrial = (teamId: number, body: IExtendTrialReq) =>
  adminApi.post(`/teams/${teamId}/extend-trial`, body);

export const deleteTeamMember = (teamId: number, memberId: number) =>
  adminApi.delete(`/teams/${teamId}/members/${memberId}`);

export const deleteInvite = (inviteId: number) => adminApi.delete(`/teams/invites/${inviteId}`);

export const patchUpdateTeamStripeInfo = (teamId: number) => adminApi.patch(`/teams/${teamId}/stripe-info`);

export const getTeamWhiteLabel = (teamId: number): Promise<ITeamWhiteLabel> =>
  adminApi.get(`/teams/${teamId}/whitelabel`);

export const getReferredTeams = (teamId: number, params: IReferredTeamsReq): Promise<IReferredTeamsRes> =>
  adminApi.get(`/teams/${teamId}/referred-teams`, { params });

export const toggleWhiteLabelAdvancedSettings = (teamId: number) =>
  adminApi.patch(`/teams/${teamId}/whitelabel/toggle-advanced-settings`);

export const returnUserToTeam = (teamId: number, userId: number): Promise<void> =>
  adminApi.post(`/teams/${teamId}/users/${userId}/return-to-team`);

export const updateTeamPlanDetails = (teamId: number, body: IUpdateTeamPlanDetails) =>
  adminApi.patch(`/teams/${teamId}/plan-details`, body);

import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getAdmin } from 'src/api';
import { IAdmin } from 'src/types';

export const useGetAdmin = <TData extends IAdmin>(
  userId: number,
  options?: UseQueryOptions<IAdmin, AxiosError, TData>,
) => {
  const { data, ...rest } = useQuery<IAdmin, AxiosError, TData>(['get-admin', userId], () => getAdmin(userId), options);

  return { admin: data, ...rest };
};

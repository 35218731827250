import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import { Block, Button, InfoLabel, InfoLabelWrapper } from 'src/components';
import { TEAM_LEVEL_ICON } from 'src/constants/teams';
import { TeamDetailsLayout } from 'src/containers';
import { ModalTypes } from 'src/enums';
import { useGetTeamInfo } from 'src/reactQueries';
import { openModal } from 'src/store';

export const GeneralInformation = () => {
  const dispatch = useDispatch();
  const { teamId } = useParams();
  const TEAM_ID = Number(teamId);

  const { teamInfo } = useGetTeamInfo(TEAM_ID);
  const {
    id,
    isTrialExtended,
    name,
    plan,
    referralTeam,
    seats,
    trialEndsAt,
    billingType,
    isSubscriptionPaused,
    subscriptionPausedAt,
    level,
    customerAge = 0,
  } = teamInfo ?? {};

  const onExtend = () =>
    dispatch(
      openModal({
        type: ModalTypes.EXTEND_TRIAL,
        headerText: 'Extend trial',
        params: {
          teamId: id,
        },
      }),
    );

  return (
    <TeamDetailsLayout>
      <Block p="24px">
        <InfoLabelWrapper>
          <InfoLabel label="Team Name">{name}</InfoLabel>
          <InfoLabel label="Team Key">{id}</InfoLabel>
          <InfoLabel label="Plan">{plan}</InfoLabel>
          <InfoLabel label="Billing cycle">{billingType}</InfoLabel>
          <InfoLabel label="Number of Seats">{seats}</InfoLabel>
          <InfoLabel label="Subscription Paused">
            {isSubscriptionPaused ? dayjs(subscriptionPausedAt).format('MM-DD-YYYY HH:mm') : 'No'}
          </InfoLabel>
          <InfoLabel label="Referral Team">{referralTeam}</InfoLabel>
          <InfoLabel label="Trial Ending Date">
            <Box display="flex" gap="20px">
              {trialEndsAt ? dayjs(Number(trialEndsAt) * 1000).format('DD MMM, YYYY') : '---'}{' '}
              <Button variant="whiteBlue" size={12} lineHeight="14px" onClick={onExtend}>
                Extend
              </Button>
            </Box>
          </InfoLabel>
          <InfoLabel label="Trial Extended">{isTrialExtended ? 'Yes' : 'No'}</InfoLabel>
          <InfoLabel label="Customer Age">
            {customerAge} day{customerAge > 1 && 's'} {level && `[${TEAM_LEVEL_ICON[level]} ${level}]`}
          </InfoLabel>
        </InfoLabelWrapper>
      </Block>
    </TeamDetailsLayout>
  );
};

import dayjs from 'dayjs';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import {
  Block,
  Button,
  InfoLabel,
  InfoLabelWrapper,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TablePagination,
  TableRow,
  Typography,
} from 'src/components';
import { TeamDetailsLayout } from 'src/containers';
import { Plan } from 'src/enums';
import { useGetTeamInfo, useGetTeamStripeInfo, useUpdateStripeInfo } from 'src/reactQueries';
import { ITablePagination, TSortOrder, TTeamStripeInfoSortFields } from 'src/types';

export const StripeInfo = () => {
  const { teamId } = useParams();
  const TEAM_ID = Number(teamId);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState<TSortOrder>('ASC');
  const [sortField, setSortField] = useState<TTeamStripeInfoSortFields>('createdAt');

  const { teamInfo } = useGetTeamInfo(TEAM_ID);
  const { updateStripeInfo } = useUpdateStripeInfo(TEAM_ID);
  const { teamStripeInfo, isLoading } = useGetTeamStripeInfo(TEAM_ID, { page, perPage, sortField, sortOrder });
  const { actions = [], info, total } = teamStripeInfo ?? {};
  const { nextInvoiceCreatedAt, stripeId } = info ?? {};

  const isUpdateButtonDisabled = teamInfo && !teamInfo?.hasCustomPlan && teamInfo.plan === Plan.TRIAL;

  const sortingProps = {
    sortField,
    sortOrder,
    onSortFieldChange: (field: TTeamStripeInfoSortFields, order: TSortOrder) => {
      setSortField(field);
      setSortOrder(order);
    },
  };

  const paginationChangeHandler = (pagination: ITablePagination) => {
    setPage(pagination.page);
    setPerPage(pagination.perPage);
  };

  return (
    <TeamDetailsLayout>
      <Block p="24px">
        <InfoLabelWrapper>
          <InfoLabel label="Stripe ID" noneValue={!stripeId}>
            <Link external openNewTab to={`https://dashboard.stripe.com/customers/${stripeId}`}>
              {stripeId}
            </Link>
          </InfoLabel>
          <InfoLabel label="Date of the next invoice" noneValue={!nextInvoiceCreatedAt}>
            {dayjs(Number(nextInvoiceCreatedAt) * 1000).format('DD MMM, YYYY')}
          </InfoLabel>
          <InfoLabel label="Stripe Info">
            <Button
              variant="whiteBlue"
              size={12}
              lineHeight="16px"
              maxWidth="70px"
              tooltip={teamInfo?.isSubscriptionPaused && 'Subscription is paused'}
              disabled={isUpdateButtonDisabled || teamInfo?.isSubscriptionPaused}
              onClick={() => updateStripeInfo()}
              wrapperProps={{ width: 'min-content' }}
            >
              Update
            </Button>
          </InfoLabel>
        </InfoLabelWrapper>
      </Block>
      <Block>
        <Table
          placeholder={!total && (stripeId ? 'No actions found' : 'No stripe info found')}
          processing={isLoading}
          disabled={isLoading}
        >
          <TableHead secondary>
            <TableRow>
              <TableHeadCell secondary name="createdAt" sorting={sortingProps}>
                Time
              </TableHeadCell>
              <TableHeadCell secondary name="description" sorting={sortingProps}>
                Actions
              </TableHeadCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {actions.map(({ description, id, createdAt }) => (
              <TableRow key={id}>
                <TableCell>
                  <Box display="flex" gap="10px">
                    <Typography medium color="#000">
                      {dayjs(createdAt).format('DD MMM, YYYY')}
                    </Typography>
                    <Typography>{dayjs(createdAt).format('HH:mm')}</Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Typography medium>{description}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Box mx="10px">
          <TablePagination page={page} perPage={perPage} total={total} onPaginationChange={paginationChangeHandler} />
        </Box>
      </Block>
    </TeamDetailsLayout>
  );
};

import { adminApi } from 'src/api/axios';
import { AdminPermissionEnum } from 'src/enums/admins';
import { IAdmin, ICreateAdminReq, IGetAllAdminsReq, IGetAllAdminsRes } from 'src/types';

export const getAdmins = (params: IGetAllAdminsReq): Promise<IGetAllAdminsRes> => adminApi.get(`/admins`, { params });

export const getAdmin = (userId: number): Promise<IAdmin> => adminApi.get(`/admins/${userId}`);

export const createAdmin = (data: ICreateAdminReq): Promise<IAdmin> => adminApi.post(`/admins`, data);

export const updateAdmin = (userId: number, data: { permissions: AdminPermissionEnum[] }): Promise<IAdmin> =>
  adminApi.patch(`/admins/${userId}`, data);

export const deleteAdmin = (userId: number): Promise<void> => adminApi.delete(`/admins/${userId}`);

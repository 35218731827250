import { TSortOrder } from 'src/types';

export const propsBlackList = (blackList: string[]) => ({
  shouldForwardProp: (prop: string) => !blackList.includes(prop),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sortTable = <T extends Record<string, any>>(data: T[], sortField: string, sortOrder: TSortOrder): T[] => {
  return data?.sort((a, b) => {
    const aValue = Number(a[sortField]) || 0;
    const bValue = Number(b[sortField]) || 0;

    return sortOrder === 'ASC' ? aValue - bValue : bValue - aValue;
  });
};

import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { removeUser } from 'src/api';
import { showToast } from '../../store';

export const useRemoveUser = (userId: number, onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { mutate, ...rest } = useMutation(['remove-user', userId], () => removeUser(userId), {
    onSuccess: async () => {
      await queryClient
        .invalidateQueries(['get-all-users'], { refetchInactive: true, exact: false }, {})
        .catch(console.error);

      dispatch(
        showToast({
          type: 'info',
          message: 'Removing a user is in process',
        }),
      );

      onSuccess?.();
    },
    onError: (err) => {
      dispatch(
        showToast({
          type: 'error',
          message: (err as Error)?.message ?? 'Something went wrong',
        }),
      );
    },
  });

  return { removeUser: mutate, ...rest };
};

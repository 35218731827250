import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { removeUserEmailIntegration } from 'src/api';

export const useRemoveUserEmailIntegration = (userId: number, options?: UseMutationOptions<void, AxiosError>) => {
  const queryClient = useQueryClient();

  const { mutate, ...rest } = useMutation<void, AxiosError>(
    ['remove-email-integration', userId],
    () => removeUserEmailIntegration(userId),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries(['get-user-email-info', userId]);
        options?.onSuccess?.(data, variables, context);
      },
    },
  );

  return { removeEmailIntegration: mutate, ...rest };
};

import { useMutation } from 'react-query';

import { exportCancellationsCsv } from 'src/api';

export const useExportCancellationsCsv = () => {
  const { mutate, isLoading } = useMutation(['export-cancellations-csv'], exportCancellationsCsv, {
    onSuccess: (data) => {
      const blobFile = new Blob([data], { type: 'text/csv' });
      const href = URL.createObjectURL(blobFile);
      const link = document.createElement('a');

      link.href = href;
      link.setAttribute('download', 'cancellations.csv');
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    },
  });

  return { exportCancellationsCsv: mutate, isLoading };
};

import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { updateTeamPlanDetails } from 'src/api';
import { closeModal, showToast } from 'src/store';
import { IUpdateTeamPlanDetails } from 'src/types';

export const useUpdateTeamPlanDetails = (teamId: number) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { mutate, ...rest } = useMutation(
    ['update-team-plan-details', teamId],
    (data: IUpdateTeamPlanDetails) => updateTeamPlanDetails(teamId, data),
    {
      onSuccess: async () => {
        dispatch(closeModal());

        await queryClient.invalidateQueries(['get-all-users'], { exact: false });
        await queryClient.invalidateQueries(['get-all-teams'], { exact: false, refetchInactive: true });
        await queryClient.invalidateQueries(['get-team-info', teamId], { exact: false, refetchInactive: true });
        await queryClient.invalidateQueries(['get-user-team-info']);
      },
      onError: (err) => {
        dispatch(
          showToast({
            type: 'error',
            message: (err as Error)?.message ?? 'Something went wrong',
          }),
        );
      },
    },
  );

  return { updatePlanDetails: mutate, ...rest };
};

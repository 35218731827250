import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getPauseReasons } from 'src/api/';
import { IGetPauseReasonsRequest, IGetPauseReasonsResponse } from 'src/types';

export const useGetPauseReasons = <TData extends IGetPauseReasonsResponse>(
  params: IGetPauseReasonsRequest,
  options?: UseQueryOptions<IGetPauseReasonsResponse, AxiosError, TData>,
) => {
  return useQuery<IGetPauseReasonsResponse, AxiosError, TData>(
    ['get-pause-reasons-stats', JSON.stringify(params)],
    () => getPauseReasons(params),
    options,
  );
};

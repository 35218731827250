import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { faCircleMinus, faExternalLinkAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import { Block, BlockFooter, Button, InfoLabel, InfoLabelWrapper, Link, SmallCard } from 'src/components';
import { UserDetailsLayout } from 'src/containers';
import { convertTime } from 'src/helpers';
import {
  useGetUserCloudBotInfo,
  useGetUserInfo,
  usePauseCloudBot,
  useRemoveCloudBot,
  useRemoveCloudBotProxy,
  useRestartCloudBot,
  useUnpauseCloudBot,
} from 'src/reactQueries';
import { openModal } from 'src/store';

const NO_CONNECTED_MESSAGE = 'Cloud bot is not connected';

export const Cloud = () => {
  const dispatch = useDispatch();

  const { userId } = useParams();
  const USER_ID = Number(userId);

  const { userInfo } = useGetUserInfo(USER_ID);
  const { pauseCloudBot } = usePauseCloudBot(USER_ID);
  const { removeCloudBot, isLoading } = useRemoveCloudBot(USER_ID);
  const { removeProxy } = useRemoveCloudBotProxy(USER_ID);
  const { restartCloudBot } = useRestartCloudBot(USER_ID);
  const { unpauseCloudBot } = useUnpauseCloudBot(USER_ID);
  const { cloudBotInfo } = useGetUserCloudBotInfo(USER_ID);
  const {
    currentSession,
    isInWorkingHours,
    id,
    isPaused,
    linkedInEmail,
    maxConnectsPerDay,
    maxEmailsPerDay,
    maxEventMessagesPerDay,
    maxGroupMessagesPerDay,
    maxInMailsPerDay,
    maxMessagesPerDay,
    maxTwitterFollowsPerDay,
    maxTwitterMessagesPerDay,
    maxViewsPerDay,
    operatingHours,
    status,
    timezone,
  } = cloudBotInfo ?? {};

  const dailyLimits = [
    {
      label: 'Views',
      value: maxViewsPerDay,
    },
    {
      label: 'Invites',
      value: maxConnectsPerDay,
    },
    {
      label: 'Messages',
      value: maxMessagesPerDay,
    },
    {
      label: 'Group Messages',
      value: maxGroupMessagesPerDay,
    },
    {
      label: 'Event Messages',
      value: maxEventMessagesPerDay,
    },
    {
      label: 'Inmails',
      value: maxInMailsPerDay,
    },
    {
      label: 'Emails',
      value: maxEmailsPerDay,
    },
    {
      label: 'Twitter Follows',
      value: maxTwitterFollowsPerDay,
    },
    {
      label: 'Twitter DM’s',
      value: maxTwitterMessagesPerDay,
    },
  ];

  const omRemoveProxy = () =>
    dispatch(
      openModal({
        headerText: 'Remove proxy',
        descriptionText: `Are you sure you want to remove Proxy from ${userInfo?.name}?`,
        onConfirm: removeProxy,
      }),
    );

  const omRemoveCloudBot = () =>
    dispatch(
      openModal({
        headerText: 'Remove Cloud bot',
        descriptionText: `Are you sure you want to remove Cloud bot from ${userInfo?.name}?`,
        onConfirm: removeCloudBot,
      }),
    );

  return (
    <UserDetailsLayout>
      <Block>
        <Box p="24px">
          <InfoLabelWrapper columns={6}>
            <InfoLabel label="Bot Key">{id}</InfoLabel>
            <InfoLabel label="LinkedIn Email">{linkedInEmail}</InfoLabel>
            <InfoLabel label="Current Session">{currentSession}</InfoLabel>
            <InfoLabel label="Status">{status}</InfoLabel>
            <InfoLabel label="In working hours" noneValue={!isInWorkingHours}>
              {String(Boolean(isInWorkingHours))}
            </InfoLabel>
            <InfoLabel label="Timezone" noneValue={!timezone?.utcOffsetStr}>
              GMT{timezone?.utcOffsetStr}
            </InfoLabel>
          </InfoLabelWrapper>
          <InfoLabel label="Daily limits" mt="20px">
            <Box display="flex" flexWrap="wrap" gap="16px" mt="4px">
              {dailyLimits?.map(({ label, value }) => (
                <SmallCard key={label} label={label} minWidth="128px">
                  {value ?? 0}/1000
                </SmallCard>
              ))}
            </Box>
          </InfoLabel>
          {operatingHours?.scheduled && (
            <InfoLabel label="Working Hours" mt="20px">
              <Box display="flex" flexWrap="wrap" gap="16px" mt="4px">
                {operatingHours?.scheduled.map(({ day, endHour, startHour }, index) => (
                  <SmallCard key={index} label={`Day ${day}`} minWidth="128px">
                    {convertTime(Number(startHour))} - {convertTime(Number(endHour))}
                  </SmallCard>
                ))}
              </Box>
            </InfoLabel>
          )}
        </Box>
        <BlockFooter justifyContent="space-between">
          <Box display="flex" gap="48px">
            <Link
              fontSize="16px"
              color="#337ab7"
              underline={false}
              disabled={!id}
              tooltip={!id && NO_CONNECTED_MESSAGE}
              onClick={() => (isPaused ? unpauseCloudBot() : pauseCloudBot())}
            >
              {isPaused ? 'Run Bot' : 'Pause Bot'}
            </Link>
            <Link
              fontSize="16px"
              color="#337ab7"
              underline={false}
              disabled={!id}
              tooltip={!id && NO_CONNECTED_MESSAGE}
              onClick={() => restartCloudBot()}
            >
              Restart Bot
            </Link>

            <Link
              external
              openNewTab
              fontSize="16px"
              color="#337ab7"
              underline={false}
              to={`https://sc.meetalfred.com/accounts/viewLogs?userId=${USER_ID}`}
            >
              <FontAwesomeIcon icon={faExternalLinkAlt} size="xs" /> View Log
            </Link>
          </Box>
          <Box display="flex" gap="12px">
            <Button
              disabled={!id}
              tooltip={!id && NO_CONNECTED_MESSAGE}
              variant="red"
              gap="10px"
              onClick={omRemoveProxy}
            >
              <FontAwesomeIcon icon={faCircleMinus} /> Remove proxy
            </Button>
            <Button
              disabled={!id}
              tooltip={!id && NO_CONNECTED_MESSAGE}
              variant="red"
              gap="10px"
              onClick={omRemoveCloudBot}
              processing={isLoading}
            >
              <FontAwesomeIcon icon={faTrashAlt} /> Remove cloud bot
            </Button>
          </Box>
        </BlockFooter>
      </Block>
    </UserDetailsLayout>
  );
};

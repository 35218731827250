import axios, { AxiosError, AxiosInstance } from 'axios';
import Cookies from 'universal-cookie';

import { ADMIN_API_URL, APP_API_URL, COOKIE_TOKEN_NAME, MAIN_APP_URL } from 'src/constants';
import { ICustomAxiosError } from 'src/types';

const cookies = new Cookies();
const APP_API_URL_ROUTE = `${APP_API_URL}/api/v1`;
const ADMIN_API_URL_ROUTE = `${ADMIN_API_URL}/api/v1`;

const appApi = axios.create({ baseURL: APP_API_URL_ROUTE });
const adminApi = axios.create({ baseURL: ADMIN_API_URL_ROUTE });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const setInterceptorRequest = (api: AxiosInstance) =>
  api.interceptors.request.use((config) => {
    const accessToken = cookies.get(COOKIE_TOKEN_NAME);

    config.headers.setAuthorization(`Bearer ${accessToken}`);
    config.headers.setAccept('application/json');

    return config;
  });

const setInterceptorResponse = (api: AxiosInstance) =>
  api.interceptors.response.use(
    (response) => response.data,
    (error: AxiosError<ICustomAxiosError>) => {
      if (error?.response?.data?.statusCode === 401) {
        location.assign(MAIN_APP_URL);
      }

      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        name: error.name,
        message: Array.isArray(error?.response?.data?.message)
          ? error?.response?.data?.message[0]
          : error?.response?.data?.message,
        statusCode: error?.response?.data?.statusCode,
      });
    },
  );

setInterceptorRequest(appApi);
setInterceptorRequest(adminApi);

setInterceptorResponse(appApi);
setInterceptorResponse(adminApi);

export { ADMIN_API_URL, adminApi, APP_API_URL, appApi };

import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getUserInfo } from 'src/api';
import { IGetUserInfoRes } from 'src/types';

export const useGetUserInfo = <TData extends IGetUserInfoRes>(
  userId: number,
  options?: UseQueryOptions<IGetUserInfoRes, AxiosError, TData>,
) => {
  const { data, ...rest } = useQuery<IGetUserInfoRes, AxiosError, TData>(
    ['get-user-info', userId],
    () => getUserInfo(userId),
    options,
  );

  return { userInfo: data, ...rest };
};

import { ReactNode } from 'react';
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import { Box, CircularProgress } from '@mui/material';
import MuiTable, { TableProps } from '@mui/material/Table';

const StyledTable = styled(MuiTable, {
  shouldForwardProp: (prop) => isPropValid(prop),
})<{ disabled?: boolean }>`
  table-layout: fixed;
  background-color: #ffffff;
  border-radius: 5px;
  width: ${({ width }) => width ?? '100%'} !important;

  ${({ disabled }) => disabled && 'opacity: 0.5; pointer-events: none;'}
`;

const Placeholder = styled(Box)`
  padding: 30px 15px 20px;
  font-size: 14px;
  color: #808080;
  text-align: center;
`;

interface ITableProps extends Omit<TableProps, 'placeholder'> {
  disabled?: boolean;
  placeholder?: false | ReactNode;
  processing?: boolean;
  processingContent?: ReactNode;
  headerTitle?: ReactNode;
  headerAction?: ReactNode;
}

export const Table = ({
  children,
  processing,
  processingContent,
  headerTitle,
  headerAction,
  placeholder,
  ...restProps
}: ITableProps) => {
  if (processing) {
    return (
      <Box textAlign="center" pt="30px">
        {processingContent || <CircularProgress />}
      </Box>
    );
  }

  if (placeholder) {
    return <Placeholder>{placeholder}</Placeholder>;
  }

  return (
    <>
      {(headerTitle || headerAction) && (
        <Box p="16px" display="flex" justifyContent="space-between" alignItems="center">
          <Box>{headerTitle}</Box>
          <Box>{headerAction}</Box>
        </Box>
      )}
      <StyledTable {...restProps}>{children}</StyledTable>
    </>
  );
};

import styled from '@emotion/styled';
import MuiTypography, { TypographyProps } from '@mui/material/Typography';

const StyledTypography = styled(MuiTypography)`
  font-family: 'ProximaSoft', sans-serif;
  font-size: 30px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0;
`;

export const HeaderTitle = ({ children, ...restProps }: TypographyProps) => {
  return (
    <StyledTypography variant="h2" {...restProps}>
      {children}
    </StyledTypography>
  );
};

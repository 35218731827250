import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { deleteInvite } from 'src/api';
import { showToast } from 'src/store';
import { IGetTeamInvitesReq, IGetTeamInvitesRes } from 'src/types';

export const useDeleteInvite = (teamId: number, getInvitesQueries: IGetTeamInvitesReq) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const getInvitesKeys = ['get-team-invites', teamId, JSON.stringify(getInvitesQueries)];

  const { mutate, ...rest } = useMutation(['delete-team-member'], (inviteId: number) => deleteInvite(inviteId), {
    onMutate: async (inviteId) => {
      await queryClient.cancelQueries(getInvitesKeys);

      const prevData = queryClient.getQueryData(getInvitesKeys);

      queryClient.setQueriesData<IGetTeamInvitesRes | undefined>(getInvitesKeys, (teamInvites) => {
        if (teamInvites) {
          return {
            ...teamInvites,
            invites: teamInvites.invites.filter((invite) => invite.id !== inviteId),
            total: teamInvites.total - 1,
          };
        }

        return teamInvites;
      });

      return { prevData };
    },
    onError: (err, _vars, context) => {
      queryClient.setQueryData(getInvitesKeys, context?.prevData);

      dispatch(
        showToast({
          type: 'error',
          message: (err as Error)?.message ?? 'Something went wrong',
        }),
      );
    },
  });

  return { deleteInvite: mutate, ...rest };
};

import { adminApi } from 'src/api/axios';
import {
  IDateRangeFilter,
  IGetCancellationsGroupedStatsRes,
  IGetCancellationsReq,
  IGetCancellationsRes,
  IGetCancellationsStatsRes,
} from 'src/types';

export const getCancellations = (params: IGetCancellationsReq): Promise<IGetCancellationsRes> =>
  adminApi.get(`/cancel-reasons`, { params });

export const exportCancellationsCsv = (params: IGetCancellationsReq): Promise<string> =>
  adminApi.get(`/cancel-reasons/export`, { params });

export const getCancellationsStats = (params: IDateRangeFilter): Promise<IGetCancellationsStatsRes> =>
  adminApi.get(`/cancel-reasons/stats`, { params });

export const getCancellationsGroupedStats = (params: IDateRangeFilter): Promise<IGetCancellationsGroupedStatsRes> =>
  adminApi.get(`/cancel-reasons/stats/grouped`, { params });

export const getCancellationsSavedUsersStats = (params: IDateRangeFilter): Promise<IGetCancellationsStatsRes> =>
  adminApi.get(`/cancel-reasons/saved-users/stats`, { params });

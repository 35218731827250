import { useMutation, useQueryClient } from 'react-query';

import { removeCloudBot } from 'src/api';

export const useRemoveCloudBot = (userId: number) => {
  const queryClient = useQueryClient();
  const getCloudBotInfoKeys = ['get-user-cloud-bot-info', userId];

  const { mutate, ...rest } = useMutation(['remove-cloud-bot'], () => removeCloudBot(userId), {
    onSuccess: async () => {
      await queryClient.cancelQueries(getCloudBotInfoKeys);

      queryClient.setQueriesData(getCloudBotInfoKeys, () => ({}));

      await queryClient.invalidateQueries(['get-user-cloud-bot-info', userId]);
    },
  });

  return { removeCloudBot: mutate, ...rest };
};

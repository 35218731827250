import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { Button, Input, Typography } from 'src/components';
import {
  MAX_PASSWORD_LENGTH,
  MAX_PASSWORD_LENGTH_MESSAGE,
  MIN_PASSWORD_LENGTH,
  MIN_PASSWORD_LENGTH_MESSAGE,
  PASSWORD_REGEX,
  PASSWORD_REGEX_MESSAGE,
} from 'src/constants';
import { useAppSelector } from 'src/hooks';
import { useChangeUserPassword } from 'src/reactQueries';
import { closeModal } from 'src/store';
import { ModalBody, ModalFooter } from '../_components';

export const ModalChangePassword = () => {
  const dispatch = useDispatch();
  const { params } = useAppSelector((state) => state.modal);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<{ password: string; confirmPassword: string }>({
    defaultValues: { password: '', confirmPassword: '' },
  });

  const watchPassword = watch('password');

  const closeHandler = () => {
    dispatch(closeModal());
  };

  const { changePassword, isLoading } = useChangeUserPassword(params?.userId, { onSuccess: closeHandler });

  const confirmHandler = (values: { password: string; confirmPassword: string }) => {
    changePassword(values.password);
  };

  return (
    <>
      <ModalBody display="flex" flexDirection="column" gap="20px">
        <Input
          register={register}
          parameters={{
            required: 'Please enter a password',
            minLength: {
              value: MIN_PASSWORD_LENGTH,
              message: MIN_PASSWORD_LENGTH_MESSAGE,
            },
            maxLength: {
              value: MAX_PASSWORD_LENGTH,
              message: MAX_PASSWORD_LENGTH_MESSAGE,
            },
            pattern: {
              value: PASSWORD_REGEX,
              message: PASSWORD_REGEX_MESSAGE,
            },
          }}
          className={classNames({
            error: errors?.password,
          })}
          type="password"
          name="password"
          label="New password"
          placeholder="New password"
        />
        {errors?.password && <Typography color="#FF4D4F">{errors?.password?.message}</Typography>}
        <Input
          register={register}
          parameters={{
            required: 'Please enter new password again',
            validate: (value: string) => value === watchPassword || 'The passwords do not match',
            minLength: {
              value: 6,
              message: 'The password must be a minimum of 6 characters',
            },
          }}
          className={classNames({
            error: errors?.confirmPassword,
          })}
          type="password"
          name="confirmPassword"
          label="Confirm password"
          placeholder="Please enter new password again"
        />
        {errors?.confirmPassword && <Typography color="#FF4D4F">{errors?.confirmPassword?.message}</Typography>}
      </ModalBody>
      <ModalFooter alignToTheEnd>
        <Button onClick={closeHandler} variant="whiteBlue" disabled={isLoading}>
          Cancel
        </Button>
        <Button onClick={handleSubmit(confirmHandler)} disabled={isLoading}>
          Confirm
        </Button>
      </ModalFooter>
    </>
  );
};

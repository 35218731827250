import styled from '@emotion/styled';
import { Typography as MuiTypography, TypographyProps } from '@mui/material';

import { propsBlackList } from 'src/helpers';

const BLACK_LIST_PROPS = ['underline'];

interface IMuiTypography {
  cursor?: string;
  underline?: boolean;
}

const StyledMuiTypography = styled(MuiTypography, propsBlackList(BLACK_LIST_PROPS))<IMuiTypography>`
  cursor: ${({ cursor }) => cursor};
  text-decoration: ${({ underline }) => (underline ? 'underline' : 'none')};
`;

export interface ITypographyProps extends TypographyProps {
  semibold?: boolean;
  bold?: boolean;
  medium?: boolean;
  inline?: boolean;
  crop?: boolean;
  pointer?: boolean;
  underline?: boolean;
}

export const Typography = ({
  semibold,
  bold,
  medium,
  inline,
  crop = false,
  pointer,
  underline,
  ...restProps
}: ITypographyProps) => {
  const cursor = pointer ? 'pointer' : 'default';

  return (
    <StyledMuiTypography
      display={inline ? 'inline' : 'block'}
      color="#333333"
      fontFamily="'ProximaSoft', sans-serif"
      fontSize="14px"
      // eslint-disable-next-line no-undefined
      cursor={pointer === undefined ? 'inherit' : cursor}
      underline={underline}
      lineHeight={1.145}
      fontWeight={400}
      letterSpacing="0"
      {...(crop && {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      })}
      {...(medium && { fontWeight: 500 })}
      {...(semibold && { fontWeight: 600 })}
      {...(bold && { fontWeight: 700 })}
      {...restProps}
    />
  );
};

import {
  IDateRangeFilter,
  IGetPauseReasonsRequest,
  IGetPauseReasonsResponse,
  IGetPauseReasonsStatsResponse,
  ISubscriptionPauseDurationStat,
} from 'src/types';
import { adminApi } from './axios';

export const getPauseReasons = (params: IGetPauseReasonsRequest): Promise<IGetPauseReasonsResponse> =>
  adminApi.get(`/pause-reasons`, { params });

export const getPauseReasonsStats = (params: IDateRangeFilter): Promise<IGetPauseReasonsStatsResponse> =>
  adminApi.get(`/pause-reasons/stats`, { params });

export const getSubscriptionPauseDurationsStats = (
  params: IDateRangeFilter,
): Promise<ISubscriptionPauseDurationStat[]> => adminApi.get(`/pause-reasons/stats/periods`, { params });

import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getCancellationsSavedUsersStats } from 'src/api';
import { IDateRangeFilter, IGetCancellationsStatsRes } from 'src/types';

export const useGetCancellationsSavedUsersStats = <TData extends IGetCancellationsStatsRes>(
  params: IDateRangeFilter,
  options?: UseQueryOptions<IGetCancellationsStatsRes, AxiosError, TData>,
) => {
  const { data, ...rest } = useQuery<IGetCancellationsStatsRes, AxiosError, TData>(
    ['get-cancellations-saved-users-stats', JSON.stringify(params)],
    () => getCancellationsSavedUsersStats(params),
    options,
  );

  return { savedUsersStats: data, ...rest };
};

import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getMonthlySignups } from 'src/api';
import { transformAnalyticMonthData } from 'src/helpers';
import { IAnalyticByPlanInfo, IAnalyticSkip } from 'src/types';

export const useGetMonthlySignups = <TData extends IAnalyticByPlanInfo[]>(
  params: IAnalyticSkip,
  options?: UseQueryOptions<IAnalyticByPlanInfo[], AxiosError, TData>,
) => {
  const { data, ...rest } = useQuery<IAnalyticByPlanInfo[], AxiosError, TData>(
    ['get-monthly-signups-analytic', JSON.stringify(params)],
    () => getMonthlySignups(params),
    options,
  );

  return { monthlySignups: transformAnalyticMonthData(data, 'label'), ...rest };
};

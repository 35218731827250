import ct from 'countries-and-timezones';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';

import { Button, ImpersonateButton, Link, MarkIcon, TableCell, TableRow } from 'src/components';
import { ModalTypes } from 'src/enums';
import { resolveMembership } from 'src/helpers';
import { openModal } from 'src/store';
import { IUserInfo } from 'src/types';

const SmallButton = styled(Button)`
  font-size: 12px;
  padding: 4px 12px !important;
`;

interface UsersTableRowProps {
  user: IUserInfo;
  showAdvancedColumns: boolean;
}

export const UsersTableRow = ({ user, showAdvancedColumns }: UsersTableRowProps) => {
  const {
    id,
    campaignsCount,
    createdAt,
    email,
    gmailEmail,
    isBlocked,
    isTrialExtended,
    isTwitterConnected,
    lastConnectedAt,
    linkedInEmail,
    name,
    outlookEmail,
    billingType,
    plan,
    role,
    lastTeamId,
    hasLinkedInPremium,
    hasLinkedInSalesNavigator,
    timezone,
    trialEndsAt,
    subscribedAt,
    isSubscriber,
    seats,
    utmSource,
    trialOrigin,
    country,
    language,
    pageCounter,
    landingUrl,
    lastPage,
    isEmailVerified,
    isHttpClientUser,
    smtpEmail,
  } = user;
  const dispatch = useDispatch();
  const trialEndDate = dayjs(Number(trialEndsAt) * 1000).diff(dayjs(), 'days');
  const membership = resolveMembership({ hasLinkedInPremium, hasLinkedInSalesNavigator });

  const onExtend = () =>
    dispatch(
      openModal({
        type: ModalTypes.EXTEND_TRIAL,
        headerText: 'Extend trial',
        params: {
          teamId: lastTeamId,
        },
      }),
    );

  return (
    <TableRow hoverBgColor="#ebecf2">
      <TableCell>
        <Link to={`/support/users/${id}/details`}>{id}</Link>
      </TableCell>
      <TableCell>
        <Link to={`/support/users/${id}/details`}>{name?.trim() || 'User'}</Link>
      </TableCell>
      <TableCell>{email}</TableCell>
      <TableCell>{lastConnectedAt && dayjs(lastConnectedAt).format('DD MMM, YYYY')}</TableCell>
      <TableCell>{dayjs(createdAt).format('DD MMM, YYYY')}</TableCell>
      <TableCell>{role}</TableCell>
      <TableCell>{linkedInEmail}</TableCell>
      <TableCell justifyContent="center">
        <MarkIcon mark={isTrialExtended} />
      </TableCell>
      <TableCell justifyContent="center" tooltip={!lastTeamId && 'Your user does not have team'}>
        <SmallButton variant="whiteBlue" disabled={!lastTeamId} onClick={onExtend}>
          Extend
        </SmallButton>
      </TableCell>
      <TableCell justifyContent="center">
        <ImpersonateButton size={12} fontSize="12px" teamId={lastTeamId} userId={id} variant="whiteBlue">
          Impersonate
        </ImpersonateButton>
      </TableCell>
      <TableCell justifyContent="center">
        <MarkIcon mark={isBlocked} />
      </TableCell>
      <TableCell>{gmailEmail || outlookEmail || smtpEmail}</TableCell>
      <TableCell>{campaignsCount}</TableCell>
      <TableCell>{plan}</TableCell>
      <TableCell>{billingType}</TableCell>
      <TableCell>{membership}</TableCell>
      <TableCell>{!!trialEndsAt && trialEndDate > 0 && trialEndDate}</TableCell>
      <TableCell>
        <MarkIcon mark={isHttpClientUser} />
      </TableCell>
      <TableCell>GMT{timezone?.utcOffsetStr}</TableCell>

      {showAdvancedColumns && (
        <>
          <TableCell>{!!subscribedAt && dayjs(subscribedAt * 1000).format('DD MMM, YYYY')}</TableCell>
          <TableCell justifyContent="center">
            <MarkIcon mark={isSubscriber} />
          </TableCell>
          <TableCell justifyContent="center">{seats}</TableCell>
          <TableCell>{utmSource}</TableCell>
          <TableCell>{trialOrigin}</TableCell>
          <TableCell>{country && `(${country}) ${ct.getCountry(country)?.name}`}</TableCell>
          <TableCell>{language}</TableCell>
          <TableCell justifyContent="center">{pageCounter}</TableCell>
          <TableCell>
            <Link external to={landingUrl}>
              {landingUrl}
            </Link>
          </TableCell>
          <TableCell>
            <Link external to={lastPage}>
              {lastPage}
            </Link>
          </TableCell>
          <TableCell justifyContent="center">
            <MarkIcon mark={isEmailVerified} />
          </TableCell>

          <TableCell justifyContent="center">
            <MarkIcon mark={!!linkedInEmail} />
          </TableCell>
          <TableCell justifyContent="center">
            <MarkIcon mark={!!gmailEmail || !!outlookEmail} />
          </TableCell>
          <TableCell justifyContent="center">
            <MarkIcon mark={!!campaignsCount} />
          </TableCell>
          <TableCell justifyContent="center">
            <MarkIcon mark={isTwitterConnected} />
          </TableCell>
        </>
      )}
    </TableRow>
  );
};

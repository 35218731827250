import dayjs from 'dayjs';
import { ReactNode, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import {
  Block,
  ShowMoreText,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TablePagination,
  TableRow,
  Typography,
} from 'src/components';
import { LEAD_ACTIVITY_ACTIONS, LEAD_ACTIVITY_ICONS } from 'src/constants';
import { useGetUserActions } from 'src/reactQueries';
import { ITablePagination, TActionTypes } from 'src/types';

interface IActionsTableProps {
  userId: number;
  title: ReactNode;
  type: TActionTypes;
}

export const ActionsTable = ({ userId, title, type }: IActionsTableProps) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [isShowAllActions, setIsShowAllActions] = useState(false);

  const { userActions, isLoading } = useGetUserActions(userId, { perPage, page, type });
  const { actions = [], total = 0 } = userActions ?? {};

  const paginationChangeHandler = (pagination: ITablePagination) => {
    setPage(pagination.page);
    setPerPage(pagination.perPage);
  };

  return (
    <Block>
      <Table
        placeholder={!total && 'No actions found'}
        processing={isLoading}
        disabled={isLoading}
        headerAction={
          total > perPage &&
          !isShowAllActions && (
            <Typography underline pointer onClick={() => setIsShowAllActions(true)}>
              show all
            </Typography>
          )
        }
        headerTitle={
          <Typography fontSize="20px" medium>
            {title}
          </Typography>
        }
      >
        <TableHead secondary>
          <TableRow>
            <TableHeadCell width="300px" secondary>
              Description
            </TableHeadCell>
            <TableHeadCell secondary>Msg</TableHeadCell>
            <TableHeadCell width="220px" secondary>
              Lead
            </TableHeadCell>
            <TableHeadCell width="220px" secondary>
              Campaign
            </TableHeadCell>
            <TableHeadCell width="160px" secondary>
              Time performed
            </TableHeadCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {actions.map(({ id, description, campaignName, message, createdAt, personName }) => {
            const actionIcon = LEAD_ACTIVITY_ICONS[description];

            return (
              <TableRow key={id}>
                <TableCell>
                  {actionIcon && <FontAwesomeIcon icon={actionIcon} />}{' '}
                  {LEAD_ACTIVITY_ACTIONS[description] || description}
                </TableCell>
                <TableCell whiteSpace="normal">
                  <ShowMoreText text={message} />
                </TableCell>
                <TableCell>
                  <Typography>{personName}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{campaignName}</Typography>
                </TableCell>
                <TableCell>
                  <Box display="flex" gap="10px" alignItems="flex-start">
                    <Typography>{dayjs(createdAt).format('DD MMM, YYYY')}</Typography>
                    <Typography>{dayjs(createdAt).format('HH:mm')}</Typography>
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {isShowAllActions && (
        <Box mx="10px">
          <TablePagination page={page} perPage={perPage} total={total} onPaginationChange={paginationChangeHandler} />
        </Box>
      )}
    </Block>
  );
};

import { ChangeEventHandler, MouseEvent } from 'react';
import styled from '@emotion/styled';
import MuiTablePagination, { TablePaginationProps } from '@mui/material/TablePagination';

import { ITablePagination } from 'src/types';

const StyledTablePagination = styled(MuiTablePagination)`
  background-color: transparent;
  border-bottom: none;

  & .MuiInputBase-root {
    order: 3;
  }

  & .MuiInputBase-root {
    margin-right: 0;
    & > div {
      padding: 6px 24px 6px 12px;
      border-radius: 5px;
      border: 1px solid rgba(224, 224, 224, 1);
      background-color: #ffffff;
    }

    & .MuiSvgIcon-root {
      color: #002f53;
    }
  }

  & .MuiTablePagination-actions {
    & .MuiButtonBase-root {
      border-radius: 10px;
      border: 1px solid rgba(224, 224, 224, 1);
      padding: 3px;
      color: #2361fc;
      background-color: #ffffff;

      &.Mui-disabled {
        pointer-events: none;
        opacity: 0.5;
      }
    }
  }
`;

type TOmitPaginationProps = 'count' | 'rowsPerPage' | 'page' | 'onPageChange';

interface ITablePaginationAdditionalProps extends Omit<TablePaginationProps, TOmitPaginationProps> {
  page?: number;
  perPage?: number;
  total?: number;
  onPageChange?: (event: MouseEvent<HTMLButtonElement> | null, page: number) => void;
  onPaginationChange?: ({ page, perPage }: ITablePagination) => void;
}

export const TablePagination = ({
  page = 1,
  perPage = 10,
  total = 0,
  onPaginationChange,
  onPageChange,
  onRowsPerPageChange,
  ...props
}: ITablePaginationAdditionalProps) => {
  const onPageChangeHandler = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    typeof onPageChange === 'function' && onPageChange(event, newPage);

    typeof onPaginationChange === 'function' && onPaginationChange({ page: newPage + 1, perPage });
  };

  const onRowsPerPageChangeHandler: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (event) => {
    typeof onRowsPerPageChange === 'function' && onRowsPerPageChange(event);

    typeof onPaginationChange === 'function' && onPaginationChange({ page: 1, perPage: Number(event.target.value) });
  };

  if (perPage > total) {
    return null;
  }

  return (
    <StyledTablePagination
      component="div"
      labelRowsPerPage={false}
      page={page - 1}
      rowsPerPage={perPage}
      count={total}
      onPageChange={onPageChangeHandler}
      onRowsPerPageChange={onRowsPerPageChangeHandler}
      rowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
      labelDisplayedRows={({ count, from, to }) => `${from}–${to} of ${count.toLocaleString('en-US')}`}
      {...props}
    />
  );
};

import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getUserEmailInfo } from 'src/api';
import { IGetUserEmailInfoRes } from 'src/types';

export const useGetUserEmailInfo = <TData extends IGetUserEmailInfoRes>(
  userId: number,
  options?: UseQueryOptions<IGetUserEmailInfoRes, AxiosError, TData>,
) => {
  const { data, ...rest } = useQuery<IGetUserEmailInfoRes, AxiosError, TData>(
    ['get-user-email-info', userId],
    () => getUserEmailInfo(userId),
    options,
  );

  return { emailInfo: data, ...rest };
};

import styled from '@emotion/styled';
import { Box, BoxProps } from '@mui/material';

const StyledBox = styled(Box)`
  padding: 10px 0;
  color: #394548;
  font-family: ProximaSoft, sans-serif;
  font-size: 14px;
  font-weight: 400;

  > * {
    margin-left: 10px;
  }
`;

export const HeaderDescription = ({ children, ...restProps }: BoxProps) => {
  return <StyledBox {...restProps}>{children}</StyledBox>;
};

import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { enableUserFeatureFlag } from 'src/api';
import { showToast } from 'src/store';
import { IEnableFeatureFlagRequest } from 'src/types';

export const useEnableUserFeatureFlag = (userId: number) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { mutate, ...rest } = useMutation(
    ['enable-feature-for-user'],
    (params: IEnableFeatureFlagRequest) => enableUserFeatureFlag(userId, params),
    {
      onSuccess: (_data, variables) => {
        queryClient.invalidateQueries(['get-user-feature-flags', userId]);

        dispatch(
          showToast({
            type: 'success',
            message: `Feature flag was successfully ${variables.enabled ? 'enabled' : 'disabled'} for this user`,
          }),
        );
      },
      onError: (err) => {
        dispatch(
          showToast({
            type: 'error',
            message: (err as Error)?.message ?? 'Something went wrong',
          }),
        );
      },
    },
  );

  return { enableUserFeatureFlag: mutate, ...rest };
};

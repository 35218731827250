import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, DataPicker, ModalBody, ModalFooter } from 'src/components';
import { useAppSelector } from 'src/hooks';
import { useExtendTrial } from 'src/reactQueries';
import { showToast } from 'src/store';
import { closeModal } from 'src/store/modal.slice';

dayjs.extend(advancedFormat);

export const ModalExtendTrial = () => {
  const dispatch = useDispatch();
  const [date, setDate] = useState('');
  const { params } = useAppSelector((state) => state.modal);

  const { extendTrial, isLoading } = useExtendTrial(params?.teamId, () => dispatch(closeModal()));

  const confirmHandler = () =>
    date ? extendTrial({ date }) : dispatch(showToast({ message: 'Please choose the date', type: 'info' }));

  const closeHandler = () => {
    dispatch(closeModal());
  };

  return (
    <>
      <ModalBody>
        <DataPicker
          type="date"
          value={date}
          placeholder="from"
          min={dayjs().add(1, 'day').format('YYYY-MM-DD')}
          onChange={(e) => e.target.value && setDate(e.target.value)}
        />
      </ModalBody>
      <ModalFooter alignToTheEnd>
        <Button onClick={closeHandler} variant="whiteBlue">
          Cancel
        </Button>
        <Button onClick={confirmHandler} processing={isLoading}>
          Extend
        </Button>
      </ModalFooter>
    </>
  );
};

import styled from '@emotion/styled';
import { Box, BoxProps } from '@mui/material';

export const Wrapper = styled(Box)`
  position: relative;
  background-color: #fff;
  color: #262a79;
  padding: 15px 15px 0 15px;
  border-bottom: 1px solid #e8e8e8;
`;

export const Container = styled(Box)`
  width: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
`;

export const HeaderContainer = ({ children, ...restProps }: BoxProps) => {
  return (
    <Wrapper>
      <Container {...restProps}>{children}</Container>
    </Wrapper>
  );
};

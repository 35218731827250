import clsx from 'clsx';
import { Link, NavLink } from 'react-router-dom';
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { ISideBarMenu } from 'src/types';

export const MenuContainer = styled(Box)`
  position: absolute;
  padding-left: 5px;
  left: var(--sidebar-width);
`;

export const Menu = styled.div`
  border-radius: 5px;
  background-color: #002f53;
  color: white;
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

export const MenuTitle = styled(Link)`
  color: white;
  font-weight: bold;
  white-space: nowrap;
  line-height: 16px;
`;

export const MenuItems = styled(Box)`
  border-left: solid 1px #587792;
  padding-left: 15px;
`;

export const MenuItemLabel = styled('p', {
  shouldForwardProp: (prop) => isPropValid(prop),
})<{ hidden?: boolean }>`
  font-family: 'ProximaSoft', sans-serif;
  font-weight: 300;
  padding-top: 3px;
  padding-bottom: 10px;
  color: white;
  display: ${({ hidden }) => (hidden ? 'none' : 'block')};
  text-decoration: none;
  position: relative;
  white-space: nowrap;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    left: -17px;
    top: 0;
    width: 2px;
    background-color: #f47632;
    height: 36px;
    display: none;
  }

  &:hover {
    color: #f47632;
    &::before {
      display: block;
    }
  }

  &.activeLink {
    color: #f47632;
    &::before {
      display: block;
    }
  }
`;

interface ISideBarMenuProps {
  menu: ISideBarMenu;
  titleLink?: string;
  path?: string;
  openTop?: boolean;
  generateLabelList?: boolean;
}

export const SideBarMenu = ({ menu, openTop, generateLabelList, titleLink, path }: ISideBarMenuProps) => {
  const { pathname } = location;
  const isShowList = !!menu.list?.length && !menu.list?.every(({ hidden }) => hidden);
  const showTitle = menu.title && titleLink;

  if (!menu) return null;

  return (
    <MenuContainer top={openTop ? 'auto' : isShowList ? '0px' : '-10px'} bottom={openTop ? '0' : ''}>
      <Menu>
        {showTitle && <MenuTitle to={titleLink}>{menu.title}</MenuTitle>}
        {menu?.list && (
          <MenuItems mt={showTitle && '10px'}>
            {menu.list.map(({ label, to, hidden, onClick, isShown = true }, ind) => {
              if (!isShown) return null;

              const pathTo = path && to ? `${path}/${to}` : '';
              const activeChild = !!(to || path) && pathname.startsWith(pathTo);

              if (generateLabelList) {
                return <>{label}</>;
              }

              if (!pathTo)
                return (
                  <MenuItemLabel
                    key={`${label}-${ind}`}
                    className={clsx({
                      activeLink: activeChild,
                    })}
                    onClick={onClick}
                    hidden={hidden}
                  >
                    {label}
                  </MenuItemLabel>
                );

              return (
                <NavLink key={`${label}-${ind}`} to={pathTo}>
                  <MenuItemLabel
                    className={clsx({
                      activeLink: activeChild,
                    })}
                    onClick={onClick}
                    hidden={hidden}
                  >
                    {label}
                  </MenuItemLabel>
                </NavLink>
              );
            })}
          </MenuItems>
        )}
      </Menu>
    </MenuContainer>
  );
};

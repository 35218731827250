import dayjs from 'dayjs';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { useDispatch } from 'react-redux';
import { faArrowRight, faDownload, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import { Block, Button, DataPicker, Input, Toggle, Tooltip, Typography } from 'src/components';
import { useAppSelector } from 'src/hooks';
import { useExportUsersCsv } from 'src/reactQueries';
import { updateUsersRequest } from 'src/store';

interface UsersFiltersProps {
  showAdvancedColumns: boolean;
  setShowAdvancedColumns: Dispatch<SetStateAction<boolean>>;
}

export const UsersFilters = ({ showAdvancedColumns, setShowAdvancedColumns }: UsersFiltersProps) => {
  const dispatch = useDispatch();
  const usersRequest = useAppSelector(({ user }) => user.usersRequest);
  const { search, fromDate, toDate } = usersRequest;
  const today = dayjs().format('YYYY-MM-DD');

  const { exportUsersCsv, isLoading: isExportingCsv } = useExportUsersCsv();

  const exportHandler = () => {
    exportUsersCsv({ toDate, fromDate, search });
  };

  const setSearch = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(updateUsersRequest({ search: event.target.value }));
  };

  const toggleHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setShowAdvancedColumns(event.target.checked);
  };

  return (
    <Block p="20px 16px" display="flex" alignItems="center" gap="16px">
      <Box mr="30px" width="100%">
        <Input
          name="input"
          placeholder="Search by Name, Emails, LinkedIn..."
          icon={faSearch}
          value={search}
          onChange={setSearch}
        />
      </Box>
      <Box display="flex" alignItems="center" gap="20px">
        <Typography whiteSpace="pre" fontSize="16px">
          Filter by Date:
        </Typography>
        <DataPicker
          type="date"
          value={fromDate}
          placeholder="from"
          max={toDate || today}
          onChange={(e) => e.target.value && dispatch(updateUsersRequest({ fromDate: e.target.value }))}
        />
        <FontAwesomeIcon icon={faArrowRight} />
        <DataPicker
          type="date"
          value={toDate}
          placeholder="to"
          max={today}
          min={fromDate}
          onChange={(e) => e.target.value && dispatch(updateUsersRequest({ toDate: e.target.value }))}
        />
      </Box>

      <Button gap="10px" onClick={exportHandler} disabled={isExportingCsv}>
        <FontAwesomeIcon icon={faDownload} /> Download .csv
      </Button>

      <Tooltip title="Toggle table columns">
        <Box display="flex">
          <Toggle checked={showAdvancedColumns} onChange={toggleHandler} checkedBgColor="#2361FC" />
        </Box>
      </Tooltip>
    </Block>
  );
};

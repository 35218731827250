import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { deleteAdmin } from 'src/api';
import { showToast } from 'src/store';
import { IGetAllAdminsRes } from 'src/types';

export const useDeleteAdmin = (onSuccess?: () => void) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const getAdminsKeys = ['get-all-admins'];

  const { mutate, ...rest } = useMutation(['delete-admin'], (userId: number) => deleteAdmin(userId), {
    onMutate: async (userId) => {
      await queryClient.cancelQueries(getAdminsKeys);

      const prevData = queryClient.getQueryData(getAdminsKeys);

      queryClient.setQueriesData<IGetAllAdminsRes | undefined>(getAdminsKeys, (data) => {
        if (data) {
          return {
            ...data,
            admins: data.admins.filter(({ id }) => id !== userId),
            total: data.total - 1,
          };
        }
      });

      return { prevData };
    },
    onSuccess: () => {
      onSuccess?.();
      queryClient.invalidateQueries(getAdminsKeys);
    },
    onError: (err, _vars, context) => {
      queryClient.setQueryData(getAdminsKeys, context?.prevData);

      dispatch(
        showToast({
          type: 'error',
          message: (err as Error)?.message ?? 'Something went wrong',
        }),
      );
    },
  });

  return { deleteAdmin: mutate, ...rest };
};

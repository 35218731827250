import { capitalize, isEmpty } from 'lodash';
import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';

import { Button, Select, Typography } from 'src/components';
import { PLAN_SEATS } from 'src/constants';
import { BillingTypeEnum, Plan } from 'src/enums';
import { useAppSelector } from 'src/hooks';
import { useUpdateTeamPlanDetails } from 'src/reactQueries';
import { closeModal } from 'src/store';
import { ISelectOption } from 'src/types';
import { ModalBody, ModalFooter } from '../_components';

const PLAN_OPTIONS = Object.entries(Plan)
  .map(([key, value]) => ({ label: capitalize(key), value }))
  .filter(({ value }) => value !== Plan.TRIAL);

const BILLING_TYPE_OPTIONS = Object.entries(BillingTypeEnum).map(([key, value]) => ({ label: capitalize(key), value }));

export const ModalManageCustomPlan = () => {
  const dispatch = useDispatch();
  const { params } = useAppSelector((state) => state.modal);

  const { updatePlanDetails, isLoading } = useUpdateTeamPlanDetails(params?.teamId);

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm<{
    plan: Plan;
    billingType: BillingTypeEnum;
    seats: number;
  }>();

  const watchPlan = watch('plan');

  const onPlanChange = (data: ISelectOption<Plan>) => {
    const newValue = data.value;
    setValue('plan', newValue);
  };

  const onBillingTypeChange = (data: ISelectOption<BillingTypeEnum>) => {
    const value = data.value;
    setValue('billingType', value);
  };

  const onSeatsChange = (data: ISelectOption<number>) => {
    const value = data.value;
    setValue('seats', Number(value));
  };

  const seatsOptions = useMemo(() => {
    return Array.from({ length: PLAN_SEATS[watchPlan]?.seats || 1 }, (_, i) => i + 1).map((value) => ({
      label: String(value),
      value,
    }));
  }, [watchPlan]);

  const closeHandler = () => {
    dispatch(closeModal());
  };

  const confirmHandler = (values: { plan: Plan; billingType: BillingTypeEnum; seats: number }) => {
    updatePlanDetails(values);
  };

  return (
    <>
      <ModalBody display="flex" gap="20px">
        <Controller
          control={control}
          name="plan"
          render={({ field }) => {
            const selectValue = PLAN_OPTIONS.find((option) => option.value === field.value);

            return (
              <Box>
                <Typography mb="5px">Plan:</Typography>
                <Select
                  {...field}
                  defaultValue={selectValue}
                  value={selectValue}
                  options={PLAN_OPTIONS}
                  onChange={onPlanChange}
                />
              </Box>
            );
          }}
        />

        <Controller
          control={control}
          name="billingType"
          render={({ field }) => {
            const selectValue = BILLING_TYPE_OPTIONS.find((option) => option.value === field.value);

            return (
              <Box>
                <Typography mb="5px">Billing cycle:</Typography>
                <Select
                  {...field}
                  defaultValue={selectValue}
                  value={selectValue}
                  options={BILLING_TYPE_OPTIONS}
                  onChange={onBillingTypeChange}
                />
              </Box>
            );
          }}
        />

        <Controller
          control={control}
          name="seats"
          render={({ field }) => {
            return (
              <Box>
                <Typography mb="5px">Seats:</Typography>
                <Select {...field} options={seatsOptions} onChange={onSeatsChange} />
              </Box>
            );
          }}
        />
      </ModalBody>
      <ModalFooter alignToTheEnd>
        <Button onClick={closeHandler} variant="whiteBlue">
          Cancel
        </Button>
        <Button onClick={handleSubmit(confirmHandler)} processing={isLoading} disabled={!isEmpty(errors)}>
          Confirm
        </Button>
      </ModalFooter>
    </>
  );
};

import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getAdmins } from 'src/api';
import { IGetAllAdminsReq, IGetAllAdminsRes } from 'src/types';

export const useGetAllAdmins = <TData extends IGetAllAdminsRes>(
  params: IGetAllAdminsReq,
  options?: UseQueryOptions<IGetAllAdminsRes, AxiosError, TData>,
) => {
  const { data, ...rest } = useQuery<IGetAllAdminsRes, AxiosError, TData>(
    ['get-all-admins', JSON.stringify(params)],
    () => getAdmins(params),
    options,
  );

  return { allAdmins: data, ...rest };
};

import cloudIcon from 'src/assets/icons/sidebar/cloud.svg';
import gridIcon from 'src/assets/icons/sidebar/grid.svg';
import helpWheel from 'src/assets/icons/sidebar/help_wheel.svg';
import userProfile from 'src/assets/icons/sidebar/user_profile.svg';
import { convertPermissionToObject } from 'src/helpers/admins';
import { useGetUserAccount } from 'src/reactQueries';
import { ISideBarList } from 'src/types';

export const useGetSidebarConfig = () => {
  const { userMe, isLoading: isPermissionsLoading } = useGetUserAccount();
  const { analytics, managingAdmins, support, featureFlags } = convertPermissionToObject(
    userMe?.adminPermissions ?? [],
  );

  const isAnalyticsShow = analytics;
  const isSupportShow = support;
  const isManageAdminsShow = managingAdmins;
  const isFeatureFlagsShow = featureFlags;

  const sidebarConfig: ISideBarList[] = [
    {
      icon: gridIcon,
      path: '/analytics',
      disabled: !isAnalyticsShow,
    },
    {
      icon: helpWheel,
      path: '/support',
      disabled: !isSupportShow,
      menu: {
        title: 'Support',
        list: [
          { label: 'Users', to: 'users' },
          { label: 'Teams', to: 'teams' },
          { label: 'Cancellations', to: 'cancellations' },
          { label: 'Subscription Pauses', to: 'subscription-pauses' },
          { label: 'Access Code', to: 'access-code' },
          { label: 'Feature Flags', to: 'feature-flags', isShown: isFeatureFlagsShow },
        ],
      },
    },
    {
      icon: userProfile,
      path: '/manage-admins',
      disabled: !isManageAdminsShow,
      menu: {
        title: 'Manage Admins',
      },
    },
    {
      icon: cloudIcon,
      path: '/li-actions',
      disabled: !isAnalyticsShow,
    },
  ];

  return {
    sidebarConfig,
    isAnalyticsShow,
    isSupportShow,
    isManageAdminsShow,
    isPermissionsLoading,
    isFeatureFlagsShow,
  };
};

import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { updateAdmin } from 'src/api';
import { AdminPermissionEnum } from 'src/enums/admins';
import { useGetUserAccount } from 'src/reactQueries';
import { showToast } from 'src/store';
import { IAdmin } from 'src/types';

export const useUpdateAdmin = (userId: number, onSuccess?: () => void) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const getAdminKeys = ['get-admin', userId];
  const { userMe } = useGetUserAccount();

  const { mutate, ...rest } = useMutation(
    ['update-admin'],
    (permissions: AdminPermissionEnum[]) => updateAdmin(userId, { permissions }),
    {
      onMutate: async (permissions) => {
        await queryClient.cancelQueries(getAdminKeys);

        const prevData = queryClient.getQueryData(getAdminKeys);

        queryClient.setQueriesData<IAdmin | undefined>(getAdminKeys, (data) => {
          if (data) {
            return {
              ...data,
              permissions: permissions,
            };
          }

          return data;
        });

        return { prevData };
      },
      onSuccess: async () => {
        onSuccess?.();
        if (userMe?.id === userId) {
          await queryClient.invalidateQueries(['get-user-profile']);
        }
      },
      onError: (err, _vars, context) => {
        queryClient.setQueryData(getAdminKeys, context?.prevData);

        dispatch(
          showToast({
            type: 'error',
            message: (err as Error)?.message ?? 'Something went wrong',
          }),
        );
      },
    },
  );

  return { updateAdmin: mutate, ...rest };
};

import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getReferredTeams } from 'src/api';
import { IReferredTeamsReq, IReferredTeamsRes } from 'src/types';

export const useGetReferredTeams = <TData extends IReferredTeamsRes>(
  teamId: number,
  params: IReferredTeamsReq,
  options?: UseQueryOptions<IReferredTeamsRes, AxiosError, TData>,
) => {
  const { data, ...rest } = useQuery<IReferredTeamsRes, AxiosError, TData>(
    ['get-referred-teams', teamId, JSON.stringify(params)],
    () => getReferredTeams(teamId, params),
    options,
  );

  return { referredTeams: data, ...rest };
};

import {
  faBan,
  faBirthdayCake,
  faCheckSquare,
  faCommentDots,
  faComments,
  faCommentSlash,
  faEnvelope,
  faEnvelopeOpen,
  faEnvelopeSquare,
  faExchangeAlt,
  faEye,
  faPlusSquare,
  faReply,
  faUndo,
  faUser,
  faUserCheck,
  faUserFriends,
  faUserPlus,
  faUserSlash,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';

export const LEAD_ACTIVITY_ICONS: Record<string, IconDefinition> = {
  viewed: faEye,
  'already connected': faUserFriends,
  connected: faUserFriends,
  'could not follow-up': faCommentSlash,
  'followed up after connect': faCommentDots,
  found: faUser,
  'InMail Message sent': faEnvelope,
  'InMail skipped': faEnvelopeSquare,
  'linkedin reply detected': faReply,
  'Message sent': faComments,
  'requested connect': faUserPlus,
  'verified uploaded lead': faUserCheck,
  emailed: faEnvelope,
  'Email reply detected': faEnvelope,
  'emailed postponed': faBan,
  'Connect request postponed': faBan,
  'Endorsed a profile': faCheckSquare,
  'user interaction detected': faUserFriends,
  'Followed a profile': faPlusSquare,
  'invite withdrawn': faUserSlash,
  'no email found': faBan,
  'Email opened': faEnvelopeOpen,
  "Couldn't send mail": faBan,
  'Could not run action': faBan,
  JOB_CHANGE: faExchangeAlt,
  'Email bounced': faBan,
  'Unable to send message': faBan,
  WORK_ANNIVERSARY: faBirthdayCake,
  'Could not connect user': faBan,
  BIRTHDAY: faBirthdayCake,
  'Returned to campaign': faUndo,
  'Group Message sent': faComments,
};

export const LEAD_ACTIVITY_ACTIONS: Record<string, string> = {
  found: 'Lead Found',
  'already connected': 'Already Connected',
  connected: 'Accepted',
  'could not follow-up': 'Could not send Follow up',
  'followed up after connect': 'Follow up message sent after Connecting',
  'InMail Message sent': 'InMail Message sent',
  'InMail skipped': 'InMail skipped',
  'linkedin reply detected': 'LinkedIn reply detected',
  'Message sent': 'Message sent',
  'requested connect': 'Invited',
  'verified uploaded lead': 'Lead verified',
  viewed: 'Profile viewed',
  emailed: 'Email Sent',
  'Email reply detected': 'Email reply detected',
  'emailed postponed': 'Email postponed',
  'Connect request postponed': 'Connect request postponed',
  'Endorsed a profile': 'Endorsed a profile',
  'user interaction detected': 'User interaction detected',
  'Followed a profile': 'Followed a profile',
  'invite withdrawn': 'Invite withdrawn',
  'no email found': 'No email found',
  'Email opened': 'Email opened',
  "Couldn't send mail": 'Could not send mail',
  'Could not run action': 'Could not run action',
  JOB_CHANGE: 'Job change action',
  'Email bounced': 'Email bounced',
  'Unable to send message': 'Unable to send message',
  WORK_ANNIVERSARY: 'Work anniversary',
  'Could not connect user': 'Could not connect user',
  BIRTHDAY: 'Birthday action',
  'Returned to campaign': 'Returned to campaign',
  'Group Message sent': 'Group message sent',
  'engaged profile': 'Engaged profile',
};

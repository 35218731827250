import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { changeUserEmail } from 'src/api';
import { showToast } from 'src/store';
import { IChangeUserEmailReq, IGetUserInfoRes } from 'src/types';

export const useChangeUserEmail = (userId: number) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const getUserInfoKeys = ['get-user-info', userId];

  const { mutate, ...rest } = useMutation(
    ['change-email', userId],
    (data: IChangeUserEmailReq) => changeUserEmail(userId, data),
    {
      onSuccess: () => {
        queryClient
          .invalidateQueries(['get-all-users'], { refetchInactive: true, exact: false }, {})
          .catch(console.error);
        dispatch(
          showToast({
            type: 'success',
            message: 'Email is saved',
          }),
        );
      },
      onMutate: async ({ email }) => {
        await queryClient.cancelQueries(getUserInfoKeys);

        const prevData = queryClient.getQueryData(getUserInfoKeys);

        queryClient.setQueriesData<IGetUserInfoRes | undefined>(getUserInfoKeys, (user) => {
          if (user) {
            return {
              ...user,
              email,
            };
          }

          return user;
        });

        return { prevData };
      },
      onError: (err, _vars, context) => {
        queryClient.setQueryData(getUserInfoKeys, context?.prevData);

        dispatch(
          showToast({
            type: 'error',
            message: (err as Error)?.message ?? 'Something went wrong',
          }),
        );
      },
    },
  );

  return { changeEmail: mutate, ...rest };
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ModalTypes } from 'src/enums';

interface IInitialState {
  isOpen: boolean;
  headerText?: string;
  descriptionText?: string;
  type?: ModalTypes | null;
  closable?: boolean | null;
  params?: Record<string, any>; // eslint-disable-line
  onConfirm?: (() => void) | ((params: any) => void) | null; // eslint-disable-line
  onCancel?: (() => void) | null;
  isActionsShown?: boolean;
  size?: 'medium' | 'large';
}

const initialState: IInitialState = {
  isOpen: false,
  headerText: '',
  descriptionText: '',
  params: {},
  isActionsShown: true,
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<Omit<IInitialState, 'isOpen'>>) => {
      const { headerText, descriptionText, onConfirm, onCancel, type, closable, params, isActionsShown, size } =
        action.payload;

      state.isOpen = true;
      state.headerText = headerText;
      state.descriptionText = descriptionText;
      state.type = type;
      state.closable = closable;
      state.params = params;
      state.onConfirm = onConfirm;
      state.onCancel = onCancel;
      state.isActionsShown = isActionsShown;
      state.size = size;
    },
    closeModal: (state) => {
      state.isOpen = false;
      state.headerText = '';
      state.descriptionText = '';
      state.type = null;
      state.closable = true;
      state.params = {};
      state.onConfirm = null;
      state.onCancel = null;
      state.isActionsShown = true;
      state.size = 'medium';
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;

export default modalSlice.reducer;

import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { pauseCloudBot } from 'src/api';
import { showToast } from 'src/store';
import { IGetUserCloudBotInfoRes } from 'src/types';

export const usePauseCloudBot = (userId: number) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const getCloudBotInfoKeys = ['get-user-cloud-bot-info', userId];

  const { mutate, ...rest } = useMutation(['pause-cloud-bot'], () => pauseCloudBot(userId), {
    onMutate: async () => {
      await queryClient.cancelQueries(getCloudBotInfoKeys);

      const prevData = queryClient.getQueryData(getCloudBotInfoKeys);

      queryClient.setQueriesData<IGetUserCloudBotInfoRes | undefined>(getCloudBotInfoKeys, (data) => {
        if (data) {
          return {
            ...data,
            isPaused: true,
          };
        }

        return data;
      });

      return { prevData };
    },
    onSuccess: () => {
      queryClient.invalidateQueries(getCloudBotInfoKeys);
    },
    onError: (err, _vars, context) => {
      queryClient.setQueryData(getCloudBotInfoKeys, context?.prevData);

      dispatch(
        showToast({
          type: 'error',
          message: (err as Error)?.message ?? 'Something went wrong',
        }),
      );
    },
  });

  return { pauseCloudBot: mutate, ...rest };
};

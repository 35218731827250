import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Box } from '@mui/material';

import {
  ActionButton,
  Block,
  BlockHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Typography,
} from 'src/components';
import { addLabelTitles, getPercentage, separateNumWithComma } from 'src/helpers';
import { useGetMonthlySignups } from 'src/reactQueries';

const labels = {
  basic: 'Basic',
  premium: 'Premium',
  salesNavigator: 'Sales Navigator',
  activated: 'Total Activated',
  total: 'Total Trials',
};

export const Trials = () => {
  const [skip, setSkip] = useState(0);
  const absSkip = Math.abs(skip);

  const { monthlySignups, isLoading } = useGetMonthlySignups({ skip: absSkip });
  const labelsMonthlySignups = useMemo(() => addLabelTitles(monthlySignups, labels, 'label'), [monthlySignups]);
  const totalMonthlySignups = labelsMonthlySignups.find(({ title }) => title === labels.total);

  const showMonth = [
    dayjs().subtract(absSkip + 2, 'M'),
    dayjs().subtract(absSkip + 1, 'M'),
    dayjs().subtract(absSkip, 'M'),
  ];

  return (
    <Block>
      <BlockHeader py="0" height="64px" border="none" bgcolor="white">
        <Typography medium fontSize="20px">
          Trials
        </Typography>
        <Box display="flex" gap="10px">
          <ActionButton icon={faArrowLeft} onClick={() => setSkip((prev) => prev - 1)} />
          <ActionButton icon={faArrowRight} disabled={skip >= 0} onClick={() => setSkip((prev) => prev + 1)} />
        </Box>
      </BlockHeader>
      <Table
        placeholder={!labelsMonthlySignups?.length && 'No monthly sign ups found'}
        processing={isLoading}
        disabled={isLoading}
      >
        <TableHead secondary>
          <TableRow>
            <TableHeadCell width="35%" secondary />
            {showMonth.map((data) => (
              <TableHeadCell key={data.toISOString()} secondary justifyContent="flex-end">
                {data.format("MMM 'YY'")}
              </TableHeadCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {labelsMonthlySignups?.map((signUps, index) => (
            <TableRow key={signUps?.title ?? index}>
              <TableCell>{signUps?.title}</TableCell>
              {showMonth.map((data) => {
                const monthKey = data.format('YYYY/MM');
                const value = signUps[monthKey] || 0;

                const totalPercentage = getPercentage(value, totalMonthlySignups?.[monthKey]);
                const showTotalPercentage = signUps?.title !== labels.total;

                return (
                  <TableCell key={monthKey} justifyContent="flex-end">
                    {separateNumWithComma(value)}{' '}
                    {showTotalPercentage && (
                      <Typography fontSize="12px" inline>
                        ({totalPercentage}%)
                      </Typography>
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Block>
  );
};

import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { patchUpdateTeamStripeInfo } from 'src/api';
import { showToast } from 'src/store';

export const useUpdateStripeInfo = (teamId: number, onSuccess?: () => void) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { mutate, ...rest } = useMutation(['update-stripe-info', teamId], () => patchUpdateTeamStripeInfo(teamId), {
    onSuccess: async () => {
      dispatch(
        showToast({
          type: 'info',
          message: 'Stripe info has been updated',
        }),
      );
      onSuccess?.();
      await queryClient.invalidateQueries(['get-team-info', teamId], { exact: false, refetchInactive: true });
      await queryClient.invalidateQueries(['get-team-stripe-info', teamId], { exact: false, refetchInactive: true });
    },
    onError: (err) => {
      dispatch(
        showToast({
          type: 'error',
          message: (err as Error)?.message ?? 'Stipe info failed to update. Try later',
        }),
      );
    },
  });

  return { updateStripeInfo: mutate, ...rest };
};

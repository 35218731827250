import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getUserActions } from 'src/api';
import { IGetUserActionsReq, IGetUserActionsRes } from 'src/types';

export const useGetUserActions = <TData extends IGetUserActionsRes>(
  userId: number,
  params: IGetUserActionsReq,
  options?: UseQueryOptions<IGetUserActionsRes, AxiosError, TData>,
) => {
  const { data, ...rest } = useQuery<IGetUserActionsRes, AxiosError, TData>(
    ['get-user-actions', userId, JSON.stringify(params)],
    () => getUserActions(userId, params),
    options,
  );

  return { userActions: data, ...rest };
};

import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import { FormInput, Table, TableBody, TableCell, TableHead, TableHeadCell, TableRow, Typography } from 'src/components';
import { Accordion, AccordionDetails, AccordionSummary } from 'src/containers/Support/_components';
import { useGetTeamWhiteLabel } from 'src/reactQueries';
import { showToast } from 'src/store';

const CopyIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  margin-left: 10px;
`;

interface IForm {
  domain: string;
}

export const CustomDomain = () => {
  const dispatch = useDispatch();
  const { teamId } = useParams();
  const TEAM_ID = Number(teamId);
  const [expanded, setExpanded] = useState(false);

  const { whitelabel, isLoading } = useGetTeamWhiteLabel(TEAM_ID);
  const { domainSettings } = whitelabel ?? {};

  const { register } = useForm<IForm>({
    defaultValues: whitelabel,
  });

  const copyText = (text: string) => {
    navigator.clipboard.writeText(text);
    dispatch(showToast({ message: 'Copied', type: 'success', autoCloseTime: 1500 }));
  };

  return (
    <Accordion expanded={expanded} onChange={() => setExpanded((prev) => !prev)}>
      <AccordionSummary
        expanded={expanded}
        title="Custom Domain"
        status={whitelabel?.companyName ? 'applied' : 'pending'}
      />

      <AccordionDetails>
        <Box maxWidth="25%">
          <FormInput
            name="domain"
            label="Custom Domain"
            placeholder="app.meetalfred.com"
            register={register}
            disabled
          />
        </Box>

        <Box maxWidth="80%" mt="16px">
          <Typography lineHeight="22px" mb="4px">
            Custom Domain DNS records
          </Typography>
          <Table processing={isLoading}>
            <TableHead secondary>
              <TableRow>
                <TableHeadCell width="100px">TYPE</TableHeadCell>
                <TableHeadCell>HOST/NAME</TableHeadCell>
                <TableHeadCell>TARGET/VALUE</TableHeadCell>
                <TableHeadCell width="80px">TTL</TableHeadCell>
                <TableHeadCell width="100px">Status</TableHeadCell>
              </TableRow>
            </TableHead>

            <TableBody placeholder={!domainSettings?.length && 'No Custom Domain yet'}>
              {domainSettings?.map(({ host, isValidated, type, value }) => (
                <TableRow key={host}>
                  <TableCell>{type}</TableCell>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <Typography crop> {host}</Typography> <CopyIcon icon={faCopy} onClick={() => copyText(host)} />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <Typography crop> {value}</Typography> <CopyIcon icon={faCopy} onClick={() => copyText(value)} />
                    </Box>
                  </TableCell>
                  <TableCell>600</TableCell>
                  <TableCell contentStyle={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                    {isValidated ? (
                      <Typography color="success.main">Verified</Typography>
                    ) : (
                      <Typography color="#E47D44">Pending</Typography>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

import dayjs from 'dayjs';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import {
  Block,
  ImpersonateButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TablePagination,
  TableRow,
  Typography,
} from 'src/components';
import { TeamDetailsLayout } from 'src/containers';
import { convertPermissionToObject } from 'src/helpers';
import {
  useDeleteTeamMember,
  useGetTeamInfo,
  useGetTeamMembers,
  useGetUserAccount,
  useReturnTeamMemberToTeam,
} from 'src/reactQueries';
import { openModal } from 'src/store';
import { ITablePagination, TSortOrder, TTeamMembersSortFields } from 'src/types';

export const Members = () => {
  const dispatch = useDispatch();
  const { teamId } = useParams();
  const TEAM_ID = Number(teamId);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState<TSortOrder>('ASC');
  const [sortField, setSortField] = useState<TTeamMembersSortFields>('name');

  const { userMe } = useGetUserAccount();
  const { managingUsers } = convertPermissionToObject(userMe?.adminPermissions ?? []);

  const params = { page, perPage, sortField, sortOrder };
  const { teamMembers, isLoading } = useGetTeamMembers(TEAM_ID, params);
  const { deleteMember } = useDeleteTeamMember(TEAM_ID);
  const { returnUserToTeam } = useReturnTeamMemberToTeam(TEAM_ID);
  const { teamInfo } = useGetTeamInfo(TEAM_ID);
  const { members = [], total } = teamMembers ?? {};

  const sortingProps = {
    sortField,
    sortOrder,
    onSortFieldChange: (field: TTeamMembersSortFields, order: TSortOrder) => {
      setSortField(field);
      setSortOrder(order);
    },
  };

  const paginationChangeHandler = (pagination: ITablePagination) => {
    setPage(pagination.page);
    setPerPage(pagination.perPage);
  };

  const onDeleteUser = (userId: number, username: string) =>
    dispatch(
      openModal({
        headerText: `Remove ${username} from ${teamInfo?.name} team`,
        descriptionText: `Are you sure you want to remove ${username} from the ${teamInfo?.name} team?`,
        onConfirm: () => deleteMember(userId),
      }),
    );

  const onReturnToTeam = (userId: number, username: string) =>
    dispatch(
      openModal({
        headerText: `Return ${username} to ${teamInfo?.name} team`,
        descriptionText: `Are you sure you want to return ${username} to the ${teamInfo?.name} team?`,
        onConfirm: () => returnUserToTeam(userId),
      }),
    );

  return (
    <TeamDetailsLayout>
      <Block>
        <Table placeholder={!total && 'No members found'} processing={isLoading} disabled={isLoading}>
          <TableHead secondary>
            <TableRow>
              <TableHeadCell width="30%" secondary name="name" sorting={sortingProps}>
                Member Name
              </TableHeadCell>
              <TableHeadCell secondary name="role" sorting={sortingProps}>
                Role
              </TableHeadCell>
              <TableHeadCell secondary name="joinedAt" sorting={sortingProps}>
                Joined At
              </TableHeadCell>
              <TableHeadCell secondary name="isRemoved" sorting={sortingProps}>
                Status
              </TableHeadCell>
              <TableHeadCell secondary>Actions</TableHeadCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {members.map(({ id, name, isRemoved, joinedAt, removedAt, role }) => {
              const removedDate = removedAt ? dayjs(removedAt).format('DD/MM/YYYY') : '';

              return (
                <TableRow key={id}>
                  <TableCell>
                    <Link to={`/support/users/${id}/details`} medium underline>
                      {name}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Typography medium>{role}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography medium>{!!joinedAt && dayjs(joinedAt).format('DD/MM/YYYY')}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography medium>{isRemoved ? `Removed ${removedDate}` : 'Active'}</Typography>
                  </TableCell>
                  <TableCell>
                    {isRemoved ? (
                      managingUsers && (
                        <Typography color="#2361fc" medium pointer underline onClick={() => onReturnToTeam(id, name)}>
                          Return to team
                        </Typography>
                      )
                    ) : (
                      <Box display="flex" alignItems="center" gap="10px">
                        <Typography color="#FF4D4F" medium pointer underline onClick={() => onDeleteUser(id, name)}>
                          Delete
                        </Typography>
                        <ImpersonateButton size={10} userId={id} teamId={TEAM_ID}>
                          Impersonate
                        </ImpersonateButton>
                      </Box>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <Box mx="10px">
          <TablePagination page={page} perPage={perPage} total={total} onPaginationChange={paginationChangeHandler} />
        </Box>
      </Block>
    </TeamDetailsLayout>
  );
};

import dayjs from 'dayjs';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { faArrowRight, faDownload, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import { Block, Button, DataPicker, Input, Select, Typography } from 'src/components';
import { cancelReasonsOptions } from 'src/constants';
import { AllCancellations } from 'src/containers/Support/Cancellations/AllCancellations';
import { CancellationsTable } from 'src/containers/Support/Cancellations/CancellationsTable';
import { SavedUsers } from 'src/containers/Support/Cancellations/SavedUsers';
import { CancelSubscriptionReason } from 'src/enums';
import { useAppSelector } from 'src/hooks';
import { useExportCancellationsCsv } from 'src/reactQueries';
import { updateCancellationsRequest } from 'src/store';
import { CancellationsLayout } from './CancellationsLayout';

const StyledInput = styled(Input)`
  height: 34px;
  margin-top: 0;
`;

export const Cancellations = () => {
  const dispatch = useDispatch();
  const { fromDate, toDate, sortField, sortOrder, search, reason } = useAppSelector(
    ({ cancellation }) => cancellation.cancellationsRequest,
  );
  const [queryDateTo, setQueryDateTo] = useState(toDate);
  const [queryDateFrom, setQueryDateFrom] = useState(fromDate);
  const [searchInput, setSearchInput] = useState('');
  const [selectedReason, setSelectedReason] = useState<CancelSubscriptionReason | 'all'>('all');

  const { exportCancellationsCsv, isLoading } = useExportCancellationsCsv();

  const exportHandler = () => {
    exportCancellationsCsv({ toDate: queryDateTo, fromDate: queryDateFrom, search, reason, sortOrder, sortField });
  };

  const onApplyFilters = () =>
    dispatch(
      updateCancellationsRequest({
        toDate: queryDateTo,
        fromDate: queryDateFrom,
        search: searchInput,
        reason: selectedReason === 'all' ? null : selectedReason,
        page: 1,
      }),
    );

  return (
    <CancellationsLayout>
      <Block p="16px" display="flex" alignItems="center" gap="8px">
        <Typography whiteSpace="pre" fontSize="16px">
          Filter by Date:
        </Typography>
        <Box display="flex" alignItems="center" gap="20px">
          <DataPicker
            type="date"
            value={queryDateFrom}
            placeholder="from"
            max={queryDateTo}
            onChange={(e) => e.target.value && setQueryDateFrom(e.target.value)}
          />
          <FontAwesomeIcon icon={faArrowRight} />
          <DataPicker
            type="date"
            value={queryDateTo}
            placeholder="to"
            max={dayjs().format('YYYY-MM-DD')}
            min={queryDateFrom}
            onChange={(e) => e.target.value && setQueryDateTo(e.target.value)}
          />
        </Box>
        <Box display="flex" alignItems="center" gap="8px">
          <Typography>Reason:</Typography>
          <Select
            minWidth="170px"
            placeholder="Select reason"
            options={cancelReasonsOptions}
            onChange={(nextValue) => setSelectedReason(nextValue.value)}
            value={selectedReason}
          />
        </Box>
        <StyledInput
          name="input"
          placeholder="Search by user name, email or ID"
          icon={faSearch}
          value={searchInput}
          onChange={(event) => setSearchInput(event.target.value)}
        />
        <Button size={22} onClick={onApplyFilters}>
          Apply
        </Button>
        <Button gap="10px" onClick={exportHandler} disabled={isLoading}>
          <FontAwesomeIcon icon={faDownload} /> Download .csv
        </Button>
      </Block>
      <Box display="grid" gridTemplateColumns="1fr 1fr" gap="16px">
        <AllCancellations />
        <SavedUsers />
      </Box>
      <CancellationsTable />
    </CancellationsLayout>
  );
};

import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getCancellationsStats } from 'src/api';
import { IDateRangeFilter, IGetCancellationsStatsRes } from 'src/types';

export const useGetCancellationsStats = <TData extends IGetCancellationsStatsRes>(
  params: IDateRangeFilter,
  options?: UseQueryOptions<IGetCancellationsStatsRes, AxiosError, TData>,
) => {
  const { data, ...rest } = useQuery<IGetCancellationsStatsRes, AxiosError, TData>(
    ['get-cancellations-stats', JSON.stringify(params)],
    () => getCancellationsStats(params),
    options,
  );

  return { cancellationsStats: data, ...rest };
};

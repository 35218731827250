import { useMemo } from 'react';

import { Button, ISmallButtonProps } from 'src/components';
import { convertPermissionToObject } from 'src/helpers';
import { useGetUserAccount, usePostImpersonateUser } from 'src/reactQueries';

interface IImpersonateButtonProps extends ISmallButtonProps {
  userId: number;
  teamId?: number;
}

export const ImpersonateButton = ({ userId, teamId, ...buttonProps }: IImpersonateButtonProps) => {
  const { userMe } = useGetUserAccount();

  const { impersonateUser } = usePostImpersonateUser();

  const onImpersonate = () => impersonateUser({ teamIdToImpersonate: teamId ?? -1, userIdToImpersonate: userId });

  const tooltip = useMemo(() => {
    if (!teamId) {
      return 'You cannot impersonate this user';
    }

    const permission = convertPermissionToObject(userMe?.adminPermissions ?? []);
    if (!permission.impersonation) {
      return `You don't have permission`;
    }

    return null;
  }, [teamId, userMe]);

  return (
    <Button disabled={!!tooltip} tooltip={tooltip} onClick={onImpersonate} {...buttonProps}>
      Impersonate
    </Button>
  );
};

import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getAllUsers } from 'src/api';
import { IGetAllUsersInfoReq, IGetAllUsersInfoRes } from 'src/types';

export const useGetAllUsers = <TData extends IGetAllUsersInfoRes>(
  params: IGetAllUsersInfoReq,
  options?: UseQueryOptions<IGetAllUsersInfoRes, AxiosError, TData>,
) => {
  const processedParams = { ...params, search: params?.search?.trim() };

  const { data, ...rest } = useQuery<IGetAllUsersInfoRes, AxiosError, TData>(
    ['get-all-users', JSON.stringify(processedParams)],
    () => getAllUsers(processedParams),
    options,
  );

  return { allUsers: data, ...rest };
};

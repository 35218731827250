import { Link, useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import {
  Breadcrumbs,
  HeaderContainer,
  HeaderTitle,
  Table,
  TableBody,
  TableHead,
  TableHeadCell,
  TableRow,
  Typography,
} from 'src/components';
import { AccessCodeDetailsRow } from 'src/containers/Support/AccessCodeDetails/AccessCodeDetailsRow';
import { useLocationState } from 'src/hooks';
import { useGetCodesTeam } from 'src/reactQueries';

export const AccessCodeDetails = () => {
  const { codeId } = useParams();
  const locationState = useLocationState<{ accelerator: string; code: string }>();
  const CODE_ID = Number(codeId);

  const { codesTeam = [], isLoading } = useGetCodesTeam(CODE_ID);

  return (
    <>
      <HeaderContainer pb="15px">
        <Breadcrumbs>
          <Link to="/support">Support</Link>
          <Link to="/support/access-code">Access Codes</Link>
          <Typography>{locationState.code}</Typography>
        </Breadcrumbs>
        <HeaderTitle mt="8px" display="flex" alignItems="baseline">
          {locationState.accelerator}{' '}
          <Typography fontSize="26px" semibold color="#262a79ba">
            &nbsp;{locationState.code}
          </Typography>
        </HeaderTitle>
      </HeaderContainer>
      <Box m="16px" display="grid" gap="16px">
        <Table placeholder={!codesTeam.length && 'No access codes found'} processing={isLoading} disabled={isLoading}>
          <TableHead secondary>
            <TableRow>
              <TableHeadCell width="380px" secondary>
                Team Name
              </TableHeadCell>
              <TableHeadCell secondary>Users</TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {codesTeam.map((code, index) => (
              <AccessCodeDetailsRow key={index} code={code} />
            ))}
          </TableBody>
        </Table>
      </Box>
    </>
  );
};

import { ButtonHTMLAttributes } from 'react';
import styled from '@emotion/styled';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StyledButton = styled.button<{ width?: string }>`
  position: relative;
  padding: 8px 10px;
  width: ${(props) => props.width || '100px'};
  min-width: 100px;
  max-width: 250px;
  height: 33px;
  color: #ffffff;
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  border-radius: 4px;
  background-color: #2361fc;
  border: none;
  cursor: pointer;

  &[disabled] {
    opacity: 0.5;
  }
`;

const StyledSpinner = styled(FontAwesomeIcon)`
  animation: fa-spin 2s linear infinite;
`;

interface IModalButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  width?: string;
  wide?: boolean;
  processing?: boolean;
}

export const ModalButton = ({
  children,
  wide,
  width = wide ? '180px' : '100px',
  processing,
  ...restProps
}: IModalButtonProps) => (
  <StyledButton width={width} {...restProps}>
    {processing ? <StyledSpinner size="lg" icon={faSpinner} /> : children}
  </StyledButton>
);

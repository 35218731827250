import { RefObject, useLayoutEffect, useState } from 'react';

export const useIsOverflow = (ref: RefObject<HTMLDivElement>) => {
  const [isOverflow, setIsOverflow] = useState(false);

  useLayoutEffect(() => {
    const { current } = ref;

    if (current) {
      const hasOverflow = current?.scrollWidth > current?.offsetWidth;

      setIsOverflow(hasOverflow);
    }
  }, [ref]);

  return isOverflow;
};

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

import { Link, TableCell, TableRow, Toggle, Typography } from 'src/components';
import { useDeleteCode, useToggleCode } from 'src/reactQueries';
import { openModal } from 'src/store';
import { ICode, IGetCodesReq } from 'src/types';

interface IAccessCodeTableRowProps {
  accessCode: ICode;
  allCodesQueryKeys: IGetCodesReq;
}

export const AccessCodeTableRow = ({ accessCode, allCodesQueryKeys }: IAccessCodeTableRowProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { accelerator, code, id, isActive, redemptionCounter } = accessCode;

  const { toggleCode } = useToggleCode(id, allCodesQueryKeys);
  const { deleteCode } = useDeleteCode(id, allCodesQueryKeys);

  const onToggle = () => toggleCode();

  const onDelete = () =>
    dispatch(
      openModal({
        headerText: `Remove ${code} access code`,
        descriptionText: `Are you sure you want to remove ${code}?`,
        onConfirm: deleteCode,
      }),
    );

  return (
    <>
      <TableRow hoverBgColor="#ebecf2">
        <TableCell>{code}</TableCell>
        <TableCell>{accelerator}</TableCell>
        <TableCell>{isActive ? 'Active' : 'Disabled'}</TableCell>
        <TableCell>
          <Link onClick={() => navigate(`/support/access-code/${id}`, { state: { accelerator, code } })}>
            {redemptionCounter}
          </Link>
        </TableCell>
        <TableCell contentStyle={{ width: '100%' }}>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Box display="flex" alignItems="center" gap="8px">
              <Toggle checked={isActive} onChange={onToggle} checkedBgColor="#002F53" />
              <Typography color="#000000">{isActive ? 'On' : 'Off'}</Typography>
            </Box>

            <Typography onClick={onDelete} color="#FF4D4F" medium pointer underline>
              Delete
            </Typography>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};

import styled from '@emotion/styled';

export const DataPicker = styled.input`
  box-sizing: border-box;
  max-width: 180px;
  font-size: 14px;
  outline: 0;
  padding: 5px 10px;
  border: 2px solid #dcdce6;
  border-radius: 5px;

  &:focus {
    outline: none;
    border: 2px solid #2361fc;
    border-radius: 5px;
    box-shadow:
      inset 0 2px 1px 0 rgba(0, 0, 0, 0.1),
      0 0 10px 0 rgba(35, 97, 252, 0.5);
  }
`;

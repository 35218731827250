import classNames from 'classnames';
import { ReactNode } from 'react';
import { UseFormReturn } from 'react-hook-form';
import styled from '@emotion/styled';
import { faCloudUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import { Tooltip, Typography } from 'src/components';
import { IBrandingForm } from 'src/containers/Support/TeamDetails/TeamWhiteLabel/Branding';

const Label = styled.label`
  box-sizing: border-box;
  padding: 0 16px;
  height: 40px;
  display: flex;
  align-items: center;
  gap: 15px;
  background: #ffffff;
  border: 1px solid #bfbfbf;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 4px;
  width: 180px;

  &.error {
    border: 1px solid rgb(246, 39, 19);
    box-shadow:
      inset 0 1px 1px rgb(0 0 0 / 8%),
      0 0 8px rgb(246 39 19 / 60%);
  }
`;

export interface IUploadInputProps {
  label?: string;
  description?: ReactNode;
  tooltip?: ReactNode;
  form: UseFormReturn<IBrandingForm>;
  name: 'favicon' | 'logo' | 'logoIcon';
}

export const UploadInput = ({ label, description, tooltip, name, form }: IUploadInputProps) => {
  const { formState, watch } = form;

  const { isRequired, name: fileName } = watch(name);

  return (
    <Box>
      <Typography fontSize="14px" mb="6px">
        {label}{' '}
        {isRequired && (
          <Typography inline color="error.light">
            *
          </Typography>
        )}
      </Typography>
      <Tooltip title={tooltip}>
        <Label htmlFor={name} className={classNames({ error: formState.errors[name] })}>
          <FontAwesomeIcon icon={faCloudUpload} />{' '}
          <Typography crop fontSize="16px">
            {fileName || 'Upload'}
          </Typography>
        </Label>
      </Tooltip>
      <Typography color="#8C8C8C" fontSize="12px" mt="2px">
        {description}
      </Typography>
    </Box>
  );
};

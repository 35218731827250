import { PropsWithChildren, ReactNode } from 'react';
import styled from '@emotion/styled';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, BoxProps, TooltipProps } from '@mui/material';

import { Tooltip } from 'src/components/ui/Tooltip';

const Spinner = styled(FontAwesomeIcon)`
  animation: fa-spin 2s linear infinite;
`;

const StyleActionButton = styled(Box)<{ disabled?: boolean }>`
  box-sizing: border-box;
  cursor: pointer;
  color: #2361fc;
  background-color: #ffffff;
  border: 1px solid #e3e3eb !important;
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  min-width: 32px;
  ${({ disabled }) => disabled && 'opacity: 0.5; cursor: not-allowed;'}

  &:hover {
    background-color: #f3f3f3;
  }
`;

export interface IActionButtonProps extends BoxProps {
  tooltip?: ReactNode;
  tooltipProps?: TooltipProps;
  icon?: IconProp;
  color?: string;
  disabled?: boolean;
  processing?: boolean;
}

export const ActionButton = ({
  tooltip,
  tooltipProps,
  icon,
  disabled,
  processing,
  children,
  onClick,
  color,
  ...boxProps
}: PropsWithChildren<IActionButtonProps>) => {
  const isDisabled = disabled || processing;

  const content = children ? children : icon && <FontAwesomeIcon color={color} icon={icon} fontSize="14px" />;

  const onClickHandler = (event: React.MouseEvent<HTMLDivElement>) => !isDisabled && onClick && onClick(event);

  return (
    <Tooltip {...tooltipProps} title={tooltip}>
      <StyleActionButton {...boxProps} onClick={onClickHandler} disabled={isDisabled}>
        {processing ? <Spinner size="lg" icon={faSpinner} /> : content}
      </StyleActionButton>
    </Tooltip>
  );
};

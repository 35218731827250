import styled from '@emotion/styled';
import { Box, BoxProps } from '@mui/material';

const StyledBox = styled(Box)`
  width: 600px;
  background-color: #ffffff;
  border-radius: 6px;
`;

export const ModalInner = ({ children, ...restProps }: BoxProps) => <StyledBox {...restProps}>{children}</StyledBox>;

import { Box } from '@mui/material';

import { HeaderContainer, HeaderTitle } from 'src/components';
import { GeneralStats } from 'src/containers/Analytics/AnalyticStats';
import { Activations } from 'src/containers/Analytics/Tables/Activations';
import { Conversions } from 'src/containers/Analytics/Tables/Conversions';
import { PaidTeams } from 'src/containers/Analytics/Tables/PaidTeams';
import { Subscriptions } from 'src/containers/Analytics/Tables/Subscriptions';
import { Trials } from 'src/containers/Analytics/Tables/Trials';

export const General = () => {
  return (
    <>
      <HeaderContainer pb="15px">
        <HeaderTitle>Analytics</HeaderTitle>
      </HeaderContainer>
      <Box m="16px" display="grid" gap="16px">
        <GeneralStats />
        <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" gap="18px">
          <Trials />
          <Conversions />
          <Activations />
        </Box>
        <Box display="grid" gridTemplateColumns="1fr 1fr" gap="18px">
          <Subscriptions />
          <PaidTeams />
        </Box>
      </Box>
    </>
  );
};

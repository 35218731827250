import { SubscriptionPauseReasons } from 'src/enums';

export const subscriptionPausesOptions = Object.values(SubscriptionPauseReasons).reduce(
  (gen, reason) => {
    gen.push({ label: reason, value: reason });

    return gen;
  },
  [{ label: 'All', value: 'all' }] as Array<{ value: SubscriptionPauseReasons | 'all'; label: string }>,
);

import { PropsWithChildren, ReactNode, useMemo } from 'react';
import { Box, BoxProps, styled } from '@mui/material';

import { Typography } from 'src/components';

const ValueContainer = styled(Box)`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #0a150f;
`;

interface IInfoLabelProps extends BoxProps {
  label: ReactNode;
  placeholder?: string;
  noneValue?: boolean;
  disablePlaceholder?: boolean;
  reverse?: boolean;
}

export const InfoLabel = ({
  label,
  noneValue,
  children,
  reverse,
  placeholder = '---',
  disablePlaceholder = false,
  ...props
}: PropsWithChildren<IInfoLabelProps>) => {
  const content = useMemo(() => {
    if (noneValue) {
      return placeholder;
    }

    if (disablePlaceholder || children) {
      return children;
    }

    return placeholder;
  }, [disablePlaceholder, children, noneValue, placeholder]);

  return (
    <Box display="flex" flexDirection={reverse ? 'column-reverse' : 'column'} {...props}>
      <Typography color="#8C8C8C" lineHeight="20px">
        {label}
      </Typography>
      <ValueContainer mt="4px">{content}</ValueContainer>
    </Box>
  );
};

export const InfoLabelWrapper = ({ columns = 4, ...props }: BoxProps & { columns?: number }) => (
  <Box display="grid" gap="24px" gridTemplateColumns={`repeat(${columns}, 1fr)`} {...props} />
);

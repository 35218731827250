import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import {
  Block,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TablePagination,
  TableRow,
  Typography,
} from 'src/components';
import { TeamDetailsLayout } from 'src/containers';
import { useGetReferredTeams } from 'src/reactQueries';
import { IReferredTeamsSortFields, ITablePagination, TSortOrder } from 'src/types';

export const Referrals = () => {
  const { teamId } = useParams();
  const TEAM_ID = Number(teamId);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState<TSortOrder>('ASC');
  const [sortField, setSortField] = useState<IReferredTeamsSortFields>('name');

  const { referredTeams, isLoading } = useGetReferredTeams(TEAM_ID, { page, perPage, sortField, sortOrder });
  const { teams = [], total } = referredTeams || {};

  const sortingProps = {
    sortField,
    sortOrder,
    onSortFieldChange: (field: IReferredTeamsSortFields, order: TSortOrder) => {
      setSortField(field);
      setSortOrder(order);
    },
  };

  const paginationChangeHandler = (pagination: ITablePagination) => {
    setPage(pagination.page);
    setPerPage(pagination.perPage);
  };

  return (
    <TeamDetailsLayout>
      <Block>
        <Table placeholder={!total && 'No referrals found'} processing={isLoading} disabled={isLoading}>
          <TableHead secondary>
            <TableRow>
              <TableHeadCell secondary name="name" width="30%" sorting={sortingProps}>
                Team Name
              </TableHeadCell>
              <TableHeadCell secondary name="plan" sorting={sortingProps}>
                Plan
              </TableHeadCell>
              <TableHeadCell secondary name="seats" sorting={sortingProps}>
                Seats
              </TableHeadCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {teams.map(({ id, name, plan, seats }) => (
              <TableRow key={id}>
                <TableCell>
                  <Link to={`/support/teams/${id}/general-information`} medium underline>
                    {name}
                  </Link>
                </TableCell>
                <TableCell>
                  <Typography medium>{plan}</Typography>
                </TableCell>
                <TableCell>
                  <Typography medium>{seats}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Box mx="10px">
          <TablePagination page={page} perPage={perPage} total={total} onPaginationChange={paginationChangeHandler} />
        </Box>
      </Block>
    </TeamDetailsLayout>
  );
};

import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getPaidTeamsAnalytic } from 'src/api';
import { IGetPaidTeam, IGetPaidTeamsReq } from 'src/types';

export const useGetPaidTeamsAnalytic = <TData extends IGetPaidTeam[]>(
  params: IGetPaidTeamsReq,
  options?: UseQueryOptions<IGetPaidTeam[], AxiosError, TData>,
) => {
  const { data, ...rest } = useQuery<IGetPaidTeam[], AxiosError, TData>(
    ['get-paid-team-analytic', JSON.stringify(params)],
    () => getPaidTeamsAnalytic(params),
    options,
  );

  return { paidTeams: data, ...rest };
};

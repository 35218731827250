import { useParams } from 'react-router-dom';

import { Block, InfoLabel, InfoLabelWrapper } from 'src/components';
import { UserDetailsLayout } from 'src/containers';
import { ActionsTable } from 'src/containers/Support/_components';
import { useGetUserLeadsInfo } from 'src/reactQueries';

export const LeadsInfo = () => {
  const { userId } = useParams();
  const USER_ID = Number(userId);

  const { leadsInfo } = useGetUserLeadsInfo(USER_ID);
  const { approvedLeads, connectedLeads, emailsSent, followedUpAfterConnect, invites, profileViews, totalLeads } =
    leadsInfo ?? {};

  return (
    <UserDetailsLayout>
      <Block p="24px">
        <InfoLabelWrapper>
          <InfoLabel disablePlaceholder label="Total">
            {totalLeads || 0}
          </InfoLabel>
          <InfoLabel disablePlaceholder label="Approved Leads">
            {approvedLeads || 0}
          </InfoLabel>
          <InfoLabel disablePlaceholder label="Profile Views">
            {profileViews || 0}
          </InfoLabel>
          <InfoLabel disablePlaceholder label="Invites">
            {invites || 0}
          </InfoLabel>
          <InfoLabel disablePlaceholder label="Connected Leads">
            {connectedLeads || 0}
          </InfoLabel>
          <InfoLabel disablePlaceholder label="Followed-up after Connect">
            {followedUpAfterConnect || 0}
          </InfoLabel>
          <InfoLabel disablePlaceholder label="Emails Sent">
            {emailsSent || 0}
          </InfoLabel>
        </InfoLabelWrapper>
      </Block>
      <ActionsTable type="leads" userId={USER_ID} title="Last 10 Lead Actions" />
    </UserDetailsLayout>
  );
};

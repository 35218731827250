import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { verifyUserEmail } from 'src/api';
import { showToast } from 'src/store';
import { IGetUserInfoRes } from 'src/types';

export const useVerifyUserEmail = (userId: number) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const getUserInfoKey = ['get-user-info', userId];

  const { mutate, ...rest } = useMutation(['verify-email', userId], () => verifyUserEmail(userId), {
    onMutate: async () => {
      await queryClient.cancelQueries(getUserInfoKey);

      const prevData = queryClient.getQueryData(getUserInfoKey);

      queryClient.setQueryData<IGetUserInfoRes | undefined>(getUserInfoKey, (data) => {
        if (data) {
          return { ...data, isEmailVerified: true };
        }

        return data;
      });

      return { prevData };
    },
    onError: (err, _vars, context) => {
      queryClient.setQueryData(getUserInfoKey, context?.prevData);

      dispatch(
        showToast({
          type: 'error',
          message: (err as Error)?.message ?? 'Something went wrong',
          autoCloseTime: 3000,
        }),
      );
    },
  });

  return { verifyEmail: mutate, ...rest };
};

import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getOnboardingConversions } from 'src/api';
import { IAnalyticSkip, IOnboardingConversion } from 'src/types';

export const useGetOnboardingConversions = <TData extends IOnboardingConversion[]>(
  params: IAnalyticSkip,
  options?: UseQueryOptions<IOnboardingConversion[], AxiosError, TData>,
) => {
  const { data, ...rest } = useQuery<IOnboardingConversion[], AxiosError, TData>(
    ['get-onboarding-conversions-analytic', JSON.stringify(params)],
    () => getOnboardingConversions(params),
    options,
  );

  return { onboardingConversions: data, ...rest };
};

import dayjs from 'dayjs';
import { PropsWithChildren, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import {
  Block,
  Breadcrumbs,
  Button,
  Checkbox,
  DataPicker,
  HeaderContainer,
  HeaderTitle,
  MultiSelect,
  Select,
  TabLink,
  TabsContainer,
  Typography,
} from 'src/components';
import { DateGroupType, LiStatsActionsEnum } from 'src/enums';

interface LinkedInActionsLayoutProps extends PropsWithChildren {
  title: string;
  onFiltersApply: (params: {
    action: LiStatsActionsEnum;
    fromDate: string;
    toDate: string;
    groupType: DateGroupType;
    cloudPools: Array<string>;
    httpClientOnly: boolean;
  }) => void;
}

const SelectWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const LINKEDIN_ACTIONS_TABS = [
  { label: 'Number of Users Affected', to: 'users-affected' },
  { label: 'Number of Actions', to: 'actions' },
  { label: 'Number of Actions per User', to: 'actions-per-user' },
];

const ACTIONS_OPTIONS = [
  {
    value: LiStatsActionsEnum.redirectLoop,
    label: 'LI Session killed (redirect_loop)',
  },
  {
    value: LiStatsActionsEnum.tryingLinkedInLogin,
    label: 'Tried to login with LI (trying linkedin login)',
  },
  {
    value: LiStatsActionsEnum.manualLinkedInLoginEnded,
    label: 'Manual login with LI finished (LinkedIn login ended)',
  },
  {
    value: LiStatsActionsEnum.autoLinkedInLoginEnded,
    label: 'Autologin with LI finished (lnkedin login ended)',
  },
  { value: LiStatsActionsEnum.proxyAdded, label: 'User got new proxy (proxy added)' },
  { value: LiStatsActionsEnum.unusualActivityWarningDetected, label: 'Unusual activity Warning detected' },
];

const CLOUD_POOL_IDS = Array.from(Array(8)).map((_, i) => ({ label: `cloud${i + 1}`, value: `cloud${i + 1}` }));
const ALL_SERVERS_OPTION = { label: 'All servers', value: 'all' };

const GROUP_BY_OPTIONS = Object.entries(DateGroupType).map(([label, value]) => ({ label, value }));

export const LinkedInActionsLayout = ({ children, title, onFiltersApply }: LinkedInActionsLayoutProps) => {
  const [queryDateFrom, setQueryDateFrom] = useState(dayjs().subtract(1, 'week').format('YYYY-MM-DD'));
  const [queryDateTo, setQueryDateTo] = useState(dayjs().format('YYYY-MM-DD'));
  const [action, setAction] = useState(LiStatsActionsEnum.redirectLoop);
  const [groupType, setGroupType] = useState(DateGroupType.day);
  const [cloudPools, setCloudPools] = useState([ALL_SERVERS_OPTION]);
  const [isHttpClientOnly, setIsHttpClientOnly] = useState(false);

  const onApply = () => {
    onFiltersApply({
      fromDate: queryDateFrom,
      toDate: queryDateTo,
      action,
      groupType,
      cloudPools: cloudPools.map(({ value }) => value).filter((value) => value !== ALL_SERVERS_OPTION.value),
      httpClientOnly: isHttpClientOnly,
    });
  };

  const onServerSelect = (values: Array<{ label: string; value: string }>) => {
    if ([...values].pop()?.value === ALL_SERVERS_OPTION.value) {
      setCloudPools([ALL_SERVERS_OPTION]);
    } else {
      setCloudPools(values.filter(({ value }) => value !== ALL_SERVERS_OPTION.value));
    }
  };

  return (
    <>
      <HeaderContainer>
        <Breadcrumbs>
          <Link to="/li-actions">LinkedIn Actions</Link>
          <Typography>Stats</Typography>
        </Breadcrumbs>
        <Box display="flex" alignItems="center" my="8px">
          <HeaderTitle>LinkedIn Actions</HeaderTitle>
        </Box>
        <TabsContainer>
          {LINKEDIN_ACTIONS_TABS.map(({ label, to }) => {
            const path = `/li-actions/${to}`;

            return (
              <TabLink key={to} isActive={path === location.pathname} to={path}>
                {label}
              </TabLink>
            );
          })}
        </TabsContainer>
      </HeaderContainer>
      <Box m="16px" display="grid" gap="16px">
        <Block p="16px" display="flex" alignItems="center" gap="8px">
          <Typography mr="auto" fontSize="24px">
            {title}
          </Typography>
          <SelectWrapper>
            <Typography>Server:</Typography>
            <MultiSelect
              placeholder="Server:"
              options={[ALL_SERVERS_OPTION, ...CLOUD_POOL_IDS]}
              onChange={onServerSelect}
              defaultValue={ALL_SERVERS_OPTION}
              value={cloudPools}
              minWidth="150px"
              maxWidth="150px"
            />
          </SelectWrapper>
          <Box display="flex" alignItems="center" justifyContent="space-between" gap="5px">
            <Typography>Http Client Only</Typography>
            <Checkbox
              noMargin
              checked={isHttpClientOnly}
              onChange={(e) => setIsHttpClientOnly(e.target.checked)}
              type="checkbox"
            />
          </Box>
          <SelectWrapper>
            <Typography>Action:</Typography>
            <Select
              placeholder="Action:"
              options={ACTIONS_OPTIONS}
              onChange={(nextValue) => setAction(nextValue.value)}
              value={action}
              minWidth="150px"
              maxWidth="150px"
              styles={{ menu: (provided) => ({ ...provided, width: '300px' }) }}
            />
          </SelectWrapper>
          <SelectWrapper>
            <Typography>Group by:</Typography>
            <Select
              placeholder="Group by: "
              options={GROUP_BY_OPTIONS}
              onChange={(nextValue) => setGroupType(nextValue.value)}
              value={groupType}
              minWidth="80px"
              maxWidth="80px"
            />
          </SelectWrapper>
          <Typography whiteSpace="pre" fontSize="16px">
            Filter by Date:
          </Typography>
          <Box display="flex" alignItems="center" gap="5px">
            <DataPicker
              type="date"
              value={queryDateFrom}
              placeholder="from"
              max={queryDateTo}
              onChange={(e) => e.target.value && setQueryDateFrom(e.target.value)}
            />
            <FontAwesomeIcon icon={faArrowRight} />
            <DataPicker
              type="date"
              value={queryDateTo}
              placeholder="to"
              max={dayjs().format('YYYY-MM-DD')}
              min={queryDateFrom}
              onChange={(e) => e.target.value && setQueryDateTo(e.target.value)}
            />
          </Box>
          <Button size={22} onClick={onApply}>
            Apply
          </Button>
        </Block>
        {children}
      </Box>
    </>
  );
};

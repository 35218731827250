import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Box } from '@mui/material';

import {
  ActionButton,
  Block,
  BlockHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Typography,
} from 'src/components';
import { addLabelTitles, separateNumWithComma } from 'src/helpers';
import { useGetUsersActions } from 'src/reactQueries';

const labels = {
  registered: 'Registered',
  verified: 'Verified',
  linkedInConnected: 'Linkedin Connected',
  emailConnected: 'Email Connected',
  twitterConnected: 'Twitter connected',
  ranCampaign: 'Ran Campaign',
};

export const Activations = () => {
  const [skip, setSkip] = useState(0);
  const absSkip = Math.abs(skip);

  const { usersActions, isLoading } = useGetUsersActions({ skip: absSkip });
  const labelsUsersActions = useMemo(() => addLabelTitles(usersActions, labels, 'label'), [usersActions]);
  const totalUsersActions = labelsUsersActions.find(({ title }) => title === labels.registered);

  const showMonth = [
    dayjs().subtract(absSkip + 2, 'M'),
    dayjs().subtract(absSkip + 1, 'M'),
    dayjs().subtract(absSkip, 'M'),
  ];

  return (
    <Block>
      <BlockHeader py="0" height="64px" border="none" bgcolor="white">
        <Typography medium fontSize="20px">
          Activations
        </Typography>
        <Box display="flex" gap="10px">
          <ActionButton icon={faArrowLeft} onClick={() => setSkip((prev) => prev - 1)} />
          <ActionButton icon={faArrowRight} disabled={skip >= 0} onClick={() => setSkip((prev) => prev + 1)} />
        </Box>
      </BlockHeader>
      <Table
        placeholder={!labelsUsersActions.length && 'No activations found'}
        processing={isLoading}
        disabled={isLoading}
      >
        <TableHead secondary>
          <TableRow>
            <TableHeadCell width="45%" secondary />
            {showMonth.map((data) => (
              <TableHeadCell key={data.toISOString()} secondary justifyContent="flex-end">
                {data.format("MMM 'YY'")}
              </TableHeadCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {labelsUsersActions?.map((action, index) => {
            return (
              <TableRow key={action?.title ?? index}>
                <TableCell>{action?.title}</TableCell>
                {showMonth.map((data) => {
                  const monthKey = data.format('YYYY/MM');
                  const value = action[monthKey] || 0;
                  const totalValue = totalUsersActions?.[monthKey] || 0;

                  const totalPercentage = Number((value * 100) / (totalValue || 1)).toFixed(2);

                  return (
                    <TableCell key={monthKey} justifyContent="flex-end">
                      {separateNumWithComma(value)}{' '}
                      <Typography fontSize="12px" inline>
                        ({totalPercentage}%)
                      </Typography>
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Block>
  );
};

import { useParams } from 'react-router-dom';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import { Block, InfoLabel, InfoLabelWrapper, Toggle, Tooltip } from 'src/components';
import { TeamDetailsLayout } from 'src/containers';
import { Branding } from 'src/containers/Support/TeamDetails/TeamWhiteLabel/Branding';
import { CustomDomain } from 'src/containers/Support/TeamDetails/TeamWhiteLabel/CustomDomain';
import { CustomEmail } from 'src/containers/Support/TeamDetails/TeamWhiteLabel/CustomEmail';
import { useGetTeamWhiteLabel, useToggleWhiteLabelAdvancedSettings } from 'src/reactQueries';

export const TeamWhiteLabel = () => {
  const { teamId } = useParams();
  const TEAM_ID = Number(teamId);

  const { toggleAdvancedSettings } = useToggleWhiteLabelAdvancedSettings(TEAM_ID);
  const { whitelabel: whiteLabel } = useGetTeamWhiteLabel(TEAM_ID);
  const { companyName, domain, isAdvancedSettingsSetupAllowed, status, isDomainValidated } = whiteLabel ?? {};

  return (
    <TeamDetailsLayout>
      <Block p="24px">
        <InfoLabelWrapper columns={3}>
          <InfoLabel label="Company Name">{companyName}</InfoLabel>
          <InfoLabel label="Domain">{domain}</InfoLabel>
          <InfoLabel label="Status">{status === 'active' ? 'Active' : 'Inactive'}</InfoLabel>
          <InfoLabel label="Domain Validated">{isDomainValidated ? 'Yes' : 'No'}</InfoLabel>

          <InfoLabel
            label={
              <>
                Advanced Settings Allowed{' '}
                <Tooltip title="This allows user to set/edit the Custom domain and SMTP settings.">
                  <FontAwesomeIcon color="#000" size="sm" icon={faQuestionCircle} />
                </Tooltip>
              </>
            }
          >
            <Box display="flex" gap="10px">
              <Toggle checked={isAdvancedSettingsSetupAllowed} onChange={() => toggleAdvancedSettings()} />
              {isAdvancedSettingsSetupAllowed ? 'Yes' : 'No'}
            </Box>
          </InfoLabel>
        </InfoLabelWrapper>
        {!!whiteLabel && (
          <Box mt="20px" display="flex" flexDirection="column" gap="20px">
            <Branding />
            <CustomDomain />
            <CustomEmail />
          </Box>
        )}
      </Block>
    </TeamDetailsLayout>
  );
};

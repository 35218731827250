import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getNumberOfLIActionsPerUser } from 'src/api';
import { IGetLinkedInActionsStats, ILinkedInActionsStats } from 'src/types';

export const useGetNumberOfLIActionsPerUser = <TData extends ILinkedInActionsStats>(
  params: IGetLinkedInActionsStats,
  options?: UseQueryOptions<ILinkedInActionsStats, AxiosError, TData>,
) => {
  return useQuery<ILinkedInActionsStats, AxiosError, TData>(
    ['get-li-actions-number-per-user', JSON.stringify(params)],
    () => getNumberOfLIActionsPerUser(params),
    options,
  );
};

import dayjs from 'dayjs';
import { useState } from 'react';
import { Box } from '@mui/material';

import { Block, Table, TableBody, TableCell, TableHead, TableHeadCell, TableRow, Typography } from 'src/components';
import { DateGroupType, LiStatsActionsEnum } from 'src/enums';
import { useSortLinkedinActionsStats } from 'src/hooks/useSortLinkedinActionsStats';
import { useGetNumberOfLIActionsPerUser } from 'src/reactQueries';
import { IGetLinkedInActionsStats } from 'src/types';
import { LinkedInActionsLayout } from './LinkedInActionsLayout';

export const ActionsPerUser = () => {
  const [params, setParams] = useState<IGetLinkedInActionsStats>({
    fromDate: dayjs().subtract(1, 'week').format('YYYY-MM-DD'),
    toDate: dayjs().format('YYYY-MM-DD'),
    action: LiStatsActionsEnum.redirectLoop,
    groupType: DateGroupType.day,
    cloudPools: [],
    httpClientOnly: false,
  });

  const { data, isLoading } = useGetNumberOfLIActionsPerUser(params);
  const { sortingProps } = useSortLinkedinActionsStats(data);

  return (
    <LinkedInActionsLayout
      title="Number of Actions per User"
      onFiltersApply={(params) => {
        setParams((prev) => ({ ...prev, ...params }));
      }}
    >
      <Box display="flex" gap="8px">
        <Block overflow="hidden" width="50%">
          <Table placeholder={!data?.data?.length && 'No data'} processing={isLoading} disabled={isLoading}>
            <TableHead secondary>
              <TableRow>
                <TableHeadCell secondary name="date" sorting={sortingProps}>
                  Date
                </TableHeadCell>
                <TableHeadCell justifyContent="flex-end" secondary name="userId" sorting={sortingProps}>
                  User ID
                </TableHeadCell>
                <TableHeadCell justifyContent="flex-end" secondary name="actions" sorting={sortingProps}>
                  Count
                </TableHeadCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {data?.data?.map(({ date, actions, userId }, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Typography>
                      {dayjs(date).format(
                        `${params.groupType !== DateGroupType.month ? 'DD ' : ''}MMMM YYYY${
                          params.groupType === DateGroupType.hour ? ' HH:00' : ''
                        }`,
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell justifyContent="flex-end">
                    <Typography>{userId}</Typography>
                  </TableCell>
                  <TableCell justifyContent="flex-end">
                    <Typography>{actions}</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Block>
      </Box>
    </LinkedInActionsLayout>
  );
};

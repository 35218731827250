import clsx from 'clsx';
import { InputHTMLAttributes, ReactNode, useState } from 'react';
import { RegisterOptions, UseFormRegister } from 'react-hook-form';
import styled from '@emotion/styled';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, TooltipProps } from '@mui/material';

import { Tooltip } from '../ui/Tooltip';

const Label = styled.label`
  font-family: 'ProximaSoft', sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.3rem;
`;

const StyledInputContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const StyledInput = styled.input<{ preTab?: boolean; generalType?: string }>`
  display: block;
  width: 100%;
  padding: 6px ${({ generalType }) => (generalType === 'password' ? '38px' : '12px')} 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-width: 1px !important;
  border: 1px solid #dfdfe8;
  border-radius: ${({ preTab }) => (preTab ? '0 4px 4px 0' : '4px')};
  transition:
    border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  height: 42px;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  box-sizing: border-box;
  margin-top: 5px;

  &:focus {
    border: 1px solid #2361fc;
    box-shadow:
      inset 0 1px 1px rgb(0 0 0 / 8%),
      0 0 8px rgba(35, 96, 252, 0.6);
    outline: 0;
  }

  &.error {
    border: 1px solid #f62713;
    &:focus {
      box-shadow:
        inset 0 1px 1px rgb(0 0 0 / 8%),
        0 0 8px rgb(246 39 19 / 60%);
    }
  }

  &::placeholder {
    color: #98a9ac;
  }
`;

const PreInputTab = styled(Box)`
  display: flex;
  align-items: center;
  padding: 0 12px;
  height: 42px;
  background: #fafafa;
  margin-top: 5px;
  border: 1px solid #bfbfbf;
  box-sizing: border-box;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  border-radius: 4px 0 0 4px;
`;

const StyledIconContainer = styled.div`
  display: flex;
  position: absolute;
  cursor: pointer;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #999;

  &.visible {
    right: 9px;
  }
`;

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  register?: UseFormRegister<any>; // eslint-disable-line
  name: string;
  parameters?: RegisterOptions;
  label?: string | JSX.Element;
  labelStyle?: string;
  tooltip?: Omit<TooltipProps, 'children'>;
  icon?: IconProp;
  preTab?: ReactNode;
  onIconClick?: () => void;
}

export const Input = ({
  register,
  name,
  parameters = {},
  label,
  type,
  labelStyle,
  tooltip = {
    title: '',
  },
  icon,
  onIconClick,
  preTab,
  width = '100%',
  ...restProps
}: InputProps) => {
  const [visible, setVisible] = useState(type !== 'password');

  return (
    <Box width={width} color="#002f53">
      <Label htmlFor={name} className={labelStyle}>
        {label}
      </Label>
      <StyledInputContainer>
        {preTab && <PreInputTab>{preTab}</PreInputTab>}
        <Tooltip {...tooltip}>
          <StyledInput
            height="40px"
            {...(register ? register(name, parameters) : {})}
            {...restProps}
            type={visible ? 'input' : 'password'}
          />
        </Tooltip>
        {icon && (
          <StyledIconContainer onClick={onIconClick} className={clsx({ visible })}>
            <FontAwesomeIcon icon={icon} />
          </StyledIconContainer>
        )}
        {type === 'password' && !icon && (
          <StyledIconContainer
            onClick={() => restProps.disabled || setVisible((prevState) => !prevState)}
            className={clsx({ visible })}
          >
            <FontAwesomeIcon icon={visible ? faEyeSlash : faEye} />
          </StyledIconContainer>
        )}
      </StyledInputContainer>
    </Box>
  );
};

import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Checkbox, FormInput, Typography } from 'src/components';
import { Accordion, AccordionDetails, AccordionSummary } from 'src/containers/Support/_components';
import { useGetTeamWhiteLabel } from 'src/reactQueries';
import { ISmtpSettings } from 'src/types';

const FormColumns = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 40px;
  max-width: 80%;
`;

interface IForm extends Omit<ISmtpSettings, 'port'> {
  port: number | string;
}

export const CustomEmail = () => {
  const { teamId } = useParams();
  const TEAM_ID = Number(teamId);
  const [expanded, setExpanded] = useState(false);

  const { whitelabel } = useGetTeamWhiteLabel(TEAM_ID);

  const { register } = useForm<IForm>({ defaultValues: whitelabel?.smtpSettings });

  return (
    <Accordion expanded={expanded} onChange={() => setExpanded((prev) => !prev)}>
      <AccordionSummary
        expanded={expanded}
        title="Custom Email Address"
        status={whitelabel?.smtpSettings?.smtpServer ? 'applied' : 'pending'}
      />
      <AccordionDetails>
        <FormColumns mt="24px">
          <FormInput
            label="Reply to Email"
            name="replyToEmail"
            type="email"
            placeholder="support@yourdomain.com"
            disabled
            register={register}
          />
          <FormInput
            label="From (sender) Name"
            name="senderName"
            placeholder="Your App Name Team"
            disabled
            register={register}
          />
        </FormColumns>
        <FormColumns mt="20px">
          <FormInput label="SMTP Server" name="smtpServer" placeholder="smtp.hello.com" disabled register={register} />
          <FormInput label="SMTP Username" name="username" placeholder="e.g. John Doe" disabled register={register} />

          <FormInput
            disabled
            label="SMTP Password"
            name="password"
            type="password"
            placeholder="Set Your Password"
            value="hack me"
          />
        </FormColumns>
        <FormColumns mt="34px">
          <Box>
            <FormInput
              name="port"
              placeholder="8080"
              disabled
              register={register}
              preTab={
                <>
                  <Typography inline color="#BFBFBF">
                    Port{' '}
                  </Typography>
                  <Typography inline color="error.light">
                    *
                  </Typography>
                </>
              }
            />
          </Box>
          <Box display="flex" alignItems="center" pb="5px">
            <Checkbox pointer={false} disabled type="checkbox" {...register('isTlsEnabled')} />
            <Typography>Enable start TLS/SSL</Typography>
          </Box>
        </FormColumns>
      </AccordionDetails>
    </Accordion>
  );
};

import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Block, Button, InfoLabel, InfoLabelWrapper, Input, Typography } from 'src/components';
import { REGEXP_VALID_EMAIL } from 'src/constants';
import { UserDetailsLayout } from 'src/containers';
import { ActionsTable } from 'src/containers/Support/_components';
import { ModalTypes } from 'src/enums';
import { useChangeUserEmail, useGetUserInfo, useToggleUserBlock, useVerifyUserEmail } from 'src/reactQueries';
import { openModal, showToast } from 'src/store';

const StyledInput = styled(Input)`
  height: 23px;
`;

export const UserProfile = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const USER_ID = Number(userId);
  const [emailInput, setEmailInput] = useState('');
  const [isEmailEdit, setIsEmailEdit] = useState(false);

  const { userInfo, isLoading } = useGetUserInfo(USER_ID);
  const { changeEmail } = useChangeUserEmail(USER_ID);
  const { toggleBlock, isLoading: isBlockingUser } = useToggleUserBlock(USER_ID);
  const { verifyEmail, isLoading: isVerifyingEmail } = useVerifyUserEmail(USER_ID);
  const {
    createdAt,
    email = '',
    lastConnectedAt,
    activeCampaignsCount = 0,
    archivedCampaignsCount = 0,
    name,
    isBlocked,
    isEmailVerified,
  } = userInfo ?? {};

  useEffect(() => {
    if (email) {
      setEmailInput(email);
    }
  }, [email]);

  const onChangeEmailClick = () => {
    if (isEmailEdit) {
      if (!REGEXP_VALID_EMAIL.test(emailInput)) {
        return dispatch(
          showToast({
            type: 'error',
            message: 'please enter a valid email',
          }),
        );
      }
      if (emailInput === email) {
        setIsEmailEdit((prev) => !prev);

        return dispatch(
          showToast({
            type: 'error',
            message: 'Email exists',
          }),
        );
      }

      changeEmail({ email: emailInput });
    }

    return setIsEmailEdit((prev) => !prev);
  };

  const onChangePasswordClick = () => {
    dispatch(openModal({ type: ModalTypes.CHANGE_PASSWORD, headerText: 'Change password', params: { userId } }));
  };

  return (
    <UserDetailsLayout>
      <Block p="24px">
        <InfoLabelWrapper>
          <InfoLabel label="Name" noneValue={!name}>
            {name}
          </InfoLabel>
          <InfoLabel label="Registration Email">
            {REGEXP_VALID_EMAIL.test(email) ? (
              <Box display="flex" alignItems="center" justifyContent="space-between" gap="15px">
                {isEmailEdit ? (
                  <StyledInput name="email" value={emailInput} onChange={(e) => setEmailInput(e.target.value)} />
                ) : (
                  <Typography semibold crop>
                    {emailInput}
                  </Typography>
                )}
                <Button variant="whiteBlue" size={12} lineHeight="14px" onClick={onChangeEmailClick}>
                  {isEmailEdit ? 'Save' : 'Edit'}
                </Button>
              </Box>
            ) : (
              <Typography medium>
                <em>User without email</em>
              </Typography>
            )}
          </InfoLabel>
          <InfoLabel label="Password" noneValue={isLoading}>
            <Box display="flex" alignItems="center" justifyContent="flex-start" gap="60px">
              <Typography semibold crop>
                *********
              </Typography>
              {REGEXP_VALID_EMAIL.test(email) && (
                <Button variant="whiteBlue" size={12} lineHeight="14px" onClick={onChangePasswordClick}>
                  Change password
                </Button>
              )}
            </Box>
          </InfoLabel>
          <InfoLabel label="Joined At" noneValue={!createdAt}>
            {dayjs(createdAt).format('DD MMM, YYYY')}
          </InfoLabel>
          <InfoLabel label="Last User Connection" noneValue={!lastConnectedAt}>
            {dayjs(lastConnectedAt).format('DD MMM, YYYY')}
          </InfoLabel>
          <InfoLabel label="User blocked" noneValue={isLoading}>
            <Box display="flex" alignItems="center" justifyContent="flex-start" gap="60px">
              <Typography semibold crop>
                {isBlocked ? 'True' : 'False'}
              </Typography>
              <Button
                disabled={isBlockingUser}
                variant="whiteBlue"
                size={12}
                lineHeight="14px"
                onClick={() => toggleBlock()}
              >
                {isBlocked ? 'Unblock' : 'Block'}
              </Button>
            </Box>
          </InfoLabel>
          <InfoLabel disablePlaceholder label="Total Campaigns">
            {(activeCampaignsCount || 0) + (archivedCampaignsCount || 0)}
          </InfoLabel>
          <InfoLabel disablePlaceholder label="Active Campaigns">
            {activeCampaignsCount || 0}
          </InfoLabel>
          <InfoLabel disablePlaceholder label="Archived Campaigns">
            {archivedCampaignsCount || 0}
          </InfoLabel>
          <InfoLabel label="Email verified" noneValue={isLoading}>
            <Box display="flex" alignItems="center" justifyContent="flex-start" gap="60px">
              <Typography semibold crop lineHeight="22px">
                {isEmailVerified ? 'True' : 'False'}
              </Typography>
              {!isEmailVerified && (
                <Button
                  disabled={isVerifyingEmail}
                  variant="whiteBlue"
                  size={12}
                  lineHeight="14px"
                  onClick={() => verifyEmail()}
                >
                  Verify email
                </Button>
              )}
            </Box>
          </InfoLabel>
        </InfoLabelWrapper>
      </Block>
      <ActionsTable title="Last 10 User Actions" type="user" userId={USER_ID} />
    </UserDetailsLayout>
  );
};

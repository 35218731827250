import styled from '@emotion/styled';

import {
  Block,
  BlockHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Typography,
} from 'src/components';
import { separateNumWithComma } from 'src/helpers';
import { useGetPaidTeamsAnalytic } from 'src/reactQueries';

const StyledTable = styled(Table)`
  border-collapse: separate;
`;

const MAX_MEMBERS = 5;

export const PaidTeams = () => {
  const { paidTeams, isLoading } = useGetPaidTeamsAnalytic({ maxMembersCount: MAX_MEMBERS });

  return (
    <Block>
      <BlockHeader py="0" height="64px" bgcolor="white">
        <Typography medium fontSize="20px">
          Paid Teams
        </Typography>
      </BlockHeader>
      <StyledTable
        placeholder={!paidTeams?.length && 'No paid teams found'}
        processing={isLoading}
        disabled={isLoading}
      >
        <TableHead hidden>
          <TableRow>
            <TableHeadCell secondary></TableHeadCell>
            <TableHeadCell justifyContent="flex-end" secondary></TableHeadCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {paidTeams?.map(({ seatsCount, teamsCount }, index) => {
            const membersLabel = seatsCount === 1 ? 'member' : 'members';

            return (
              <TableRow key={index}>
                <TableCell>
                  {seatsCount === MAX_MEMBERS ? `${seatsCount} members and more` : `${seatsCount} ${membersLabel}`}
                </TableCell>
                <TableCell justifyContent="flex-end">
                  <Typography>{separateNumWithComma(teamsCount)}</Typography>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </StyledTable>
    </Block>
  );
};

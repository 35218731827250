import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getTeams } from 'src/api';
import { IGetTeamsRes, ITeamsFilters } from 'src/types';

export const useGetAllTeams = <TData extends IGetTeamsRes>(
  params: ITeamsFilters,
  options?: UseQueryOptions<IGetTeamsRes, AxiosError, TData>,
) => {
  const { seats, billingType, plan, membersCount } = params;

  const processedParams = {
    ...params,
    search: params?.search?.trim(),
    // eslint-disable-next-line no-undefined
    plan: plan === 'all' ? undefined : plan,
    // eslint-disable-next-line no-undefined
    billingType: billingType === 'all' ? undefined : billingType,
    // eslint-disable-next-line no-undefined
    seats: seats === '' ? undefined : Number(seats),
    // eslint-disable-next-line no-undefined
    membersCount: membersCount === '' ? undefined : Number(membersCount),
  };

  const { data, ...rest } = useQuery<IGetTeamsRes, AxiosError, TData>(
    ['get-all-teams', JSON.stringify(processedParams)],
    () => getTeams(processedParams),
    options,
  );

  return { allTeams: data, ...rest };
};

import { PropsWithChildren, ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { ITypographyProps, Tooltip, Typography } from 'src/components/ui';
import { propsBlackList } from 'src/helpers';

const BLACK_LIST_PROPS = ['hoverColor'];

const BoxLinkWrapper = styled(Typography, propsBlackList(BLACK_LIST_PROPS))<{ cursor?: string; hoverColor?: string }>`
  cursor: ${({ cursor }) => cursor};
  & a {
    color: inherit;
  }

  &:hover,
  & a:hover {
    color: ${({ hoverColor }) => hoverColor};
  }
`;

export interface ILinkProps extends ITypographyProps {
  disabled?: boolean;
  tooltip?: ReactNode;
  external?: boolean;
  openNewTab?: boolean;
  to?: string;
  cursor?: string;
  hoverColor?: string;
}

export const Link = ({
  tooltip,
  to = '',
  disabled,
  openNewTab,
  external,
  children,
  onClick,
  hoverColor,
  color,
  ...linkWrapper
}: PropsWithChildren<ILinkProps>) => {
  const externalLink = (
    <a href={to} target={openNewTab ? '_blank' : '_self'} rel={openNewTab ? 'noreferrer' : ''}>
      {children}
    </a>
  );
  const localLink = <RouterLink to={to}>{children}</RouterLink>;

  const linkContent = external ? externalLink : localLink;

  return (
    <Tooltip title={tooltip}>
      <Box>
        <BoxLinkWrapper
          inline
          underline
          color={disabled ? '#717171' : color || '#002F53'}
          hoverColor={disabled ? '#717171' : hoverColor || '#00b2e4'}
          cursor={disabled ? 'not-allowed' : 'pointer'}
          onClick={(event) => disabled || onClick?.(event)}
          {...linkWrapper}
        >
          {disabled || !to ? children : linkContent}
        </BoxLinkWrapper>
      </Box>
    </Tooltip>
  );
};

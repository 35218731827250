import styled from '@emotion/styled';

export const Checkbox = styled.input<{ pointer?: boolean; noMargin?: boolean }>`
  -webkit-appearance: none;
  position: relative;
  border: 1px solid rgba(210, 222, 224, 0.5);
  width: 22px;
  height: 22px;
  zoom: normal;
  border-radius: 5px;
  box-shadow: 0 1px 1px 0 rgba(47, 58, 62, 0.1);
  transition: all 0.1s linear;
  background-color: #ffffff;
  margin-right: ${({ noMargin = false }) => (noMargin ? '0' : '10px')};
  flex-shrink: 0;

  &:checked {
    &::after {
      content: '✓';
      position: absolute;
      display: inline-block;
      width: 100%;
      height: 100%;
      color: #fff;
      text-align: center;
      line-height: 24px;
      background: #2361fc;
      border-radius: 5px;
    }
  }

  &,
  & ~ label {
    cursor: ${({ pointer = true }) => (pointer ? 'pointer' : 'default')};
    font-size: 14px;
    color: #2f3a3e;
  }
`;

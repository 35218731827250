import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getUserAccount } from 'src/api';
import { IUser } from 'src/types';

export const useGetUserAccount = <TData extends IUser>(options?: UseQueryOptions<IUser, AxiosError, TData>) => {
  const { data, ...rest } = useQuery<IUser, AxiosError, TData>('get-user-profile', getUserAccount, options);

  return { userMe: data, ...rest };
};

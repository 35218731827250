import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { toggleWhiteLabelAdvancedSettings } from 'src/api';
import { showToast } from 'src/store';
import { ITeamWhiteLabel } from 'src/types';

export const useToggleWhiteLabelAdvancedSettings = (teamId: number) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const getTeamWhiteLabel = ['get-team-white-label', teamId];

  const { mutate, ...rest } = useMutation(
    ['toggle-white-label-advanced-settings', teamId],
    () => toggleWhiteLabelAdvancedSettings(teamId),
    {
      onMutate: async () => {
        await queryClient.cancelQueries(getTeamWhiteLabel);

        const prevData = queryClient.getQueryData(getTeamWhiteLabel);

        queryClient.setQueriesData<ITeamWhiteLabel | undefined>(getTeamWhiteLabel, (whitelabel) => {
          if (whitelabel) {
            return {
              ...whitelabel,
              isAdvancedSettingsSetupAllowed: !whitelabel.isAdvancedSettingsSetupAllowed,
            };
          }

          return whitelabel;
        });

        return { prevData };
      },

      onError: (err, _vars, context) => {
        queryClient.setQueryData(getTeamWhiteLabel, context?.prevData);

        dispatch(
          showToast({
            type: 'error',
            message: (err as Error)?.message ?? 'Stipe info failed to update. Try later',
          }),
        );
      },
    },
  );

  return { toggleAdvancedSettings: mutate, ...rest };
};

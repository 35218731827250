import { InputHTMLAttributes } from 'react';
import styled from '@emotion/styled';

const StyledInput = styled.input`
  border-radius: 5px;
  border: 2px solid #dcdce6;
  box-sizing: border-box;
  color: #333;
  font-family: 'ProximaSoft', sans-serif;
  font-size: 14px;
  height: 30px;
  line-height: 19px;
  margin-top: 5px;
  padding: 5px 5px 5px 10px;
  width: 100%;

  &::placeholder {
    color: #97a9ac;
  }
  &:focus {
    border-color: #2361fc;
    box-shadow:
      inset 0 2px 1px 0 rgba(0, 0, 0, 0.1),
      0 0 10px 0 rgba(35, 97, 252, 0.5);
    outline: none;
  }
`;

export const ModalTextInput = (props: InputHTMLAttributes<HTMLInputElement>) => <StyledInput type="text" {...props} />;

import dayjs from 'dayjs';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IGetCancellationsReq } from 'src/types';

export const DEFAULT_CANCELLATIONS_REQUEST: Required<IGetCancellationsReq> = {
  page: 1,
  perPage: 10,
  fromDate: dayjs().format('YYYY-MM-01'),
  toDate: dayjs().format('YYYY-MM-DD'),
  sortField: 'createdAt',
  sortOrder: 'DESC',
  search: '',
  reason: null,
};

const initialState = {
  cancellationsRequest: DEFAULT_CANCELLATIONS_REQUEST,
};

export const cancellationSlice = createSlice({
  name: 'cancellation',
  initialState,
  reducers: {
    updateCancellationsRequest: (state, action: PayloadAction<Partial<IGetCancellationsReq>>) => {
      state.cancellationsRequest = { ...state.cancellationsRequest, ...action.payload };
    },
  },
});

export const { updateCancellationsRequest } = cancellationSlice.actions;

export default cancellationSlice.reducer;

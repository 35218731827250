import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { css, Global } from '@emotion/react';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, styled } from '@mui/material';

import { ModalButton } from 'src/components/Modal/_components';
import { ModalAddAdmin } from 'src/components/Modal/admins';
import { Typography } from 'src/components/ui';
import { ModalTypes } from 'src/enums/modal';
import { closeModal, RootState } from 'src/store';
import { ModalChangePassword, ModalCreateAccessCode, ModalExtendTrial, ModalManageCustomPlan } from './support';

const Wrapper = styled(Box)`
  margin: 30px 0;
  width: 600px;
  border-radius: 6px;
  box-shadow: 0 5px 15px rgb(0 0 0 / 50%);
  background-color: #ffffff;
  outline: none;

  &.large {
    width: 900px;
  }
`;

const ModalBackdrop = styled(Box)`
  position: fixed;
  width: 100%;
  height: 100vh;
  overflow: auto;
  z-index: 999999999;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ModalContainer = styled(Box)`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
`;

const ContentWrapper = styled(Box)`
  margin: auto;
`;

const globalOpenModalStyles = css`
  body {
    overflow: hidden;
  }
`;

const ModalBody = styled(Box)`
  background: #f3f6f7;
  padding: 30px 40px;
`;

const IconBox = styled(Box)`
  cursor: pointer;
`;

export const Modal = () => {
  const dispatch = useDispatch();

  const {
    isOpen,
    headerText,
    descriptionText,
    onConfirm,
    onCancel,
    type,
    params = {},
    closable = true,
    isActionsShown = true,
    size = 'medium',
  } = useSelector((state: RootState) => state.modal);

  const confirmHandler = () => {
    onConfirm && onConfirm(params);
    dispatch(closeModal());
  };

  const closeHandler = () => {
    onCancel && onCancel();
    dispatch(closeModal());
  };

  const renderBody = () => {
    switch (type) {
      case ModalTypes.CREATE_ACCESS_CODE:
        return <ModalCreateAccessCode />;
      case ModalTypes.EXTEND_TRIAL:
        return <ModalExtendTrial />;
      case ModalTypes.ADD_ADMIN:
        return <ModalAddAdmin />;
      case ModalTypes.CHANGE_PASSWORD:
        return <ModalChangePassword />;
      case ModalTypes.MANAGE_CUSTOM_PLAN:
        return <ModalManageCustomPlan />;
      default:
        return null;
    }
  };

  if (!isOpen) {
    return null;
  }

  return (
    <ModalBackdrop onClick={closeHandler}>
      <ModalContainer>
        <Global styles={globalOpenModalStyles} />
        <ContentWrapper>
          <Wrapper
            className={clsx(size)}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {(closable || !!headerText) && (
              <Box pt="24px" pb="18px" px={5} display="flex">
                <Typography mr="auto" lineHeight="20px">
                  {headerText}
                </Typography>
                {closable && (
                  <IconBox>
                    <FontAwesomeIcon icon={faXmark} onClick={closeHandler} />
                  </IconBox>
                )}
              </Box>
            )}
            {type ? (
              renderBody()
            ) : (
              <>
                <ModalBody>
                  <Typography color="#7c7d9d">{descriptionText}</Typography>
                </ModalBody>
                <Box py={2} px={5} display="flex" justifyContent="space-between">
                  {isActionsShown && (
                    <>
                      <ModalButton onClick={closeHandler}>Cancel</ModalButton>
                      <ModalButton onClick={confirmHandler}>Confirm</ModalButton>
                    </>
                  )}
                </Box>
              </>
            )}
          </Wrapper>
        </ContentWrapper>
      </ModalContainer>
    </ModalBackdrop>
  );
};

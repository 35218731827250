import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { enableFeatureForAdmins } from 'src/api';
import { showToast } from 'src/store';

export const useEnableFeatureForAdmins = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { mutate, ...rest } = useMutation(['enable-feature-for-admins'], enableFeatureForAdmins, {
    onSuccess: (_data, variables) => {
      queryClient.invalidateQueries(['get-feature-flags']);

      dispatch(
        showToast({
          type: 'success',
          message: `Feature flag was successfully ${variables.enabled ? 'enabled' : 'disabled'} for all Alfred admins`,
        }),
      );
    },
    onError: (err) => {
      dispatch(
        showToast({
          type: 'error',
          message: (err as Error)?.message ?? 'Something went wrong',
        }),
      );
    },
  });

  return { enableFeatureForAdmins: mutate, ...rest };
};

import React, { PropsWithChildren } from 'react';
import { Box, BoxProps, styled } from '@mui/material';

import { ReactComponent as CloseIcon } from 'src/assets/icons/close-icon.svg';
import { Typography } from 'src/components';

const SmallCardWrapper = styled(Box)`
  background: #f9fbfc;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
`;

const ValueContainer = styled(Box)`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #0a150f;
`;

interface IInfoLabelProps extends BoxProps {
  label: string;
  addCrossIcon?: boolean;
}

export const SmallCard = ({ label, children, addCrossIcon = false, ...props }: PropsWithChildren<IInfoLabelProps>) => {
  return (
    <SmallCardWrapper padding="8px" position="relative" {...props}>
      <Typography color="#0A150F" semibold lineHeight="20px" mr={addCrossIcon ? '12px' : '0'}>
        {label}
      </Typography>
      <ValueContainer mt="2px">{children}</ValueContainer>

      {addCrossIcon && (
        <Box position="absolute" top="4px" right="4px">
          <CloseIcon width="14px" height="14px" />
        </Box>
      )}
    </SmallCardWrapper>
  );
};

import { adminApi, appApi } from 'src/api/axios';
import {
  IAuthToken,
  IChangeUserEmailReq,
  IExportUsersCsv,
  IGetAllUsersInfoReq,
  IGetAllUsersInfoRes,
  IGetUserActionsReq,
  IGetUserActionsRes,
  IGetUserCloudBotInfoRes,
  IGetUserEmailInfoRes,
  IGetUserInfoRes,
  IGetUserLeadsInfoRes,
  IGetUserLinkedinInfoRes,
  IGetUserTeamInfoRes,
  IGetUserTwitterInfoRes,
  IImpersonateUser,
  ISearchUser,
  IUser,
} from 'src/types';

export const getUserAccount = (): Promise<IUser> => adminApi.get('/users/me');

export const impersonateUser = (data: IImpersonateUser): Promise<IAuthToken> =>
  appApi.post(`/users/impersonate`, { ...data, isAdminImpersonate: true, redirectLink: window.location.href });

export const stopImpersonateUser = () => appApi.post(`/users/stop-impersonating`);

export const getAllUsers = (params: IGetAllUsersInfoReq): Promise<IGetAllUsersInfoRes> =>
  adminApi.get(`/users`, { params });

export const getUserInfo = (userId: number): Promise<IGetUserInfoRes> => adminApi.get(`/users/${userId}`);

export const getUserLinkedinInfo = (userId: number): Promise<IGetUserLinkedinInfoRes> =>
  adminApi.get(`/users/${userId}/linked-in`);

export const getUserLeadsInfo = (userId: number): Promise<IGetUserLeadsInfoRes> =>
  adminApi.get(`/users/${userId}/leads `);

export const getUserTwitterInfo = (userId: number): Promise<IGetUserTwitterInfoRes> =>
  adminApi.get(`/users/${userId}/twitter`);

export const getUserEmailInfo = (userId: number): Promise<IGetUserEmailInfoRes> =>
  adminApi.get(`/users/${userId}/email`);

export const getUserTeamInfo = (userId: number): Promise<IGetUserTeamInfoRes> => adminApi.get(`/users/${userId}/team`);

export const getUserCloudBotInfo = (userId: number): Promise<IGetUserCloudBotInfoRes> =>
  adminApi.get(`/users/${userId}/cloud-bot`);

export const getUserActions = (userId: number, params: IGetUserActionsReq): Promise<IGetUserActionsRes> =>
  adminApi.get(`/users/${userId}/actions`, { params });

export const changeUserEmail = (userId: number, data: IChangeUserEmailReq): Promise<IGetUserInfoRes> =>
  adminApi.patch(`/users/${userId}/email`, data);

export const removeUser = (userId: number) => adminApi.delete(`/users/${userId}`);

export const exportUsersCsv = (params: IExportUsersCsv): Promise<string> =>
  adminApi.get('/users/export', { params, responseType: 'stream' });

export const getSearchUser = (params: { email: string }): Promise<ISearchUser[]> =>
  adminApi.get(`/users/search`, { params });

export const toggleUserBlock = (userId: number): Promise<void> => adminApi.patch(`/users/${userId}/toggle-block`);

export const removeUserLinkedInCredentials = (userId: number): Promise<void> =>
  adminApi.delete(`/users/${userId}/linked-in/credentials`);

export const verifyUserEmail = (userId: number): Promise<void> => adminApi.post(`/users/${userId}/verify-email`);

export const removeUserEmailIntegration = (userId: number): Promise<void> =>
  adminApi.delete(`/users/${userId}/email-integration`);

export const changeUserPassword = (userId: number, password: string): Promise<void> =>
  adminApi.patch(`/users/${userId}/password`, { password });

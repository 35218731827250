import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { returnUserToTeam } from 'src/api';
import { showToast } from 'src/store';

export const useReturnTeamMemberToTeam = (teamId: number) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const getTeamInfoKeys = ['get-team-members', teamId];

  const { mutate, ...rest } = useMutation(
    ['return-team-user-to-team', teamId],
    (memberId: number) => returnUserToTeam(teamId, memberId),
    {
      onSuccess: () => {
        dispatch(
          showToast({
            type: 'info',
            message: 'User was successfully returned to the team.',
          }),
        );
        queryClient.invalidateQueries(getTeamInfoKeys);
      },
      onError: (err) => {
        dispatch(
          showToast({
            type: 'error',
            message: (err as Error)?.message ?? 'Something went wrong',
          }),
        );
      },
    },
  );

  return { returnUserToTeam: mutate, ...rest };
};

import { adminApi } from 'src/api/axios';

export const pauseCloudBot = (userId: number) => adminApi.post(`/cloud-bot/user/${userId}/pause`);

export const unpauseCloudBot = (userId: number) => adminApi.post(`/cloud-bot/user/${userId}/unpause`);

export const restartCloudBot = (userId: number) => adminApi.post(`/cloud-bot/user/${userId}/restart`);

export const removeCloudBot = (userId: number) => adminApi.post(`/cloud-bot/user/${userId}/remove-bot`);

export const removeCloudBotProxy = (userId: number) => adminApi.post(`/cloud-bot/user/${userId}/remove-proxy`);

import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getSubscriptionPauseDurationsStats } from 'src/api/';
import { IDateRangeFilter, ISubscriptionPauseDurationStat } from 'src/types';

export const useGetSubscriptionPauseDurationStats = <TData extends ISubscriptionPauseDurationStat[]>(
  params: IDateRangeFilter,
  options?: UseQueryOptions<ISubscriptionPauseDurationStat[], AxiosError, TData>,
) => {
  const { data, ...rest } = useQuery<ISubscriptionPauseDurationStat[], AxiosError, TData>(
    ['get-subscription-pause-duration-stats', JSON.stringify(params)],
    () => getSubscriptionPauseDurationsStats(params),
    options,
  );

  return { durationStats: data, ...rest };
};

import { Link } from 'react-router-dom';
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';

export const TabsContainer = styled('div', {
  shouldForwardProp: (prop) => isPropValid(prop),
})`
  width: auto;
  justify-content: space-around;
  display: inline-flex;
  align-items: center;

  &:last-child {
    box-shadow: none;
  }
`;

export const TabLink = styled(Link, {
  shouldForwardProp: (prop) => isPropValid(prop),
})<{ isActive: boolean; disabled?: boolean }>`
  font-size: 14px;
  color: ${(props) => (props.isActive ? '#272a79' : '#8788b6')};
  line-height: 44px;
  margin-right: 20px;
  font-weight: 400;

  ${(props) => props.isActive && 'border-bottom: 2px solid #2361fc;'}

  ${(props) =>
    props.disabled &&
    `
      color: #d3d3d3;
      cursor: not-allowed !important;
    `}
`;

import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getUserLinkedinInfo } from 'src/api';
import { IGetUserLinkedinInfoRes } from 'src/types';

export const useGetUserLinkedinInfo = <TData extends IGetUserLinkedinInfoRes>(
  userId: number,
  options?: UseQueryOptions<IGetUserLinkedinInfoRes, AxiosError, TData>,
) => {
  const { data, ...rest } = useQuery<IGetUserLinkedinInfoRes, AxiosError, TData>(
    ['get-user-linkedin-info', userId],
    () => getUserLinkedinInfo(userId),
    options,
  );

  return { linkedinInfo: data, ...rest };
};

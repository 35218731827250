import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { postExtendTrial } from 'src/api';
import { showToast } from 'src/store';
import { IExtendTrialReq } from 'src/types';

export const useExtendTrial = (teamId: number, onSuccess?: () => void) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { mutate, ...rest } = useMutation(['extend-trial'], (data: IExtendTrialReq) => postExtendTrial(teamId, data), {
    onSuccess: async () => {
      onSuccess?.();
      await queryClient.invalidateQueries(['get-all-users'], { exact: false });
      await queryClient.invalidateQueries(['get-all-teams'], { exact: false, refetchInactive: true });
      await queryClient.invalidateQueries(['get-team-info', teamId], { exact: false, refetchInactive: true });
      await queryClient.invalidateQueries(['get-user-team-info']);
    },
    onError: (err) => {
      dispatch(
        showToast({
          type: 'error',
          message: (err as Error)?.message ?? 'Something went wrong',
        }),
      );
    },
  });

  return { extendTrial: mutate, ...rest };
};

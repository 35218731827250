import ReactSelect, { Props as ReactSelectProps, PropsValue } from 'react-select';

import { ISelectOption, TJustifyContent } from 'src/types';
import { defaultComponents, defaultStyles } from './default-styles';

interface TSelectProps<T, V> extends Omit<ReactSelectProps<ISelectOption<unknown>>, 'options' | 'onChange' | 'value'> {
  label?: string;
  minWidth?: string;
  maxWidth?: string;
  valueContainerPosition?: TJustifyContent;
  value?: V[];
  options: T[];
  onChange: (data: T[]) => void;
}

export const MultiSelect = <T extends ISelectOption<unknown>, V extends ISelectOption<unknown>['value']>({
  styles,
  minWidth,
  maxWidth,
  options,
  onChange,
  label,
  value,
  components,
  valueContainerPosition,
  ...otherProps
}: TSelectProps<T, V>) => {
  return (
    <ReactSelect
      isMulti
      isSearchable={false}
      options={options}
      value={value as PropsValue<ISelectOption<unknown>>}
      onChange={(data) => onChange(data as T[])}
      styles={{
        ...defaultStyles({ minWidth, maxWidth, valueContainerPosition }),
        ...styles,
      }}
      components={{
        ...defaultComponents({ label }),
        ...components,
      }}
      {...otherProps}
    />
  );
};

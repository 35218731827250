import { adminApi } from 'src/api/axios';
import { ICode, ICreateCodeReq, IGetCodesReq, IGetCodesRes, IGetCodesTeamRes } from 'src/types';

export const getCodes = (params: IGetCodesReq): Promise<IGetCodesRes> => adminApi.get(`/codes`, { params });

export const getCodesTeam = (codeId: number): Promise<IGetCodesTeamRes[]> => adminApi.get(`/codes/${codeId}/teams`);

export const createCode = (data: ICreateCodeReq): Promise<ICode> => adminApi.post(`/codes`, data);

export const toggleCode = (codeId: number): Promise<ICode> => adminApi.patch(`/codes/${codeId}/toggle`);

export const deleteCode = (codeId: number): Promise<void> => adminApi.delete(`/codes/${codeId}`);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IGetAllUsersInfoReq } from 'src/types';

export const DEFAULT_USERS_REQUEST: IGetAllUsersInfoReq = {
  page: 1,
  perPage: 10,
  search: '',
  sortField: 'id',
  sortOrder: 'DESC',
};

const initialState = {
  usersRequest: DEFAULT_USERS_REQUEST,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUsersRequest: (state, action: PayloadAction<Partial<IGetAllUsersInfoReq>>) => {
      state.usersRequest = { ...state.usersRequest, ...action.payload };
    },
  },
});

export const { updateUsersRequest } = userSlice.actions;

export default userSlice.reducer;

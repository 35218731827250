import { useMutation, useQueryClient } from 'react-query';

import { removeUserLinkedInCredentials } from 'src/api';

export const useRemoveUserLinkedInCredentials = (userId: number) => {
  const queryClient = useQueryClient();

  const { mutate, ...rest } = useMutation(
    ['remove-li-credentials', userId],
    () => removeUserLinkedInCredentials(userId),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['get-user-linkedin-info', userId]);
      },
    },
  );

  return { removeCredentials: mutate, ...rest };
};

import { PropsWithChildren, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import {
  Breadcrumbs,
  Button,
  HeaderContainer,
  HeaderTitle,
  ImpersonateButton,
  TabLink,
  TabsContainer,
  Typography,
} from 'src/components';
import { convertPermissionToObject } from 'src/helpers';
import { useGetUserAccount, useGetUserInfo, useGetUserTeamInfo, useRemoveUser } from 'src/reactQueries';
import { openModal } from 'src/store';

export const UserDetailsLayout = ({ children }: PropsWithChildren) => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const USER_ID = Number(userId);
  const dispatch = useDispatch();

  const { userMe } = useGetUserAccount();
  const { featureFlags } = convertPermissionToObject(userMe?.adminPermissions ?? []);

  const { removeUser } = useRemoveUser(USER_ID, () => navigate('/support/users'));
  const { userInfo } = useGetUserInfo(USER_ID);
  const { teamInfo } = useGetUserTeamInfo(USER_ID);

  const tabs = useMemo(() => {
    return [
      { label: 'User Profile', to: 'details' },
      { label: 'Leads Info', to: 'leads-info' },
      { label: 'LinkedIn', to: 'linkedin' },
      { label: 'Twitter', to: 'twitter' },
      { label: 'Email Integration', to: 'email-integration' },
      { label: 'Team Information', to: 'team-info' },
      { label: 'Cloud', to: 'cloud' },
      { label: 'Feature Flags', to: 'feature-flags', isShown: featureFlags },
    ];
  }, [featureFlags]);

  const onRemoveUser = () =>
    dispatch(
      openModal({
        headerText: `Remove user`,
        descriptionText: `Are you sure you want to remove ${userInfo?.name}?`,
        onConfirm: removeUser,
      }),
    );

  return (
    <>
      <HeaderContainer>
        <Breadcrumbs>
          <Link to="/support">Support</Link>
          <Link to="/support/users">All Users</Link>
          <Typography>User Details</Typography>
        </Breadcrumbs>
        <Box display="flex" alignItems="center" my="8px">
          <HeaderTitle>{userInfo?.name}</HeaderTitle>
          {userInfo?.isDeactivated && (
            <Typography ml="5px" pb="10px">
              (Deactivated)
            </Typography>
          )}
          <Box display="flex" gap="16px" ml="auto">
            <Button variant="red" gap="10px" onClick={onRemoveUser}>
              <FontAwesomeIcon icon={faTrashAlt} /> Remove User
            </Button>
            <ImpersonateButton userId={USER_ID} teamId={teamInfo?.teamId}>
              Impersonate
            </ImpersonateButton>
          </Box>
        </Box>
        <TabsContainer>
          {tabs.map(({ label, to, isShown = true }) => {
            if (!isShown) return null;

            const path = `/support/users/${userId}/${to}`;

            return (
              <TabLink key={to} isActive={path === location.pathname} to={path}>
                {label}
              </TabLink>
            );
          })}
        </TabsContainer>
      </HeaderContainer>
      <Box m="16px" display="grid" gap="16px">
        {children}
      </Box>
    </>
  );
};

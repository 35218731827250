import { AdminPermissionEnum } from 'src/enums';

type TPermissionObject = Record<AdminPermissionEnum, boolean>;

export const convertObjectToPermission = (permissionObject: TPermissionObject) => {
  const permissionKeys = Object.keys(permissionObject) as AdminPermissionEnum[];

  return permissionKeys.filter((permission) => permissionObject[permission]);
};

export const convertPermissionToObject = (permissions: AdminPermissionEnum[]) => {
  const enumPermission = Object.keys(AdminPermissionEnum) as AdminPermissionEnum[];
  const permissionsEntries = enumPermission.map((permission) => [permission, permissions.includes(permission)]);

  return Object.fromEntries(permissionsEntries) as TPermissionObject;
};

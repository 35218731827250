import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getSummary } from 'src/api';
import { IAnalyticSummaryRes, IGetSummaryParams } from 'src/types';

export const useGetAnalyticSummary = <TData extends IAnalyticSummaryRes>(
  params: IGetSummaryParams,
  options?: UseQueryOptions<IAnalyticSummaryRes, AxiosError, TData>,
) => {
  const { data, ...rest } = useQuery<IAnalyticSummaryRes, AxiosError, TData>(
    ['get-analytic-summary', JSON.stringify(params)],
    () => getSummary(params),
    options,
  );

  return { summary: data, ...rest };
};

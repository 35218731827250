import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, BoxProps, TooltipProps } from '@mui/material';

import { Tooltip } from 'src/components';
import { propsBlackList } from 'src/helpers';

interface IButtonStyles {
  textColor?: string;
  hoverColor?: string;
  backgroundColor: string;
  hoverBackgroundColor: string;
  processing: 1 | 0;
  disabled: boolean;
  size: number;
}

const BLACK_LIST_PROPS = ['hoverBackgroundColor', 'textColor', 'hoverColor'];

const StyledSmallButton = styled(Box, propsBlackList(BLACK_LIST_PROPS))<IButtonStyles>`
  display: flex;
  align-items: center;
  border-radius: 4px;
  white-space: nowrap;
  color: ${({ textColor }) => textColor || '#ffffff'};
  cursor: pointer;
  padding: ${({ size }) => `${size / 3}px ${size}px`};
  min-width: max-content;
  background-color: ${(props) => props.backgroundColor};
  transition: 0.2s;
  opacity: ${({ processing, disabled }) => (processing || disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  &:hover {
    background-color: ${(props) => props.hoverBackgroundColor};
    color: ${(props) => props.hoverColor || '#ffffff'};
  }
`;

const Spinner = styled(FontAwesomeIcon)`
  animation: fa-spin 2s linear infinite;
`;

const buttonColors = {
  blue: {
    backgroundColor: '#2361FC',
    hoverBackgroundColor: '#0349f8',
  },
  orange: {
    backgroundColor: '#F47632',
    hoverBackgroundColor: '#f25f10',
  },
  red: {
    backgroundColor: '#c66',
    hoverBackgroundColor: '#c35f5f',
  },
  white: {
    textColor: '#000000',
    hoverColor: '#ffffff',
    backgroundColor: '#ffffff',
    hoverBackgroundColor: '#808080',
  },
  whiteBlue: {
    border: '1px solid #2361fc',
    textColor: '#2361FC',
    hoverColor: '#0349f8',
    backgroundColor: '#ffffff',
    hoverBackgroundColor: '#f6f6f6',
  },
  transparent: {
    textColor: '#262a79',
    hoverColor: '#262a79',
    backgroundColor: 'transparent',
    hoverBackgroundColor: '#f5f5f5',
  },
};

export interface ISmallButtonProps extends BoxProps {
  tooltip?: ReactNode;
  tooltipProps?: Omit<TooltipProps, 'children' | 'title'>;
  variant?: keyof typeof buttonColors;
  processing?: boolean;
  disabled?: boolean;
  size?: number;
  wrapperProps?: BoxProps;
}

export const Button = ({
  tooltip,
  tooltipProps,
  children,
  variant = 'blue',
  processing = false,
  disabled = false,
  size = 24,
  wrapperProps = {},
  ...restButtonProps
}: ISmallButtonProps) => {
  return (
    <Tooltip title={tooltip} {...tooltipProps}>
      <Box {...wrapperProps}>
        <StyledSmallButton
          role="button"
          fontSize="14px"
          size={size}
          disabled={disabled}
          processing={processing ? 1 : 0}
          {...buttonColors[variant]}
          {...restButtonProps}
        >
          {processing ? <Spinner size="lg" icon={faSpinner} /> : children}
        </StyledSmallButton>
      </Box>
    </Tooltip>
  );
};

import { Plan } from 'src/enums';

export const PLAN_SEATS: { [key in Plan]?: { seats: number; minSeats: number } } = {
  [Plan.INDIVIDUAL]: {
    seats: 1,
    minSeats: 1,
  },
  [Plan.STARTER]: {
    seats: 1,
    minSeats: 1,
  },
  [Plan.PERSONAL]: {
    seats: 1,
    minSeats: 1,
  },
  [Plan.BUSINESS]: {
    seats: 250,
    minSeats: 1,
  },
  [Plan.ENTERPRISE]: {
    seats: 250,
    minSeats: 5,
  },
  [Plan.AGENCY]: {
    seats: 250,
    minSeats: 10,
  },
  [Plan.BASIC]: {
    seats: 1,
    minSeats: 1,
  },
  [Plan.PRO]: {
    seats: 1,
    minSeats: 1,
  },
  [Plan.TEAMS]: {
    seats: 250,
    minSeats: 3,
  },
};

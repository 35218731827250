import { BillingTypeEnum, Plan, TeamLevel } from 'src/enums';

export const planVariants = [
  'all',
  Plan.TRIAL,
  Plan.BUSINESS,
  Plan.ENTERPRISE,
  Plan.PERSONAL,
  Plan.STARTER,
  Plan.INDIVIDUAL,
  Plan.AGENCY,
  Plan.BASIC,
  Plan.PRO,
  Plan.TEAMS,
] as const;
export const planOptions = planVariants.map((plan) => ({
  label: String(plan),
  value: plan,
}));

export const bullingVariants = [
  'all',
  BillingTypeEnum.MONTHLY,
  BillingTypeEnum.QUARTERLY,
  BillingTypeEnum.ANNUAL,
] as const;
export const billingOptions = bullingVariants.map((plan) => ({
  label: String(plan),
  value: plan,
}));

export const TEAM_LEVEL_ICON: Record<TeamLevel, string> = {
  [TeamLevel.Bronze]: '🥉',
  [TeamLevel.Silver]: '🥈',
  [TeamLevel.Gold]: '🥇',
};

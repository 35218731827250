import { CategoryScale, Chart, Legend, LinearScale, LineElement, PointElement, Title, Tooltip } from 'chart.js';
import dayjs from 'dayjs';
import { useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Box } from '@mui/material';

import {
  Block,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TablePagination,
  TableRow,
  Typography,
} from 'src/components';
import { DateGroupType, LiStatsActionsEnum } from 'src/enums';
import { useSortLinkedinActionsStats } from 'src/hooks/useSortLinkedinActionsStats';
import { useGetNumberOfLIActions } from 'src/reactQueries';
import { IGetLinkedInActionsStats } from 'src/types';
import { LinkedInActionsLayout } from './LinkedInActionsLayout';

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const Actions = () => {
  const [params, setParams] = useState<IGetLinkedInActionsStats>({
    fromDate: dayjs().subtract(1, 'week').format('YYYY-MM-DD'),
    toDate: dayjs().format('YYYY-MM-DD'),
    action: LiStatsActionsEnum.redirectLoop,
    groupType: DateGroupType.day,
    page: 1,
    perPage: 10,
    cloudPools: [],
    httpClientOnly: false,
  });

  const { data, isLoading } = useGetNumberOfLIActions(params);
  const { sortingProps } = useSortLinkedinActionsStats(data);

  return (
    <LinkedInActionsLayout
      title="Number of Actions"
      onFiltersApply={(params) => {
        setParams((prev) => ({ ...prev, ...params, page: 1 }));
      }}
    >
      <Box display="flex" gap="8px">
        <Block overflow="hidden" width="50%">
          <Table placeholder={!data?.data?.length && 'No data'} processing={isLoading} disabled={isLoading}>
            <TableHead secondary>
              <TableRow>
                <TableHeadCell secondary name="date" sorting={sortingProps}>
                  Date
                </TableHeadCell>
                {params.action === LiStatsActionsEnum.autoLinkedInLoginEnded && (
                  <>
                    <TableHeadCell justifyContent="flex-end" secondary name="successActions" sorting={sortingProps}>
                      Success Actions
                    </TableHeadCell>
                    <TableHeadCell
                      justifyContent="flex-end"
                      secondary
                      name="verificationActions"
                      sorting={sortingProps}
                    >
                      Verification Actions
                    </TableHeadCell>
                    <TableHeadCell justifyContent="flex-end" secondary name="failedActions" sorting={sortingProps}>
                      Failed Actions
                    </TableHeadCell>
                  </>
                )}
                <TableHeadCell width="100px" justifyContent="flex-end" secondary name="actions" sorting={sortingProps}>
                  Actions
                </TableHeadCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {data?.data?.map(({ date, actions, successActions = 0, failedActions = 0, verificationActions = 0 }) => (
                <TableRow key={date}>
                  <TableCell>
                    <Typography>
                      {dayjs(date).format(
                        `${params.groupType !== DateGroupType.month ? 'DD ' : ''}MMMM YYYY${
                          params.groupType === DateGroupType.hour ? ' HH:00' : ''
                        }`,
                      )}
                    </Typography>
                  </TableCell>
                  {params.action === LiStatsActionsEnum.autoLinkedInLoginEnded && (
                    <>
                      <TableCell justifyContent="flex-end">
                        <Typography>{successActions}</Typography>
                      </TableCell>
                      <TableCell justifyContent="flex-end">
                        <Typography>{verificationActions}</Typography>
                      </TableCell>
                      <TableCell justifyContent="flex-end">
                        <Typography>{failedActions}</Typography>
                      </TableCell>
                    </>
                  )}
                  <TableCell justifyContent="flex-end">
                    <Typography>{actions}</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Box mx="10px">
            <TablePagination
              page={params.page}
              perPage={params.perPage}
              total={data?.total}
              onPaginationChange={({ page, perPage }) => setParams((prevState) => ({ ...prevState, page, perPage }))}
            />
          </Box>
        </Block>
        <Block width="50%" p="8px">
          <Line
            data={{
              labels: data?.data.map((item) =>
                dayjs(item.date).format(
                  `${params.groupType !== DateGroupType.month ? 'DD.' : ''}MM.YYYY${
                    params.groupType === DateGroupType.hour ? ' HH:MM' : ''
                  }`,
                ),
              ),
              datasets: [
                { label: 'Number of Actions', data: data?.data.map((item) => item.actions), borderColor: '#2361fc' },
                ...(params.action === LiStatsActionsEnum.autoLinkedInLoginEnded
                  ? [
                      {
                        label: 'Number of Success Login',
                        data: data?.data.map((item) => item.successActions || 0),
                        borderColor: '#53a25d',
                      },
                      {
                        label: 'Number of Verification Actions',
                        data: data?.data.map((item) => item.verificationActions || 0),
                        borderColor: '#ffc727',
                      },
                      {
                        label: 'Number of Failed Actions',
                        data: data?.data.map((item) => item.failedActions || 0),
                        borderColor: '#f62713',
                      },
                    ]
                  : []),
              ],
            }}
            options={{ responsive: true }}
          />
        </Block>
      </Box>
    </LinkedInActionsLayout>
  );
};

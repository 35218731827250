import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getFeatureFlags } from 'src/api/';
import { IFeatureFlag } from 'src/types';

export const useGetFeatureFlags = <TData extends IFeatureFlag[]>(
  options?: UseQueryOptions<IFeatureFlag[], AxiosError, TData>,
) => {
  return useQuery<IFeatureFlag[], AxiosError, TData>(['get-feature-flags'], getFeatureFlags, options);
};

import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getUserTwitterInfo } from 'src/api';
import { IGetUserTwitterInfoRes } from 'src/types';

export const useGetUserTwitterInfo = <TData extends IGetUserTwitterInfoRes>(
  userId: number,
  options?: UseQueryOptions<IGetUserTwitterInfoRes, AxiosError, TData>,
) => {
  const { data, ...rest } = useQuery<IGetUserTwitterInfoRes, AxiosError, TData>(
    ['get-user-twitter-info', userId],
    () => getUserTwitterInfo(userId),
    options,
  );

  return { twitterInfo: data, ...rest };
};

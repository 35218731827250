import clsx from 'clsx';
import { Link, To } from 'react-router-dom';
import styled from '@emotion/styled';

import moreIcon from 'src/assets/icons/sidebar/more.svg';

export const IconHolder = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const IconContainer = styled.div`
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: #003a66;

  &.expandable {
    &:hover {
      &:after {
        content: '';
        position: absolute;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-left: 6px solid #52728a;
        right: 12px;
        opacity: 1;
      }
      .expand {
        opacity: 0;
      }
    }
  }

  &:hover {
    background-color: #52728a;
  }

  &.active {
    background-color: #f47632;
  }

  &.disabled {
    background-color: #3f4951;

    img {
      opacity: 0.6;
    }
  }

  &.active.expandable {
    &:hover {
      &:after {
        content: '';
        position: absolute;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-left: 6px solid #f47632;
        right: 12px;
        opacity: 1;
      }
    }
  }
`;

const ExpandIcon = styled.img`
  position: absolute;
  right: 10px;
  opacity: 0.3;
  transition: opacity ease 0.3s;

  &.active {
    opacity: 0;
  }
`;

const Icon = styled.img`
  transition: background-color 0.3s ease;
`;

interface ISideBarIconProps extends React.HTMLAttributes<HTMLDivElement> {
  expandable?: boolean;
  active?: boolean;
  disabled?: boolean;
  icon?: string;
  linkTo?: false | To;
}

export const SideBarIcon = ({ icon, expandable, linkTo, active, disabled, ...props }: ISideBarIconProps) => {
  const linkResolver = (element: JSX.Element) => (linkTo ? <Link to={linkTo}>{element}</Link> : element);

  return linkResolver(
    <IconContainer
      {...props}
      className={clsx({
        active,
        expandable,
        disabled,
      })}
    >
      <Icon src={icon} />
      {expandable && (
        <ExpandIcon
          src={moreIcon}
          className={clsx('expand', {
            active,
          })}
        />
      )}
    </IconContainer>,
  );
};

import { CancelSubscriptionReason } from 'src/enums';

export const getContrastYIQ = (hex = '#eaebef') => {
  hex = hex.replace('#', '');

  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);

  /* Conversion from RGB to YIQ. In YIQ the Y component represents the luma information, I and Q represent the chrominance information. */
  const y = 0.299 * r + 0.587 * g + 0.114 * b;

  return y >= 128 ? '#000000' : '#ffffff';
};

export const getColorByReason = (reason: CancelSubscriptionReason) => {
  const colors = {
    [CancelSubscriptionReason.COST]: '#38A6A5',
    [CancelSubscriptionReason.DIFFICULTY_OF_USE]: '#1D6996',
    [CancelSubscriptionReason.MISSING_FUNCTIONALITY]: '#73AF48',
    [CancelSubscriptionReason.SOMETHING_ELSE]: '#E17C05',
    [CancelSubscriptionReason.TECHNICAL_ISSUES]: '#556b2f',
    [CancelSubscriptionReason.USING_OTHER_PRODUCT]: '#EDAD08',
  };

  return colors[reason] ?? '#38A6A5';
};

export const getColorByLabel = (reason: string) => {
  const colors: Record<string, string> = {
    '15% discount accepted': '#de0000',
    '25% discount accepted': '#E17C05',
    '50% discount accepted': '#e5b300',
    'close cancel view': '#0F8554',
    'subscription resumed': '#5F4690',
    'pause subscription': '#1D6996',
  };

  return colors[reason] ?? '#de0000';
};

import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { store } from 'src/store';
import { ErrorBoundary, Modal, Toast } from './components';
import { Routes } from './routes';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
    },
  },
});

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  enabled: process.env.REACT_APP_NODE_ENV === 'staging',
  environment: process.env.REACT_APP_NODE_ENV,
});

function App() {
  return (
    <Sentry.ErrorBoundary fallback={<ErrorBoundary />}>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <Modal />
            <Toast />
            <Routes />
          </BrowserRouter>
        </QueryClientProvider>
      </Provider>
    </Sentry.ErrorBoundary>
  );
}

export default App;

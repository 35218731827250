import { useState } from 'react';
import { Link } from 'react-router-dom';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import {
  Breadcrumbs,
  Button,
  HeaderContainer,
  HeaderTitle,
  Table,
  TableBody,
  TableHead,
  TableHeadCell,
  TablePagination,
  TableRow,
  Typography,
} from 'src/components';
import { AccessCodeTableRow } from 'src/containers/Support/AccessCode/AccessCodeTableRow';
import { ModalTypes } from 'src/enums';
import { useAppDispatch } from 'src/hooks';
import { useCreateCode, useGetAllCodes } from 'src/reactQueries';
import { openModal } from 'src/store';
import { ITablePagination, TCodesSortFields, TSortOrder } from 'src/types';

export const AccessCode = () => {
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState<TSortOrder>('DESC');
  const [sortField, setSortField] = useState<TCodesSortFields>('date');

  const params = {
    page,
    perPage,
    sortField,
    sortOrder,
  };
  const { allCodes, isLoading } = useGetAllCodes(params);
  const { codes = [], total } = allCodes ?? {};
  const { createCode } = useCreateCode(params);

  const sortingProps = {
    sortField,
    sortOrder,
    onSortFieldChange: (field: TCodesSortFields, order: TSortOrder) => {
      setSortField(field);
      setSortOrder(order);
    },
  };

  const paginationChangeHandler = (pagination: ITablePagination) => {
    setPage(pagination.page);
    setPerPage(pagination.perPage);
  };

  const onCreateNewCode = () =>
    dispatch(
      openModal({
        type: ModalTypes.CREATE_ACCESS_CODE,
        headerText: 'Create a new access code',
        onConfirm: (data) => createCode(data),
      }),
    );

  return (
    <>
      <HeaderContainer pb="15px">
        <Breadcrumbs>
          <Link to="/support">Support</Link>
          <Typography>Access Codes</Typography>
        </Breadcrumbs>
        <Box display="flex" justifyContent="space-between">
          <HeaderTitle mt="8px">Access Codes</HeaderTitle>
          <Button onClick={onCreateNewCode} gap="10px">
            <FontAwesomeIcon icon={faPlus} /> Create Code
          </Button>
        </Box>
      </HeaderContainer>
      <Box m="16px" display="grid" gap="16px">
        <Table placeholder={!total && 'No access codes found'} processing={isLoading} disabled={isLoading}>
          <TableHead>
            <TableRow>
              <TableHeadCell secondary name="code" sorting={sortingProps}>
                Code Name
              </TableHeadCell>
              <TableHeadCell secondary name="accelerator" sorting={sortingProps}>
                Accelerator Name
              </TableHeadCell>
              <TableHeadCell secondary width="120px" name="status" sorting={sortingProps}>
                Status
              </TableHeadCell>
              <TableHeadCell secondary width="150px" name="redemption-counter" sorting={sortingProps}>
                Redemption Counter
              </TableHeadCell>
              <TableHeadCell secondary width="170px">
                Actions
              </TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {codes.map((code, index) => (
              <AccessCodeTableRow key={index} accessCode={code} allCodesQueryKeys={params} />
            ))}
          </TableBody>
        </Table>
        <TablePagination page={page} perPage={perPage} total={total} onPaginationChange={paginationChangeHandler} />
      </Box>
    </>
  );
};

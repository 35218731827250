import clsx from 'clsx';
import { ButtonHTMLAttributes } from 'react';
import styled from '@emotion/styled';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TooltipProps } from '@mui/material';

import { Tooltip } from '../index';

const StyledButton = styled.button`
  position: relative;
  display: inline-block;
  min-width: 180px;
  height: 33px;
  padding: 4px 12px;
  background-color: #2361fc;
  color: #ffffff;
  cursor: pointer;
  font-family: 'ProximaSoft', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  white-space: nowrap;
  border: none;
  border-radius: 4px;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`;

const ContentWrapper = styled.div`
  white-space: pre;
`;

const SpinnerWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const Spinner = styled(FontAwesomeIcon)`
  animation: fa-spin 2s linear infinite;
`;

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  processing?: boolean;
  tooltip?: Omit<TooltipProps, 'children'>;
}

export const HeaderButton = ({
  children,
  processing,
  tooltip = {
    title: '',
  },
  className,
  ...restProps
}: IButtonProps) => {
  return (
    <Tooltip arrow placement="top" {...tooltip}>
      {/* need this wrapper as MUI fires warning on using tooltip with disabled element inside */}
      <span>
        <StyledButton className={clsx(className)} {...restProps}>
          <ContentWrapper style={{ opacity: processing ? 0 : 1 }}>{children}</ContentWrapper>
          <SpinnerWrapper style={{ opacity: processing ? 1 : 0 }}>
            <Spinner size="lg" icon={faSpinner} />
          </SpinnerWrapper>
        </StyledButton>
      </span>
    </Tooltip>
  );
};

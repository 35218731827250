import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { deleteCode } from 'src/api';
import { showToast } from 'src/store';
import { IGetCodesReq, IGetCodesRes } from 'src/types';

export const useDeleteCode = (codeId: number, allCodesQueryKeys: IGetCodesReq) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const getAllCodesKeys = ['get-all-codes', JSON.stringify(allCodesQueryKeys)];

  const { mutate, ...rest } = useMutation(['delete-code'], () => deleteCode(codeId), {
    onMutate: async () => {
      await queryClient.cancelQueries(getAllCodesKeys);

      const prevData = queryClient.getQueryData(getAllCodesKeys);

      queryClient.setQueriesData<IGetCodesRes | undefined>(getAllCodesKeys, (data) => {
        if (data) {
          return {
            ...data,
            codes: data.codes.filter(({ id }) => id !== codeId),
          };
        }

        return data;
      });

      return { prevData };
    },
    onSuccess() {
      queryClient.invalidateQueries(getAllCodesKeys);
    },
    onError: (err, _vars, context) => {
      queryClient.setQueryData(getAllCodesKeys, context?.prevData);

      dispatch(
        showToast({
          type: 'error',
          message: (err as Error)?.message ?? 'Something went wrong',
        }),
      );
    },
  });

  return { deleteCode: mutate, ...rest };
};

import { UseFormWatch } from 'react-hook-form';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import logoIconWhite from 'src/assets/logo/logo_white2.svg';
import { Typography } from 'src/components';
import { IBrandingForm } from './Branding';

const Tab = styled(Box)`
  display: flex;
  gap: 5px;
  align-items: center;
  padding-top: 2px;
  padding-bottom: 2px;
`;

const TabIcon = styled(Box)`
  width: 8px;
  height: 8px;
  background-color: rgba(0, 0, 0, 0.2);
`;

const Screen = styled(Box)`
  height: 230px;
  display: grid;
  grid-template-columns: 32px 1fr;
  border-radius: 5px;
  overflow: hidden;
`;

const Header = styled(Box)`
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Button = styled(Box)`
  height: 16px;
  width: 52px;
  border-radius: 2px;
`;

export const AlfPreview = ({ watch }: { watch: UseFormWatch<IBrandingForm> }) => {
  const favicon = watch('favicon.url');
  const logoIcon = watch('logoIcon.url');

  return (
    <Box width="385px" height="270px">
      <Box display="flex" gap="5px" px="10px">
        <Tab>
          <TabIcon />
          <Typography fontSize="10px">Website 1</Typography>
        </Tab>
        <Tab border="1px solid #E8E8E8" maxWidth="20%" borderRadius="4px 4px 0px 0px" px="5px">
          {!favicon ? <TabIcon /> : <img src={favicon} width="8px" height="8px" />}
          <Typography fontSize="10px" crop>
            {watch('companyName') || 'MeetAlfred'}
          </Typography>
        </Tab>
        <Tab>
          <TabIcon />
          <Typography fontSize="10px">Website 3</Typography>
        </Tab>
      </Box>
      <Screen bgcolor="light.light">
        <Box height="100%" bgcolor={watch('menuFontColor')} display="flex" justifyContent="center" pt="5px">
          <img src={logoIcon ? logoIcon : logoIconWhite} width="20px" height="20px" />
        </Box>
        <Box width="100%">
          <Header bgcolor={watch('menuFontColor')}>
            <Button mr="15px" ml="auto" bgcolor={watch('accentColor')} />
          </Header>
          <Header bgcolor="#fff" px="15px" py="5px">
            <Typography mt="-5px" ml="-5px" color={watch('titleColor')} semibold>
              Title
            </Typography>
            <Button bgcolor={watch('secondaryColor')} />
          </Header>
        </Box>
      </Screen>
    </Box>
  );
};

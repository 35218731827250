import { useParams } from 'react-router-dom';

import { Block, InfoLabel, InfoLabelWrapper, Link } from 'src/components';
import { UserDetailsLayout } from 'src/containers';
import { useGetUserTwitterInfo } from 'src/reactQueries';
import { ActionsTable } from '../_components';

export const Twitter = () => {
  const { userId } = useParams();
  const USER_ID = Number(userId);

  const { twitterInfo } = useGetUserTwitterInfo(USER_ID);
  const { activeCampaigns, isActive, twitterHandle } = twitterInfo ?? {};

  return (
    <UserDetailsLayout>
      <Block p="24px">
        <InfoLabelWrapper>
          <InfoLabel label="Connected">{isActive ? 'Yes' : 'No'}</InfoLabel>
          {twitterHandle && (
            <InfoLabel label="Twitter Handle">
              <Link external openNewTab to={`https://twitter.com/${twitterHandle}`}>
                {twitterHandle}
              </Link>
            </InfoLabel>
          )}
          <InfoLabel disablePlaceholder label="Twitter Active Campaigns">
            {activeCampaigns || 0}
          </InfoLabel>
          <InfoLabel label="Current Status">{isActive ? 'Active' : 'Disabled'}</InfoLabel>
        </InfoLabelWrapper>
      </Block>
      <ActionsTable title="Last 10 Twitter Actions" type="twitter" userId={USER_ID} />
    </UserDetailsLayout>
  );
};

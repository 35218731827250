import { useState } from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import {
  Breadcrumbs,
  Button,
  HeaderContainer,
  HeaderTitle,
  Table,
  TableBody,
  TableHead,
  TableHeadCell,
  TablePagination,
  TableRow,
  Typography,
} from 'src/components';
import { ManageAdminsTableRow } from 'src/containers/ManageAdmins/ManageAdmins/ManageAdminsTableRow';
import { ModalTypes } from 'src/enums';
import { useAppDispatch } from 'src/hooks';
import { useGetAllAdmins } from 'src/reactQueries';
import { openModal } from 'src/store';
import { IGetAllAdminsSortFields, ITablePagination, TSortOrder } from 'src/types';

export const ManageAdmins = () => {
  const dispatch = useAppDispatch();

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState<TSortOrder>('ASC');
  const [sortField, setSortField] = useState<IGetAllAdminsSortFields>('name');

  const { allAdmins, isLoading } = useGetAllAdmins({ sortField, sortOrder, page, perPage });
  const { admins = [], total } = allAdmins ?? {};

  const sortingProps = {
    sortField,
    sortOrder,
    onSortFieldChange: (field: IGetAllAdminsSortFields, order: TSortOrder) => {
      setSortField(field);
      setSortOrder(order);
    },
  };

  const paginationChangeHandler = (pagination: ITablePagination) => {
    setPage(pagination.page);
    setPerPage(pagination.perPage);
  };

  const onCreateNewAdmin = () =>
    dispatch(
      openModal({
        type: ModalTypes.ADD_ADMIN,
        headerText: 'Add Admin',
      }),
    );

  return (
    <>
      <HeaderContainer pb="15px">
        <Breadcrumbs>
          <Typography>Manage Admins</Typography>
        </Breadcrumbs>
        <Box display="flex" justifyContent="space-between">
          <HeaderTitle mt="8px">Manage Admins</HeaderTitle>
          <Button onClick={onCreateNewAdmin} gap="10px">
            <FontAwesomeIcon icon={faPlus} /> Add Admin
          </Button>
        </Box>
      </HeaderContainer>
      <Box m="16px" display="grid" gap="16px">
        <Table placeholder={!total && 'No admins yet'} processing={isLoading} disabled={isLoading}>
          <TableHead>
            <TableRow>
              <TableHeadCell secondary name="name" sorting={sortingProps}>
                Name
              </TableHeadCell>
              <TableHeadCell secondary name="email" sorting={sortingProps}>
                Email
              </TableHeadCell>
              <TableHeadCell secondary name="id" sorting={sortingProps}>
                ID
              </TableHeadCell>
              <TableHeadCell secondary width="125px" name="createdAt" sorting={sortingProps}>
                Date Created
              </TableHeadCell>
              <TableHeadCell secondary width="130px" name="" sorting={sortingProps}>
                Actions
              </TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {admins.map((admin, index) => (
              <ManageAdminsTableRow key={index} admin={admin} />
            ))}
          </TableBody>
        </Table>
        <TablePagination page={page} perPage={perPage} total={total} onPaginationChange={paginationChangeHandler} />
      </Box>
    </>
  );
};

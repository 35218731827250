export enum BillingTypeEnum {
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
  ANNUAL = 'annual',
}

export enum Plan {
  TRIAL = 'trial',
  STARTER = 'starter',
  PERSONAL = 'personal',
  BUSINESS = 'business',
  ENTERPRISE = 'enterprise',
  INDIVIDUAL = 'individual',
  AGENCY = 'agency',
  BASIC = 'basic',
  PRO = 'pro',
  TEAMS = 'teams',
}

export enum TeamLevel {
  Bronze = 'Bronze',
  Silver = 'Silver',
  Gold = 'Gold',
}

import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { enabledFeatureForAllUsers } from 'src/api';
import { showToast } from 'src/store';

export const useEnableFeatureForAllUsers = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { mutate, ...rest } = useMutation(['enable-feature-for-users'], enabledFeatureForAllUsers, {
    onSuccess: (_data, variables) => {
      queryClient.invalidateQueries(['get-feature-flags']);

      dispatch(
        showToast({
          type: 'success',
          message: `Feature flag was successfully ${variables.enabled ? 'enabled' : 'disabled'} for all users`,
        }),
      );
    },
    onError: (err) => {
      dispatch(
        showToast({
          type: 'error',
          message: (err as Error)?.message ?? 'Something went wrong',
        }),
      );
    },
  });

  return { enableFeatureForAllUsers: mutate, ...rest };
};

import { PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { Avatar as AvatarMui, AvatarProps, Box, BoxProps } from '@mui/material';

import avatar from 'src/assets/icons/empty_avatar.svg';

const StyedAvatar = styled(AvatarMui)<{ cursor: string; size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  cursor: ${({ cursor }) => cursor};
  text-shadow: 1px 1px 0 #000;
`;

export interface IProfileAvatarProps extends BoxProps {
  src?: string;
  size?: string;
  pointer?: boolean;
  firstLatter?: string;
  avatarProps?: AvatarProps;
}

export const Avatar = ({
  src,
  pointer,
  children,
  avatarProps,
  size = '32px',
  firstLatter = '',
  ...props
}: PropsWithChildren<IProfileAvatarProps>) => {
  const content = firstLatter.toUpperCase() ?? children;

  return (
    <Box {...props}>
      <StyedAvatar
        size={size}
        cursor={pointer ? 'pointer' : 'default'}
        src={src || (content ? '' : avatar)}
        {...avatarProps}
      >
        {content}
      </StyedAvatar>
    </Box>
  );
};

import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Box } from '@mui/material';

import { Block, Input, Select, Typography } from 'src/components';
import { billingOptions, planOptions } from 'src/constants/teams';
import { useAppSelector } from 'src/hooks';
import { updateTeamsRequest } from 'src/store';
import { ITeamsFilters } from 'src/types';

const SelectWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const TeamsFilters = () => {
  const dispatch = useDispatch();
  const teamsRequest = useAppSelector(({ team }) => team.teamsRequest);
  const { billingType, membersCount, plan, search, seats } = teamsRequest;

  const updateFilter = (filters: Partial<ITeamsFilters>) => {
    dispatch(updateTeamsRequest({ page: 1, ...filters }));
  };

  return (
    <Block p="20px 16px" display="grid" gridTemplateColumns="auto auto auto auto 1fr" gap="16px">
      <SelectWrapper>
        <Typography>Plan:</Typography>
        <Select
          minWidth="120px"
          placeholder="Select plan"
          options={planOptions}
          onChange={(nextValue) => updateFilter({ plan: nextValue.value })}
          value={plan}
        />
      </SelectWrapper>
      <SelectWrapper>
        <Typography>Billing type:</Typography>
        <Select
          placeholder="Billing type:"
          options={billingOptions}
          onChange={(nextValue) => updateFilter({ billingType: nextValue.value })}
          value={billingType}
        />
      </SelectWrapper>
      <SelectWrapper>
        <Typography>Seats Q-ty:</Typography>
        <Box maxWidth="120px">
          <Input
            name="seats"
            placeholder="Input number"
            height="35px"
            pattern="[0-9]*"
            value={seats}
            onChange={(event) => event.target.validity.valid && updateFilter({ seats: event.target.value })}
          />
        </Box>
      </SelectWrapper>
      <SelectWrapper>
        <Typography>Team Members:</Typography>
        <Box maxWidth="120px">
          <Input
            name="input"
            placeholder="Input members"
            height="35px"
            pattern="[0-9]*"
            value={membersCount}
            onChange={(event) => event.target.validity.valid && updateFilter({ membersCount: event.target.value })}
          />
        </Box>
      </SelectWrapper>

      <Input
        name="input"
        placeholder="Search by Team Name, Stripe ID"
        height="35px"
        icon={faSearch}
        value={search}
        onChange={(event) => updateFilter({ search: event.target.value })}
      />
    </Block>
  );
};

import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getUsersSummary } from 'src/api';
import { IAnalyticUserSummary, IDateRangeFilter } from 'src/types';

export const useGetUsersSummary = <TData extends IAnalyticUserSummary[]>(
  params?: IDateRangeFilter,
  options?: UseQueryOptions<IAnalyticUserSummary[], AxiosError, TData>,
) => {
  const { data, ...rest } = useQuery<IAnalyticUserSummary[], AxiosError, TData>(
    ['get-users-summary-analytic', JSON.stringify(params)],
    () => getUsersSummary(params),
    options,
  );

  return {
    usersSummary: data,
    ...rest,
  };
};

import { useMutation } from 'react-query';

import { exportUsersCsv } from 'src/api';

export const useExportUsersCsv = () => {
  const { mutate, isLoading } = useMutation(['export-users-csv'], exportUsersCsv, {
    onSuccess: (data) => {
      const blobFile = new Blob([data], { type: 'text/csv' });
      const href = URL.createObjectURL(blobFile);
      const link = document.createElement('a');

      link.href = href;
      link.setAttribute('download', 'all_users.csv');
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    },
  });

  return { exportUsersCsv: mutate, isLoading };
};

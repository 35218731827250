import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getCodesTeam } from 'src/api';
import { IGetCodesTeamRes } from 'src/types';

export const useGetCodesTeam = <TData extends IGetCodesTeamRes[]>(
  codeId: number,
  options?: UseQueryOptions<IGetCodesTeamRes[], AxiosError, TData>,
) => {
  const { data, ...rest } = useQuery<IGetCodesTeamRes[], AxiosError, TData>(
    ['get-codes-team', codeId],
    () => getCodesTeam(codeId),
    options,
  );

  return { codesTeam: data, ...rest };
};

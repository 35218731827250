import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getUserCloudBotInfo } from 'src/api';
import { IGetUserCloudBotInfoRes } from 'src/types';

export const useGetUserCloudBotInfo = <TData extends IGetUserCloudBotInfoRes>(
  userId: number,
  options?: UseQueryOptions<IGetUserCloudBotInfoRes, AxiosError, TData>,
) => {
  const { data, ...rest } = useQuery<IGetUserCloudBotInfoRes, AxiosError, TData>(
    ['get-user-cloud-bot-info', userId],
    () => getUserCloudBotInfo(userId),
    options,
  );

  return { cloudBotInfo: data, ...rest };
};

import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { faCheck, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import { Link, TableCell, TableRow, Typography } from 'src/components';
import { ModalTypes } from 'src/enums';
import { openModal } from 'src/store';
import { ITeam } from 'src/types';

interface ITeamsTableRowProps {
  team: ITeam;
}

export const TeamsTableRow = ({ team }: ITeamsTableRowProps) => {
  const {
    currency,
    hasPaidPlan,
    id,
    isTrialExtended,
    isTrialTeam,
    membersCount,
    name,
    nextInvoiceCreatedAt,
    owner,
    seats,
    stripeId,
    subscription,
    trialEndsAt,
    isSubscriptionPaused,
  } = team;
  const dispatch = useDispatch();
  const trialEndDate = dayjs(Number(trialEndsAt) * 1000).diff(dayjs(), 'days');

  const onExtend = () =>
    dispatch(
      openModal({
        type: ModalTypes.EXTEND_TRIAL,
        headerText: 'Extend trial',
        params: {
          teamId: id,
        },
      }),
    );

  return (
    <>
      <TableRow hoverBgColor="#ebecf2">
        <TableCell>
          <Link to={`/support/teams/${id}/general-information`}>{name}</Link>
        </TableCell>
        <TableCell>{owner}</TableCell>
        <TableCell>{subscription}</TableCell>
        <TableCell>{hasPaidPlan ? 'Yes' : 'No'}</TableCell>
        <TableCell>{isTrialTeam ? 'Yes' : 'No'}</TableCell>
        <TableCell>{isSubscriptionPaused ? 'Yes' : 'No'}</TableCell>
        <TableCell>{seats}</TableCell>
        <TableCell>
          <Link to={`/support/teams/${id}/members`}>
            <Typography color="#002F53" underline>
              {membersCount}
            </Typography>
          </Link>
        </TableCell>
        <TableCell>
          <Link external openNewTab to={`https://dashboard.stripe.com/customers/${stripeId}`}>
            {stripeId}
          </Link>
        </TableCell>
        <TableCell>{!!trialEndsAt && trialEndDate > 0 && trialEndDate}</TableCell>
        <TableCell>{currency?.toUpperCase()}</TableCell>
        <TableCell justifyContent="center">
          <FontAwesomeIcon icon={isTrialExtended ? faCheck : faX} />
        </TableCell>
        <TableCell>{!!nextInvoiceCreatedAt && dayjs(nextInvoiceCreatedAt * 1000).format('DD MMM, YYYY')}</TableCell>
        <TableCell contentStyle={{ width: '100%' }}>
          <Box display="flex" justifyContent="space-between" width="100%" px="8px">
            <Typography color="#002F53" underline pointer onClick={onExtend}>
              Extend Trial
            </Typography>

            <Link to={`/support/teams/${id}/stripe-info`}>Stripe Details</Link>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};

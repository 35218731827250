import dayjs from 'dayjs';
import { ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';

import { TableCell, TableRow, Toggle, Tooltip } from 'src/components';
import { useEnableFeatureForAdmins, useEnableFeatureForAllUsers } from 'src/reactQueries';
import { IFeatureFlag } from 'src/types';
import { openModal } from '../../../store';

export const FeatureFlagsTableRow = ({
  id,
  name,
  uniqueKey,
  enabledForAllAlfredAdminsAt,
  enabledForAllUsersAt,
}: IFeatureFlag) => {
  const dispatch = useDispatch();

  const { enableFeatureForAdmins, isLoading: isLoadingForAdmins } = useEnableFeatureForAdmins();
  const { enableFeatureForAllUsers, isLoading: isLoadingForAllUser } = useEnableFeatureForAllUsers();

  const onEnableForAdminsChange = (e: ChangeEvent<HTMLInputElement>) => {
    const enabled = e.target.checked;

    dispatch(
      openModal({
        headerText: `${enabled ? 'Enable' : 'Disable'} ${name} for all Alfred admins.`,
        descriptionText: `Are you sure you want to ${enabled ? 'Enable' : 'Disable'} ${name} for all Alfred admins ?`,
        onConfirm: () => enableFeatureForAdmins({ featureId: id, enabled }),
      }),
    );
  };

  const onEnableForAllUsersChange = (e: ChangeEvent<HTMLInputElement>) => {
    const enabled = e.target.checked;

    dispatch(
      openModal({
        headerText: `${enabled ? 'Enable' : 'Disable'} ${name} for all users.`,
        descriptionText: `Are you sure you want to ${enabled ? 'Enable' : 'Disable'} ${name} for all Alfred users ?`,
        onConfirm: () => enableFeatureForAllUsers({ featureId: id, enabled }),
      }),
    );
  };

  return (
    <TableRow>
      <TableCell>{id}</TableCell>
      <TableCell>{name}</TableCell>
      <TableCell>{uniqueKey}</TableCell>
      <TableCell>
        <Tooltip
          title={
            enabledForAllAlfredAdminsAt &&
            `Enabled on ${dayjs(enabledForAllAlfredAdminsAt).format('MM-DD-YYYY hh:mma')}`
          }
        >
          <span>
            <Toggle
              checked={!!enabledForAllAlfredAdminsAt}
              disabled={isLoadingForAllUser || isLoadingForAdmins}
              onChange={onEnableForAdminsChange}
            />
          </span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip
          title={enabledForAllUsersAt && `Enabled on ${dayjs(enabledForAllAlfredAdminsAt).format('MM-DD-YYYY hh:mma')}`}
        >
          <span>
            <Toggle
              checked={!!enabledForAllUsersAt}
              disabled={isLoadingForAllUser || isLoadingForAdmins}
              onChange={onEnableForAllUsersChange}
            />
          </span>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

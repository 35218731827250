import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import {
  Breadcrumbs,
  HeaderContainer,
  HeaderTitle,
  Table,
  TableBody,
  TableHead,
  TableHeadCell,
  TablePagination,
  TableRow,
} from 'src/components';
import { UsersFilters } from 'src/containers/Support/Users/UsersFilters';
import { UsersTableRow } from 'src/containers/Support/Users/UsersTableRow';
import { useAppSelector, useDebounce } from 'src/hooks';
import { useGetAllUsers } from 'src/reactQueries';
import { updateUsersRequest } from 'src/store';
import { ITablePagination, TGetAllUsersInfoSortFields, TSortOrder } from 'src/types';

const TableWrapper = styled(Box)`
  overflow-x: auto;
  overflow-y: hidden;

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid #fff;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &::-webkit-scrollbar {
    height: 9px;
  }
`;

export const Users = () => {
  const dispatch = useDispatch();
  const usersRequest = useAppSelector(({ user }) => user.usersRequest);
  const { search = '', page, perPage, sortField, sortOrder } = usersRequest;

  const [showAdvancedColumns, setShowAdvancedColumns] = useState(false);

  const debouncedSearch = useDebounce(search, 500);
  const { allUsers, isLoading } = useGetAllUsers({ ...usersRequest, search: debouncedSearch });
  const { total = 0, users = [] } = allUsers ?? {};

  useEffect(() => {
    dispatch(updateUsersRequest({ page: 1 }));
  }, [debouncedSearch]);

  const sortingProps = {
    sortField,
    sortOrder,
    onSortFieldChange: (sortField: TGetAllUsersInfoSortFields, sortOrder: TSortOrder) => {
      dispatch(updateUsersRequest({ sortOrder, sortField }));
    },
  };

  const paginationChangeHandler = (pagination: ITablePagination) => {
    dispatch(updateUsersRequest(pagination));
  };

  return (
    <>
      <HeaderContainer pb="15px">
        <Breadcrumbs>
          <Link to="/support">Support</Link>
          <p>All Users</p>
        </Breadcrumbs>
        <Box display="flex" justifyContent="space-between" alignItems="flex-end" mt="8px">
          <HeaderTitle>All Users</HeaderTitle>
        </Box>
      </HeaderContainer>
      <Box m="16px" display="grid" gap="16px">
        <UsersFilters showAdvancedColumns={showAdvancedColumns} setShowAdvancedColumns={setShowAdvancedColumns} />
        <TableWrapper>
          <Table placeholder={!total && 'No users found.'} processing={isLoading} disabled={isLoading}>
            <TableHead>
              <TableRow>
                <TableHeadCell secondary width="88px" name="id" sorting={sortingProps}>
                  Key
                </TableHeadCell>
                <TableHeadCell secondary width="137px" name="name" sorting={sortingProps}>
                  Name
                </TableHeadCell>
                <TableHeadCell secondary width="210px" name="email" sorting={sortingProps}>
                  Email
                </TableHeadCell>
                <TableHeadCell secondary width="126px" name="lastConnectedAt" sorting={sortingProps}>
                  Last Connection
                </TableHeadCell>
                <TableHeadCell secondary width="112px" name="joined" sorting={sortingProps}>
                  Joined
                </TableHeadCell>
                <TableHeadCell secondary width="82px" name="role" sorting={sortingProps}>
                  Role
                </TableHeadCell>
                <TableHeadCell secondary width="220px" name="linkedInEmail" sorting={sortingProps}>
                  LinkedIn Email
                </TableHeadCell>
                <TableHeadCell secondary width="82px" name="isTrialExtended" sorting={sortingProps}>
                  Trial Extended
                </TableHeadCell>
                <TableHeadCell secondary width="92px">
                  Trial Period
                </TableHeadCell>
                <TableHeadCell secondary width="116px">
                  Action
                </TableHeadCell>
                <TableHeadCell secondary width="76px" name="isUserBlocked" sorting={sortingProps}>
                  User Blocked
                </TableHeadCell>
                <TableHeadCell secondary width="209px" name="integrationEmail" sorting={sortingProps}>
                  Integration Email
                </TableHeadCell>
                <TableHeadCell secondary width="91px" name="campaignsCount" sorting={sortingProps}>
                  Total Campaigns
                </TableHeadCell>
                <TableHeadCell secondary width="137px" name="plan" sorting={sortingProps}>
                  Active Team Plan
                </TableHeadCell>
                <TableHeadCell secondary width="100px" name="billingType" sorting={sortingProps}>
                  Billing Type
                </TableHeadCell>
                <TableHeadCell secondary width="98px" name="membership" sorting={sortingProps}>
                  LinkedIn Membership
                </TableHeadCell>
                <TableHeadCell secondary width="98px" name="trialEndsAt" sorting={sortingProps}>
                  Trial Days Remaining
                </TableHeadCell>
                <TableHeadCell secondary width="92px">
                  HTTP Client
                </TableHeadCell>
                <TableHeadCell secondary width="102px" sorting={sortingProps}>
                  Timezone
                </TableHeadCell>

                {showAdvancedColumns && (
                  <>
                    <TableHeadCell secondary width="126px" name="subscribedAt" sorting={sortingProps}>
                      Subscription
                    </TableHeadCell>
                    <TableHeadCell secondary width="85px" name="isSubscriber" sorting={sortingProps}>
                      Customer
                    </TableHeadCell>
                    <TableHeadCell secondary width="60px" name="seats" sorting={sortingProps}>
                      Seats
                    </TableHeadCell>
                    <TableHeadCell secondary width="120px" name="utmSource" sorting={sortingProps}>
                      Source
                    </TableHeadCell>
                    <TableHeadCell secondary width="150px" name="trialOrigin" sorting={sortingProps}>
                      Trial Button
                    </TableHeadCell>
                    <TableHeadCell secondary width="126px" name="country" sorting={sortingProps}>
                      Location
                    </TableHeadCell>
                    <TableHeadCell secondary width="126" name="language" sorting={sortingProps}>
                      Language
                    </TableHeadCell>
                    <TableHeadCell secondary width="60px" name="pageCounter" sorting={sortingProps}>
                      Page
                    </TableHeadCell>
                    <TableHeadCell secondary width="250px" name="landingUrl" sorting={sortingProps}>
                      Landing Page
                    </TableHeadCell>
                    <TableHeadCell secondary width="250px" name="lastPage" sorting={sortingProps}>
                      Last Page
                    </TableHeadCell>
                    <TableHeadCell secondary width="80px" name="isEmailVerified" sorting={sortingProps}>
                      Verified
                    </TableHeadCell>
                    <TableHeadCell secondary width="80px">
                      LinkedIn
                    </TableHeadCell>
                    <TableHeadCell secondary width="60px">
                      Email
                    </TableHeadCell>
                    <TableHeadCell secondary width="80px">
                      Campaign
                    </TableHeadCell>
                    <TableHeadCell secondary width="108px" name="isTwitterConnected" sorting={sortingProps}>
                      Twitter Connected
                    </TableHeadCell>
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <UsersTableRow key={user.id} user={user} showAdvancedColumns={showAdvancedColumns} />
              ))}
            </TableBody>
          </Table>
        </TableWrapper>
        <TablePagination page={page} perPage={perPage} total={total} onPaginationChange={paginationChangeHandler} />
      </Box>
    </>
  );
};

import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getSubscribersAnalytic } from 'src/api';
import { ISubscribersAnalyticRes } from 'src/types';

export const useGetSubscribersAnalytic = <TData extends ISubscribersAnalyticRes>(
  options?: UseQueryOptions<ISubscribersAnalyticRes, AxiosError, TData>,
) => {
  const { data, ...rest } = useQuery<ISubscribersAnalyticRes, AxiosError, TData>(
    ['get-subscribers-analytic'],
    getSubscribersAnalytic,
    options,
  );

  return { subscribersAnalytic: data, ...rest };
};

import { ISelectOption, TCustomIntervalFilter, TIntervalFilter } from 'src/types';

export const DAYS = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'];
export const HOURS = [
  '12 am',
  '1 am',
  '2 am',
  '3 am',
  '4 am',
  '5 am',
  '6 am',
  '7 am',
  '8 am',
  '9 am',
  '10 am',
  '11 am',
  '12 pm (noon)',
  '1 pm',
  '2 pm',
  '3 pm',
  '4 pm',
  '5 pm',
  '6 pm',
  '7 pm',
  '8 pm',
  '9 pm',
  '10 pm',
  '11 pm',
  '11:59 pm',
];

export const TIME_INTERVAL_OPTIONS: ISelectOption<TIntervalFilter>[] = [
  { value: 'last30days', label: 'Last 30 days' },
  { value: 'last14days', label: 'Last 14 days' },
  { value: 'last7days', label: 'Last 7 days' },
  { value: 'yesterday', label: 'Yesterday' },
  { value: 'today', label: 'Today' },
];

export const CUSTOM_INTERVAL_OPTIONS: ISelectOption<TCustomIntervalFilter>[] = [
  { value: 'custom', label: 'Custom' },
  ...TIME_INTERVAL_OPTIONS,
];

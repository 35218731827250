import dayjs from 'dayjs';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TablePagination,
  TableRow,
  Typography,
} from 'src/components';
import { TeamDetailsLayout } from 'src/containers';
import { useDeleteInvite, useGetTeamInvites } from 'src/reactQueries';
import { openModal } from 'src/store';
import { ITablePagination, TSortOrder, TTeamInvitesSortFields } from 'src/types';

export const Invites = () => {
  const dispatch = useDispatch();
  const { teamId } = useParams();
  const TEAM_ID = Number(teamId);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState<TSortOrder>('ASC');
  const [sortField, setSortField] = useState<TTeamInvitesSortFields>('name');

  const params = { page, perPage, sortField, sortOrder };
  const { teamInvites, isLoading } = useGetTeamInvites(TEAM_ID, params);
  const { deleteInvite } = useDeleteInvite(TEAM_ID, params);
  const { invites = [], total } = teamInvites ?? {};

  const sortingProps = {
    sortField,
    sortOrder,
    onSortFieldChange: (field: TTeamInvitesSortFields, order: TSortOrder) => {
      setSortField(field);
      setSortOrder(order);
    },
  };

  const paginationChangeHandler = (pagination: ITablePagination) => {
    setPage(pagination.page);
    setPerPage(pagination.perPage);
  };

  const onDeleteInvite = (inviteId: number, username: string) =>
    dispatch(
      openModal({
        headerText: `Remove ${username} invitation`,
        descriptionText: `Are you sure you want to remove ${username} invitation to the team?`,
        onConfirm: () => deleteInvite(inviteId),
      }),
    );

  return (
    <TeamDetailsLayout>
      <Table placeholder={!total && 'No invites found'} processing={isLoading} disabled={isLoading}>
        <TableHead secondary>
          <TableRow>
            <TableHeadCell width="30%" secondary name="name" sorting={sortingProps}>
              Invited User
            </TableHeadCell>
            <TableHeadCell secondary name="role" sorting={sortingProps}>
              Role
            </TableHeadCell>
            <TableHeadCell secondary name="createdAt" sorting={sortingProps}>
              Invited
            </TableHeadCell>
            <TableHeadCell secondary name="status" sorting={sortingProps}>
              Status
            </TableHeadCell>
            <TableHeadCell secondary>Actions</TableHeadCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {invites.map(({ id, name, createdAt, isRejected, role }) => (
            <TableRow key={id}>
              <TableCell>
                <Typography medium>{name}</Typography>
              </TableCell>
              <TableCell>
                <Typography medium>{role}</Typography>
              </TableCell>
              <TableCell>
                <Typography medium>{!!createdAt && dayjs(createdAt).format('DD/MM/YYYY')}</Typography>
              </TableCell>
              <TableCell>
                <Typography medium>{isRejected ? 'Rejected' : 'Pending'}</Typography>
              </TableCell>
              <TableCell>
                {isRejected ? (
                  '-'
                ) : (
                  <Typography color="#FF4D4F" medium pointer underline onClick={() => onDeleteInvite(id, name)}>
                    Withdraw Invite
                  </Typography>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Box mx="10px">
        <TablePagination page={page} perPage={perPage} total={total} onPaginationChange={paginationChangeHandler} />
      </Box>
    </TeamDetailsLayout>
  );
};

import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import MuiTableRow, { TableRowProps } from '@mui/material/TableRow';

import { getContrastYIQ } from 'src/helpers';

const StyledTableRow = styled(MuiTableRow, {
  shouldForwardProp: (prop) => isPropValid(prop),
})<{ checked?: boolean; checkedBgColor?: string; hoverBgColor?: string }>`
  position: relative;
  ${({ checked, checkedBgColor = '#2361fc' }) =>
    checked ? `background-color: ${checkedBgColor}; * { color: ${getContrastYIQ(checkedBgColor)}; }` : ''}
  ${({ hoverBgColor }) => (hoverBgColor ? `&:hover { background-color: ${hoverBgColor} }` : '')}
`;

interface ITableRowProps extends TableRowProps {
  checked?: boolean;
  checkedBgColor?: string;
  hoverBgColor?: string;
}

export const TableRow = ({ children, ...restProps }: ITableRowProps) => {
  return <StyledTableRow {...restProps}>{children}</StyledTableRow>;
};

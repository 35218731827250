import styled from '@emotion/styled';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, BoxProps, CircularProgress } from '@mui/material';

const DotsSpinner = styled(FontAwesomeIcon)`
  animation: fa-spin 2s linear infinite;
`;

interface ISpinnerProps extends BoxProps {
  type?: 'circular' | 'dots';
  size?: string;
  inline?: boolean;
}

export const Spinner = ({
  type = 'circular',
  marginTop = type === 'circular' ? '50px' : '0',
  size = '16px',
  inline,
  ...restProps
}: ISpinnerProps) => (
  <Box
    component="span"
    display={inline ? 'inline' : 'flex'}
    alignItems="center"
    justifyContent="center"
    marginTop={marginTop}
    fontSize={size}
    {...restProps}
  >
    {type === 'circular' ? <CircularProgress size={size} /> : <DotsSpinner icon={faSpinner} />}
  </Box>
);

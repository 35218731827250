import styled from '@emotion/styled';
import { Box, BoxProps } from '@mui/material';

export const Block = styled(Box)`
  background: #ffffff;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
`;

const StyledBlockFooter = styled(Box)`
  background-color: #f9fbfc;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top: 1px solid #e8e8e8;
`;

export const BlockFooter = (props: BoxProps) => (
  <StyledBlockFooter p="18px 32px" display="flex" alignItems="center" {...props} />
);

const StyledBlockHeader = styled(Box)`
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

export const BlockHeader = (props: BoxProps) => (
  <StyledBlockHeader
    p="16px"
    display="flex"
    alignItems="center"
    justifyContent="space-between"
    bgcolor="#f9fbfc"
    borderBottom="1px solid #e8e8e8"
    {...props}
  />
);

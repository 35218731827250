import { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';

import { Breadcrumbs, HeaderContainer, HeaderTitle, Typography } from 'src/components';

export const CancellationsLayout = ({ children }: PropsWithChildren) => {
  return (
    <>
      <HeaderContainer pb="15px">
        <Breadcrumbs>
          <Link to="/support">Support</Link>
          <Typography>Cancellations</Typography>
        </Breadcrumbs>
        <Box mt="8px">
          <HeaderTitle>Cancellations</HeaderTitle>
        </Box>
      </HeaderContainer>
      <Box m="16px" display="grid" gap="16px">
        {children}
      </Box>
    </>
  );
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TToastType } from 'src/types';

const DEFAULT_AUTO_CLOSE_TIME = 3000; // ms
const DEFAULT_DELAY_TIME = 0; // ms

interface IInitialState {
  trace: Array<{
    message: string;
    type: TToastType;
    autoCloseTime?: number;
    delay?: number;
  }>;
}

const initialState: IInitialState = {
  trace: [],
};

export const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    showToast: (state, action: PayloadAction<IInitialState['trace'][0]>) => {
      const { message, type, autoCloseTime, delay } = action.payload;

      const nextItem: IInitialState['trace'][0] = {
        autoCloseTime: autoCloseTime ?? DEFAULT_AUTO_CLOSE_TIME,
        message,
        type,
        delay: delay ?? DEFAULT_DELAY_TIME,
      };

      state.trace.unshift(nextItem);
    },
  },
});

export const { showToast } = toastSlice.actions;

export default toastSlice.reducer;

import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getUserTeamInfo } from 'src/api';
import { IGetUserTeamInfoRes } from 'src/types';

export const useGetUserTeamInfo = <TData extends IGetUserTeamInfoRes>(
  userId: number,
  options?: UseQueryOptions<IGetUserTeamInfoRes, AxiosError, TData>,
) => {
  const { data, ...rest } = useQuery<IGetUserTeamInfoRes, AxiosError, TData>(
    ['get-user-team-info', userId],
    () => getUserTeamInfo(userId),
    options,
  );

  return { teamInfo: data, ...rest };
};

export const convertTime = (time: number) => {
  if (time <= 24 && time > 0) {
    return `${time}:00`;
  }

  return "Don't run";
};

export const convertDay = (day: number) => {
  if (day === 0) {
    return 7;
  }

  return day;
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ITeamsFilters } from 'src/types';

interface IInitialState {
  teamsRequest: Required<ITeamsFilters>;
}

export const DEFAULT_TEAMS_REQUEST: Required<ITeamsFilters> = {
  page: 1,
  perPage: 10,
  sortField: 'id',
  sortOrder: 'DESC',
  seats: '',
  search: '',
  membersCount: '',
  plan: 'all',
  billingType: 'all',
};

const initialState: IInitialState = {
  teamsRequest: DEFAULT_TEAMS_REQUEST,
};

export const teamSlice = createSlice({
  name: 'team',
  initialState,
  reducers: {
    updateTeamsRequest: (state, action: PayloadAction<Partial<ITeamsFilters>>) => {
      state.teamsRequest = { ...state.teamsRequest, ...action.payload };
    },
  },
});

export const { updateTeamsRequest } = teamSlice.actions;

export default teamSlice.reducer;

import { components as ReactSelectComponents, GroupBase, StylesConfig } from 'react-select';
import ReactAsyncSelect, { AsyncProps } from 'react-select/async';
import { faCaretDown, faCaretUp, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import { Typography } from 'src/components/ui';

const styles: StylesConfig = {
  control: () => {
    return {
      fontSize: '14px',
      borderRadius: '4px',
      border: '2px solid #eee',
      position: 'relative',
      display: 'flex',
      backgroundColor: '#fff',
    };
  },
  indicatorSeparator: () => {
    return {
      display: 'none',
    };
  },
  multiValue: () => {
    return {
      display: 'flex',
      lineHeight: '22px',
      margin: '3px 5px 3px 0',
      backgroundColor: 'rgba(0,126,255,.08)',
      border: '1px solid #ccc',
      borderRadius: '2px',
      padding: '2px 0',
      color: '#0071e6',
    };
  },
  multiValueLabel: () => {
    return {
      order: 2,
      padding: '0 5px',
    };
  },
  multiValueRemove: (provided) => {
    return {
      ...provided,
      borderRight: '1px solid gray',
      borderRadius: 0,
      cursor: 'pointer',

      ['&:hover']: {
        backgroundColor: 'rgba(0,113,230,.08)',
        color: '#98aaad',
      },
    };
  },
  option: (provided, props) => {
    return {
      ...provided,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      cursor: 'pointer',
      fontSize: '14px',
      padding: '15px',
      backgroundColor: 'transparent !important',
      transition: '0.2s',
      color: props?.isSelected ? '#007eff' : '#262a79',
      ['&:hover']: {
        color: '#007eff',
      },
    };
  },
};

export const AsyncSelect = <
  OptionType,
  IsMulti extends boolean = false,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>,
>(
  props: AsyncProps<OptionType, IsMulti, GroupType>,
) => {
  return (
    <ReactAsyncSelect
      styles={styles as unknown as StylesConfig<OptionType, IsMulti, GroupType>}
      components={{
        DropdownIndicator: (props) => (
          <ReactSelectComponents.DropdownIndicator {...props}>
            <Box width="16px" display="flex" justifyContent="center">
              <FontAwesomeIcon icon={props.selectProps.menuIsOpen ? faCaretUp : faCaretDown} color="#2361fc" />
            </Box>
          </ReactSelectComponents.DropdownIndicator>
        ),
        Option: ({ children, ...props }) => (
          <ReactSelectComponents.Option {...props}>
            {children} {props.isSelected && <FontAwesomeIcon size="xs" color="#2361fc" icon={faCheck} />}
          </ReactSelectComponents.Option>
        ),
        NoOptionsMessage: () => (
          <Typography margin="10px 15px" fontSize="16px" color="#999">
            Type to search
          </Typography>
        ),
      }}
      {...props}
    />
  );
};

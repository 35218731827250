import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getCancellations } from 'src/api';
import { IGetCancellationsReq, IGetCancellationsRes } from 'src/types';

export const useGetCancellations = <TData extends IGetCancellationsRes>(
  params: IGetCancellationsReq,
  options?: UseQueryOptions<IGetCancellationsRes, AxiosError, TData>,
) => {
  const { data, ...rest } = useQuery<IGetCancellationsRes, AxiosError, TData>(
    ['get-all-cancellations', JSON.stringify(params)],
    () => getCancellations(params),
    options,
  );

  return { cancellations: data, ...rest };
};

import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getTeamMembers } from 'src/api';
import { IGetTeamMembersReq, IGetTeamMembersRes } from 'src/types';

export const useGetTeamMembers = <TData extends IGetTeamMembersRes>(
  teamId: number,
  params: IGetTeamMembersReq,
  options?: UseQueryOptions<IGetTeamMembersRes, AxiosError, TData>,
) => {
  const { data, ...rest } = useQuery<IGetTeamMembersRes, AxiosError, TData>(
    ['get-team-members', teamId, JSON.stringify(params)],
    () => getTeamMembers(teamId, params),
    options,
  );

  return { teamMembers: data, ...rest };
};

import clsx from 'clsx';
import { CSSProperties, ReactNode } from 'react';
import styled from '@emotion/styled';
import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';
import MuiTableCell, { TableCellProps } from '@mui/material/TableCell';

import { Tooltip } from 'src/components';
import { propsBlackList } from 'src/helpers';
import { ITableSorting, TJustifyContent } from 'src/types';

const StyledTableHeadCell = styled(MuiTableCell)`
  padding: 10px;
  ${({ width }) => (width ? `width: ${width} !important; box-;` : '')}

  &.sortable {
    cursor: pointer;
  }
`;

const BLACK_LIST_PROPS = ['secondary'];

const StyledInnerContent = styled(Box, propsBlackList(BLACK_LIST_PROPS))<{ secondary?: boolean }>`
  display: flex;
  align-items: center;
  font-family: 'ProximaSoft', sans-serif;
  font-weight: 600;
  font-size: ${(props) => (props.secondary ? '10' : '14')}px;
  line-height: ${(props) => (props.secondary ? '12' : '16')}px;
  white-space: ${(props) => (props.secondary ? 'wrap' : 'nowrap')};
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #394548;
`;

interface ITableHeadCellProps extends TableCellProps {
  children?: JSX.Element | string;
  name?: string;
  sorting?: ITableSorting;
  secondary?: boolean;
  justifyContent?: TJustifyContent;
  contentStyle?: CSSProperties;
  tooltip?: ReactNode;
  tooltipPlacement?: 'top' | 'left';
}

export const TableHeadCell = ({
  children,
  width,
  name,
  sorting,
  tooltip,
  secondary,
  tooltipPlacement = 'top',
  justifyContent = secondary ? 'flex-start' : 'space-between',
  contentStyle,
  ...props
}: ITableHeadCellProps) => {
  const isSorting = !!(name && sorting?.onSortFieldChange);
  const isActive = name === sorting?.sortField;
  const activeIcon = sorting?.sortOrder === 'DESC' ? faSortDown : faSortUp;

  const clickHandler = () => {
    if (name && sorting?.onSortFieldChange) {
      sorting.onSortFieldChange(name, isActive && sorting?.sortOrder === 'ASC' ? 'DESC' : 'ASC');
    }
  };

  return (
    <StyledTableHeadCell
      variant="head"
      width={width}
      className={clsx({ sortable: isSorting })}
      {...(isSorting && { onClick: clickHandler })}
      {...props}
    >
      <Tooltip title={tooltip} placement={tooltipPlacement}>
        <StyledInnerContent justifyContent={justifyContent} secondary={secondary} style={contentStyle}>
          {children}
          {isSorting && (
            <FontAwesomeIcon
              icon={isActive ? activeIcon : faSort}
              style={{
                margin: '0 5px',
                color: '#394548',
                opacity: isActive ? 1 : 0.5,
              }}
            />
          )}
        </StyledInnerContent>
      </Tooltip>
    </StyledTableHeadCell>
  );
};

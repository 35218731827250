import { IEnableFeatureFlagRequest, IFeatureFlag } from 'src/types/';
import { adminApi } from './axios';

export const getFeatureFlags = (): Promise<IFeatureFlag[]> => adminApi.get('/features');

export const getUserFeatureFlags = (userId: number): Promise<IFeatureFlag[]> =>
  adminApi.get(`/features/users/${userId}`);

export const enableUserFeatureFlag = (
  userId: number,
  { featureId, enabled }: IEnableFeatureFlagRequest,
): Promise<void> => adminApi.post(`/features/${featureId}/users/${userId}`, { enabled });

export const enableFeatureForAdmins = ({ featureId, enabled }: IEnableFeatureFlagRequest): Promise<void> =>
  adminApi.post(`/features/${featureId}/admins`, { enabled });

export const enabledFeatureForAllUsers = ({ featureId, enabled }: IEnableFeatureFlagRequest): Promise<void> =>
  adminApi.post(`/features/${featureId}/users`, { enabled });

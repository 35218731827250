import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { MultiValue, SingleValue } from 'react-select';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { getSearchUser } from 'src/api';
import { AsyncSelect, Button, Checkbox, ModalBody, ModalFooter, Typography } from 'src/components';
import { AdminPermissionEnum } from 'src/enums/admins';
import { convertObjectToPermission } from 'src/helpers';
import { useCreateAdmin } from 'src/reactQueries';
import { closeModal } from 'src/store/modal.slice';
import { ISearchUser } from 'src/types';

const CheckboxWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 10px;
`;

type TAdminPermissionsForm = Record<AdminPermissionEnum, boolean>;

export const ModalAddAdmin = () => {
  const dispatch = useDispatch();
  const [selectUser, setSelectUser] = useState<ISearchUser>();
  const { register, handleSubmit } = useForm<TAdminPermissionsForm>();

  const { createAdmin, isLoading } = useCreateAdmin(() => dispatch(closeModal()));

  const changeHandler = (newValues: MultiValue<ISearchUser> | SingleValue<ISearchUser>) => {
    setSelectUser(newValues as ISearchUser);
  };

  const confirmHandler = (formData: TAdminPermissionsForm) => {
    const permissions = convertObjectToPermission(formData);

    createAdmin({
      permissions,
      userId: selectUser?.id ?? -1,
    });
  };

  const closeHandler = () => {
    dispatch(closeModal());
  };

  return (
    <>
      <ModalBody>
        <Box>
          <Typography mb="5px">Email</Typography>

          <AsyncSelect
            cacheOptions
            placeholder={'Select user'}
            defaultOptions
            loadOptions={(query: string) =>
              getSearchUser({ email: query }).then((data) =>
                data.map((user) => ({ ...user, label: user.email, value: user.id })),
              )
            }
            onChange={changeHandler}
          />
        </Box>
        {!!selectUser && (
          <Box mt="20px">
            <Typography medium>User Details</Typography>
            <Box display="flex" justifyContent="space-between" mt="5px">
              <Typography>{selectUser?.name}</Typography>
              <Typography>{selectUser?.teamName}</Typography>
            </Box>
          </Box>
        )}
        <Box mt="20px">
          <Typography medium mb="10px">
            Access
          </Typography>
          <Box display="grid" gridTemplateColumns="200px 200px" gap="10px">
            <CheckboxWrapper>
              <Checkbox type="checkbox" {...register('analytics')} />
              <Typography>Analytics tab</Typography>
            </CheckboxWrapper>
            <CheckboxWrapper>
              <Checkbox type="checkbox" {...register('impersonation')} />
              <Typography>Impersonate accounts</Typography>
            </CheckboxWrapper>
            <CheckboxWrapper>
              <Checkbox type="checkbox" {...register('support')} />
              <Typography>Support tab</Typography>
            </CheckboxWrapper>
            <CheckboxWrapper>
              <Checkbox type="checkbox" {...register('managingAdmins')} />
              <Typography>Manage Admins</Typography>
            </CheckboxWrapper>
            <CheckboxWrapper>
              <Checkbox type="checkbox" {...register('managingUsers')} />
              <Typography>Manage Users</Typography>
            </CheckboxWrapper>
            <CheckboxWrapper>
              <Checkbox type="checkbox" {...register('customPlans')} />
              <Typography>Custom Plans</Typography>
            </CheckboxWrapper>
            <CheckboxWrapper>
              <Checkbox type="checkbox" {...register('featureFlags')} />
              <Typography>Feature Flags</Typography>
            </CheckboxWrapper>
          </Box>
        </Box>
      </ModalBody>
      <ModalFooter alignToTheEnd>
        <Button onClick={closeHandler} variant="whiteBlue">
          Cancel
        </Button>
        <Button onClick={handleSubmit(confirmHandler)} processing={isLoading}>
          Add
        </Button>
      </ModalFooter>
    </>
  );
};

import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getUserLeadsInfo } from 'src/api';
import { IGetUserLeadsInfoRes } from 'src/types';

export const useGetUserLeadsInfo = <TData extends IGetUserLeadsInfoRes>(
  userId: number,
  options?: UseQueryOptions<IGetUserLeadsInfoRes, AxiosError, TData>,
) => {
  const { data, ...rest } = useQuery<IGetUserLeadsInfoRes, AxiosError, TData>(
    ['get-user-leads-info', userId],
    () => getUserLeadsInfo(userId),
    options,
  );

  return { leadsInfo: data, ...rest };
};

import { adminApi } from 'src/api/axios';
import {
  IAnalyticByPlanInfo,
  IAnalyticSkip,
  IAnalyticSummaryRes,
  IAnalyticUserSummary,
  ICancellationsRes,
  IDateRangeFilter,
  IGetLinkedInActionsStats,
  IGetPaidTeam,
  IGetPaidTeamsReq,
  IGetSummaryParams,
  IGetUserTypesRes,
  IIntervalFilter,
  ILandingPage,
  ILanguageAnalytic,
  ILinkedInActionsStats,
  ILocationAnalytic,
  IOnboardingConversion,
  ISubscribersAnalyticRes,
  ITrialButtonAnalytic,
  IUserAnalytic,
  IUsersActionsRes,
  IUsersAnalyticRes,
  IUtmSource,
  IVisitedPage,
} from 'src/types';

export const getSubscribersAnalytic = (): Promise<ISubscribersAnalyticRes> => adminApi.get(`/analytics/subscribers`);

export const getUsersAnalytic = (params: IIntervalFilter): Promise<IUsersAnalyticRes> =>
  adminApi.get(`/analytics/users`, { params });

export const getMonthlySignups = (params: IAnalyticSkip): Promise<IAnalyticByPlanInfo[]> =>
  adminApi.get(`/analytics/monthly-signups`, { params });

export const getOnboardingConversions = (params: IAnalyticSkip): Promise<IOnboardingConversion[]> =>
  adminApi.get(`/analytics/onboarding-conversions`, { params });

export const getUsersSummary = (params?: IDateRangeFilter): Promise<IAnalyticUserSummary[]> =>
  adminApi.get(`/analytics/users-summary`, { params });

export const getCancellationsAnalytic = (params: IIntervalFilter): Promise<ICancellationsRes> =>
  adminApi.get(`/analytics/cancellations`, { params });

export const getSummary = (params: IGetSummaryParams): Promise<IAnalyticSummaryRes> =>
  adminApi.get(`/analytics/summary`, { params });

export const getUsersActions = (params?: IAnalyticSkip): Promise<IUsersActionsRes> =>
  adminApi.get(`/analytics/users-actions`, { params });

export const getTrialButtons = (params?: IDateRangeFilter): Promise<ITrialButtonAnalytic[]> =>
  adminApi.get(`/analytics/trial-buttons`, { params });

export const getLocationAnalytic = (params?: IDateRangeFilter): Promise<ILocationAnalytic[]> =>
  adminApi.get(`/analytics/locations`, { params });

export const getPaidTeamsAnalytic = (params: IGetPaidTeamsReq): Promise<IGetPaidTeam[]> =>
  adminApi.get(`/analytics/paid-teams`, { params });

export const getUsersInfoAnalytics = (params: IAnalyticSkip): Promise<IUserAnalytic[]> =>
  adminApi.get(`/analytics/users-analytics`, { params });

export const getUsersTypesAnalytics = (params?: IDateRangeFilter): Promise<IGetUserTypesRes> =>
  adminApi.get(`/analytics/users-types`, { params });

export const getLandingPagesAnalytics = (params?: IDateRangeFilter): Promise<ILandingPage[]> =>
  adminApi.get(`/analytics/landing-pages`, { params });

export const getUtmSourcesAnalytics = (params?: IDateRangeFilter): Promise<IUtmSource[]> =>
  adminApi.get(`/analytics/utm-sources`, { params });

export const getVisitedPagesAnalytics = (params?: IDateRangeFilter): Promise<IVisitedPage[]> =>
  adminApi.get(`/analytics/visited-pages`, { params });

export const getLanguagesAnalytics = (params?: IDateRangeFilter): Promise<ILanguageAnalytic[]> =>
  adminApi.get(`/analytics/languages`, { params });

export const getNumberOfAffectedUsers = (params: IGetLinkedInActionsStats): Promise<ILinkedInActionsStats> =>
  adminApi.get(`/analytics/li-actions/users`, { params });

export const getNumberOfLIActions = (params: IGetLinkedInActionsStats): Promise<ILinkedInActionsStats> =>
  adminApi.get(`/analytics/li-actions/actions`, { params });

export const getNumberOfLIActionsPerUser = (params: IGetLinkedInActionsStats): Promise<ILinkedInActionsStats> =>
  adminApi.get(`/analytics/li-actions/actions-users`, { params });

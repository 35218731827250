import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getPauseReasonsStats } from 'src/api/';
import { IDateRangeFilter, IGetPauseReasonsStatsResponse } from 'src/types';

export const useGetPauseReasonsStats = <TData extends IGetPauseReasonsStatsResponse>(
  params: IDateRangeFilter,
  options?: UseQueryOptions<IGetPauseReasonsStatsResponse, AxiosError, TData>,
) => {
  const { data, ...rest } = useQuery<IGetPauseReasonsStatsResponse, AxiosError, TData>(
    ['get-pause-reasons-stats', JSON.stringify(params)],
    () => getPauseReasonsStats(params),
    options,
  );

  return { pauseReasonsStats: data, ...rest };
};

export enum ActionsDescriptions {
  redirectLoop = 'redirect_loop',
  tryingLinkedinLogin = 'trying linkedin login',
  linkedinLoginEnded = 'lnkedin login ended',
  proxyAdded = 'proxy added',
  linkedInLoginEnded = 'LinkedIn login ended',
}

export enum LiStatsActionsEnum {
  redirectLoop = 'redirect-loop',
  tryingLinkedInLogin = 'trying-linkedIn-login',
  autoLinkedInLoginEnded = 'auto-linkedIn-login-ended',
  proxyAdded = 'proxy-added',
  manualLinkedInLoginEnded = 'manual-linkedIn-login-ended',
  unusualActivityWarningDetected = 'unusual-activity-warning-detected',
}

import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import { Block, Button, InfoLabel, InfoLabelWrapper, Typography } from 'src/components';
import { UserDetailsLayout } from 'src/containers';
import { ActionsTable } from 'src/containers/Support/_components';
import { resolveMembership } from 'src/helpers';
import { useGetUserLinkedinInfo, useRemoveUserLinkedInCredentials } from 'src/reactQueries';
import { openModal } from 'src/store';

export const Linkedin = () => {
  const { userId } = useParams();
  const USER_ID = Number(userId);

  const dispatch = useDispatch();

  const { linkedinInfo } = useGetUserLinkedinInfo(USER_ID);
  const {
    activeCampaigns,
    connectionsCount,
    connectionsSyncCount,
    hasLinkedInPremium,
    hasLinkedInSalesNavigator,
    isLinkedInLoginValid,
    linkedInEmail,
  } = linkedinInfo ?? {};

  const { removeCredentials, isLoading: isRemovingCredentials } = useRemoveUserLinkedInCredentials(USER_ID);

  const membership = resolveMembership({ hasLinkedInPremium, hasLinkedInSalesNavigator });

  const openRemoveCredentialsModal = () => {
    dispatch(
      openModal({
        headerText: `DANGEROUS: Remove LinkedIn Credentials`,
        descriptionText: `Are you sure you want to remove the LI user credentials?`,
        onConfirm: removeCredentials,
      }),
    );
  };

  return (
    <UserDetailsLayout>
      <Block p="24px">
        <InfoLabelWrapper>
          <InfoLabel label="LinkedIn Email Connected">
            <Box display="flex" alignItems="center" justifyContent="flex-start" gap="60px">
              <Typography semibold crop>
                {linkedInEmail}
              </Typography>
              {linkedInEmail && (
                <Button
                  variant="whiteBlue"
                  disabled={isRemovingCredentials}
                  size={12}
                  lineHeight="14px"
                  onClick={() => openRemoveCredentialsModal()}
                >
                  Remove LI credentials
                </Button>
              )}
            </Box>
          </InfoLabel>
          <InfoLabel label="LinkedIn Membership">{isLinkedInLoginValid && membership}</InfoLabel>
          <InfoLabel label="Connection Status">{isLinkedInLoginValid ? 'Active' : 'Disabled'}</InfoLabel>
          <InfoLabel label="Active Campaigns">{activeCampaigns}</InfoLabel>
          <InfoLabel label="Number of Connections (Synced)">
            {connectionsCount} ({connectionsSyncCount})
          </InfoLabel>
        </InfoLabelWrapper>
      </Block>
      <ActionsTable title="Last 10 LinkedIn Actions" type="linkedIn" userId={USER_ID} />
    </UserDetailsLayout>
  );
};

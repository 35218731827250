import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getTeamWhiteLabel } from 'src/api';
import { ITeamWhiteLabel } from 'src/types';

export const useGetTeamWhiteLabel = (teamId: number, options?: UseQueryOptions<ITeamWhiteLabel, AxiosError>) => {
  const { data, ...rest } = useQuery<ITeamWhiteLabel, AxiosError>(
    ['get-team-white-label', teamId],
    () => getTeamWhiteLabel(teamId),
    options,
  );

  return { whitelabel: data, ...rest };
};

import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getTeam } from 'src/api';
import { IGetTeamRes } from 'src/types';

export const useGetTeamInfo = <TData extends IGetTeamRes>(
  teamId: number,
  options?: UseQueryOptions<IGetTeamRes, AxiosError, TData>,
) => {
  const { data, ...rest } = useQuery<IGetTeamRes, AxiosError, TData>(
    ['get-team-info', teamId],
    () => getTeam(teamId),
    options,
  );

  return { teamInfo: data, ...rest };
};

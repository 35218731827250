import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import {
  Block,
  Breadcrumbs,
  Button,
  Checkbox,
  HeaderContainer,
  HeaderTitle,
  InfoLabel,
  InfoLabelWrapper,
  Input,
  Typography,
} from 'src/components';
import { REGEXP_VALID_EMAIL } from 'src/constants';
import { AdminPermissionEnum } from 'src/enums';
import { convertObjectToPermission } from 'src/helpers';
import { useChangeUserEmail, useDeleteAdmin, useGetAdmin, useUpdateAdmin } from 'src/reactQueries';
import { openModal, showToast } from 'src/store';

const CheckboxWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const StyledInput = styled(Input)`
  height: 23px;
`;

type TAdminPermissionsForm = Record<AdminPermissionEnum, boolean>;

export const AdminDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userId } = useParams();
  const USER_ID = Number(userId);

  const [emailInput, setEmailInput] = useState('');
  const [isEmailEdit, setIsEmailEdit] = useState(false);
  const { register, handleSubmit, setValue } = useForm<TAdminPermissionsForm>();

  const { admin } = useGetAdmin(USER_ID);
  const { deleteAdmin, isLoading: isDeleteLoading } = useDeleteAdmin(() => navigate('/manage-admins'));
  const { updateAdmin, isLoading: isUpdateLoading } = useUpdateAdmin(USER_ID, () => navigate('/manage-admins'));
  const { changeEmail } = useChangeUserEmail(USER_ID);
  const { email = '', id, name, permissions } = admin ?? {};

  useEffect(() => {
    if (permissions?.length) {
      permissions.forEach((permission) => setValue(permission, true));
    }
  }, [permissions]);

  useEffect(() => {
    if (email) {
      setEmailInput(email);
    }
  }, [email]);

  const onSaveClick = (formData: TAdminPermissionsForm) => {
    const permissions = convertObjectToPermission(formData);

    updateAdmin(permissions);
  };

  const onRemoveUser = () =>
    dispatch(
      openModal({
        headerText: `Remove Admin access?`,
        descriptionText: `Are you sure you would like to remove Admin access for ${name}?`,
        onConfirm: () => deleteAdmin(USER_ID),
      }),
    );

  const onChangeEmailClick = () => {
    if (isEmailEdit) {
      if (!REGEXP_VALID_EMAIL.test(emailInput)) {
        return dispatch(
          showToast({
            type: 'error',
            message: 'please enter a valid email',
          }),
        );
      }
      if (emailInput === email) {
        setIsEmailEdit((prev) => !prev);

        return dispatch(
          showToast({
            type: 'error',
            message: 'Email exists',
          }),
        );
      }

      changeEmail({ email: emailInput });
    }

    return setIsEmailEdit((prev) => !prev);
  };

  return (
    <>
      <HeaderContainer pb="15px">
        <Breadcrumbs>
          <Link to="/manage-admins">Manage Admins</Link>
          <Typography>Profile Details</Typography>
        </Breadcrumbs>
        <Box display="flex" justifyContent="space-between">
          <HeaderTitle mt="8px">Manage Admins</HeaderTitle>
          <Box display="flex" gap="15px">
            <Button variant="red" gap="10px" onClick={onRemoveUser} processing={isDeleteLoading}>
              <FontAwesomeIcon icon={faTrashAlt} /> Delete
            </Button>
            <Button variant="whiteBlue" onClick={() => navigate('/manage-admins')}>
              Cancel
            </Button>
            <Button onClick={handleSubmit(onSaveClick)} processing={isUpdateLoading}>
              Save
            </Button>
          </Box>
        </Box>
      </HeaderContainer>
      <Block m="16px">
        <Box p="24px">
          <InfoLabelWrapper gap="60px">
            <InfoLabel label="Name">{name}</InfoLabel>
            <InfoLabel label="Email">
              {REGEXP_VALID_EMAIL.test(email) ? (
                <Box display="flex" alignItems="center" justifyContent="space-between" gap="15px">
                  {isEmailEdit ? (
                    <StyledInput name="email" value={emailInput} onChange={(e) => setEmailInput(e.target.value)} />
                  ) : (
                    <Typography semibold crop>
                      {emailInput}
                    </Typography>
                  )}
                  <Button variant="whiteBlue" size={12} lineHeight="14px" onClick={onChangeEmailClick}>
                    {isEmailEdit ? 'Save' : 'Edit'}
                  </Button>
                </Box>
              ) : (
                <Typography medium>
                  <em>User without email</em>
                </Typography>
              )}
            </InfoLabel>

            <InfoLabel label="ID">{id}</InfoLabel>
          </InfoLabelWrapper>
          <Box mt="40px">
            <Typography fontSize="18px" medium>
              Grant access to
            </Typography>
            <Box display="grid" gridTemplateColumns="200px 200px" gap="10px" mt="20px">
              <CheckboxWrapper>
                <Checkbox type="checkbox" {...register('analytics')} />
                <Typography>Analytics tab</Typography>
              </CheckboxWrapper>
              <CheckboxWrapper>
                <Checkbox type="checkbox" {...register('impersonation')} />
                <Typography>Impersonate accounts</Typography>
              </CheckboxWrapper>
              <CheckboxWrapper>
                <Checkbox type="checkbox" {...register('support')} />
                <Typography>Support tab</Typography>
              </CheckboxWrapper>
              <CheckboxWrapper>
                <Checkbox type="checkbox" {...register('managingAdmins')} />
                <Typography>Manage Admins</Typography>
              </CheckboxWrapper>
              <CheckboxWrapper>
                <Checkbox type="checkbox" {...register('managingUsers')} />
                <Typography>Manage Users</Typography>
              </CheckboxWrapper>
              <CheckboxWrapper>
                <Checkbox type="checkbox" {...register('customPlans')} />
                <Typography>Custom Plans</Typography>
              </CheckboxWrapper>
              <CheckboxWrapper>
                <Checkbox type="checkbox" {...register('featureFlags')} />
                <Typography>Feature Flags</Typography>
              </CheckboxWrapper>
            </Box>
          </Box>
        </Box>
      </Block>
    </>
  );
};

import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getUsersActions } from 'src/api';
import { transformAnalyticMonthData } from 'src/helpers';
import { IAnalyticSkip, IUsersActionsRes } from 'src/types';

export const useGetUsersActions = <TData extends IUsersActionsRes[]>(
  params?: IAnalyticSkip,
  options?: UseQueryOptions<IUsersActionsRes, AxiosError, TData>,
) => {
  const { data, ...rest } = useQuery<IUsersActionsRes, AxiosError, TData>(
    ['get-users-actions-analytic', JSON.stringify(params)],
    () => getUsersActions(params),
    options,
  );

  return { usersActions: transformAnalyticMonthData(data, 'label'), ...rest };
};

import dayjs from 'dayjs';
import { keyBy } from 'lodash';
import { useState } from 'react';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Box } from '@mui/material';

import {
  ActionButton,
  Block,
  BlockHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Typography,
} from 'src/components';
import { separateNumWithComma } from 'src/helpers';
import { useGetOnboardingConversions } from 'src/reactQueries';
import { IOnboardingConversion } from 'src/types';

const rowsPattern: {
  label: string;
  valueKey: keyof IOnboardingConversion;
  totalValueKey: keyof IOnboardingConversion;
}[] = [
  {
    label: 'Basic',
    valueKey: 'basicConversions',
    totalValueKey: 'basic',
  },
  {
    label: 'Premium',
    valueKey: 'premiumConversions',
    totalValueKey: 'premium',
  },
  {
    label: 'Sales Navigator',
    valueKey: 'salesNavigatorConversions',
    totalValueKey: 'salesNavigator',
  },
  {
    label: 'Total Activated',
    valueKey: 'activatedConversions',
    totalValueKey: 'activated',
  },
  {
    label: 'Total Trials',
    valueKey: 'totalConversions',
    totalValueKey: 'total',
  },
];

export const Conversions = () => {
  const [skip, setSkip] = useState(0);
  const absSkip = Math.abs(skip);

  const { onboardingConversions = [], isLoading } = useGetOnboardingConversions({ skip: absSkip });
  const conversions = keyBy(onboardingConversions, 'label');

  const showMonth = [
    dayjs().subtract(absSkip + 2, 'M'),
    dayjs().subtract(absSkip + 1, 'M'),
    dayjs().subtract(absSkip, 'M'),
  ];

  return (
    <Block>
      <BlockHeader py="0" height="64px" border="none" bgcolor="white">
        <Typography medium fontSize="20px">
          Conversions
        </Typography>
        <Box display="flex" gap="10px">
          <ActionButton icon={faArrowLeft} onClick={() => setSkip((prev) => prev - 1)} />
          <ActionButton icon={faArrowRight} disabled={skip >= 0} onClick={() => setSkip((prev) => prev + 1)} />
        </Box>
      </BlockHeader>
      <Table
        placeholder={!onboardingConversions?.length && 'No conversions found'}
        processing={isLoading}
        disabled={isLoading}
      >
        <TableHead secondary>
          <TableRow>
            <TableHeadCell width="120px" secondary />
            {showMonth.map((month) => (
              <TableHeadCell key={month.toISOString()} secondary justifyContent="flex-end">
                {month.format("MMM 'YY'")}
              </TableHeadCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {rowsPattern.map(({ label, totalValueKey, valueKey }) => {
            return (
              <TableRow key={label}>
                <TableCell>{label}</TableCell>
                {showMonth.map((month) => {
                  const monthKey = month.format('YYYY/MM');
                  const value = Number(conversions?.[monthKey]?.[valueKey]) || 0;
                  const totalValue = Number(conversions?.[monthKey]?.[totalValueKey]) || 0;
                  const totalPercentage = Number((value * 100) / (totalValue || 1)).toFixed(2);

                  return (
                    <TableCell key={monthKey} justifyContent="flex-end">
                      {separateNumWithComma(value)}{' '}
                      <Typography fontSize="12px" inline>
                        ({totalPercentage}%)
                      </Typography>
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Block>
  );
};

import { Link } from 'react-router-dom';
import { Box } from '@mui/material';

import { Breadcrumbs, HeaderContainer, HeaderTitle } from 'src/components';
import { FeatureFlagsTable } from './FeatureFlagsTable';

export const FeatureFlags = () => {
  return (
    <>
      <HeaderContainer pb="15px">
        <Breadcrumbs>
          <Link to="/support">Support</Link>
          <p>Feature Flags</p>
        </Breadcrumbs>
        <Box display="flex" justifyContent="space-between" alignItems="flex-end" mt="8px">
          <HeaderTitle>Feature Flags</HeaderTitle>
        </Box>
      </HeaderContainer>

      <Box p="16px">
        <FeatureFlagsTable />
      </Box>
    </>
  );
};

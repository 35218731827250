import { ReactNode } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import MuiTableBody, { TableBodyProps } from '@mui/material/TableBody';

const Placeholder = styled(Box)`
  font-size: 14px;
  color: #808080;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const PlaceholderBody = styled.caption`
  height: 76px;
  box-sizing: border-box;
  text-align: center !important;
  padding-top: 32px !important;
`;

const StyledMuiTableBody = styled(MuiTableBody)`
  background-color: #ffffff;
`;

interface ITableBodyProps extends Omit<TableBodyProps, 'placeholder'> {
  placeholder?: false | ReactNode;
}

export const TableBody = ({ children, placeholder, ...restProps }: ITableBodyProps) => {
  if (placeholder) {
    return (
      <PlaceholderBody>
        <Placeholder>{placeholder}</Placeholder>
      </PlaceholderBody>
    );
  }

  return <StyledMuiTableBody {...restProps}>{children}</StyledMuiTableBody>;
};

import { reduce } from 'lodash';
import { useMemo, useState } from 'react';
import styled from '@emotion/styled';

import {
  Block,
  BlockHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Typography,
} from 'src/components';
import { getPercentage, separateNumWithComma, sortTable } from 'src/helpers';
import { useAppSelector } from 'src/hooks';
import { useGetCancellationsSavedUsersStats } from 'src/reactQueries';
import { TSortOrder } from 'src/types';

const StyledTable = styled(Table)`
  border-collapse: separate;
`;

const StyledTableHeadCell = styled(TableHeadCell)`
  padding: 14px 10px !important;
`;

type TSortFields = 'seats' | 'value';

export const SavedUsers = () => {
  const { fromDate, toDate } = useAppSelector(({ cancellation }) => cancellation.cancellationsRequest);
  const { savedUsersStats, isLoading } = useGetCancellationsSavedUsersStats({ toDate, fromDate });
  const savedUsers = savedUsersStats?.data || [];
  const [sortOrder, setSortOrder] = useState<TSortOrder>('DESC');
  const [sortField, setSortField] = useState<TSortFields>('value');

  const { totalSeats, totalCustomers } = useMemo(
    () =>
      reduce(
        savedUsers,
        (acc, item) => {
          acc.totalSeats += item.seats || 0;
          acc.totalCustomers += item.customers || 0;

          return acc;
        },
        { label: 'Total', totalSeats: 0, totalCustomers: 0 },
      ),
    [savedUsers],
  );

  const sortSavedUsers = useMemo(() => sortTable(savedUsers, sortField, sortOrder), [sortField, sortOrder, savedUsers]);

  const sortingProps = {
    sortField,
    sortOrder,
    onSortFieldChange: (field: TSortFields, order: TSortOrder) => {
      setSortField(field);
      setSortOrder(order);
    },
  };

  return (
    <Block>
      <BlockHeader py="0" height="64px" bgcolor="white">
        <Typography medium fontSize="20px">
          Saved Users
        </Typography>
      </BlockHeader>
      <StyledTable
        placeholder={!savedUsers?.length && 'No user summary found'}
        processing={isLoading}
        disabled={isLoading}
      >
        <TableHead secondary>
          <TableRow>
            <StyledTableHeadCell secondary width="70%" />
            <StyledTableHeadCell name="value" sorting={sortingProps} secondary>
              Customers
            </StyledTableHeadCell>
            <StyledTableHeadCell name="seats" sorting={sortingProps} justifyContent="flex-end" secondary>
              Users
            </StyledTableHeadCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {sortSavedUsers.map(({ label, seats, customers }) => (
            <TableRow key={label}>
              <TableCell>
                <Typography>{label}</Typography>
              </TableCell>
              <TableCell>
                <Typography>
                  {separateNumWithComma(customers)}{' '}
                  <Typography fontSize="12px" inline>
                    ({getPercentage(customers, totalCustomers)}%)
                  </Typography>
                </Typography>
              </TableCell>
              <TableCell justifyContent="flex-end">
                <Typography>
                  {separateNumWithComma(seats)}{' '}
                  <Typography fontSize="12px" inline>
                    ({getPercentage(seats, totalSeats)}%)
                  </Typography>
                </Typography>
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell>
              <Typography semibold>Total</Typography>
            </TableCell>
            <TableCell>
              <Typography semibold>{separateNumWithComma(totalCustomers)} </Typography>
            </TableCell>
            <TableCell justifyContent="flex-end">
              <Typography semibold>{separateNumWithComma(totalSeats)}</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </StyledTable>
    </Block>
  );
};

import { useRef, useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Typography } from 'src/components';
import { useIsOverflow } from 'src/hooks';

const CroppedText = styled(Box)<{ crop: boolean }>`
  ${({ crop }) =>
    crop &&
    ` white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-decoration: none;`}
`;

interface IShowMoreTextProps {
  text: string;
}

export const ShowMoreText = ({ text }: IShowMoreTextProps) => {
  const [showMore, setShowMore] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);

  const isOverflow = useIsOverflow(textRef);

  return (
    <Box display="flex" gap="5px" justifyContent="space-between" alignItems="center">
      <CroppedText crop={!showMore} ref={textRef}>
        {text}
      </CroppedText>

      {!showMore && isOverflow && !!text && (
        <Typography whiteSpace="nowrap" color="#002F53" pointer underline onClick={() => setShowMore(true)}>
          Show More
        </Typography>
      )}
    </Box>
  );
};

import { useMemo } from 'react';
import { Navigate, Outlet, Route, Routes as Switch } from 'react-router-dom';

import {
  AccessCode,
  AccessCodeDetails,
  Actions,
  ActionsPerUser,
  Cancellations,
  Cloud,
  EmailIntegration,
  FeatureFlags,
  FeatureFlagsInfo,
  General,
  GeneralInformation,
  Invites,
  LeadsInfo,
  Linkedin,
  ManageAdmins,
  Members,
  NoPermission,
  Referrals,
  StripeInfo,
  SubscriptionPauses,
  TeamInformation,
  Teams,
  TeamWhiteLabel,
  Twitter,
  UserProfile,
  Users,
  UsersAffected,
} from 'src/containers';
import { AdminDetails } from 'src/containers/ManageAdmins';
import { useGetSidebarConfig } from 'src/hooks';
import { AppLayout } from 'src/layouts/AppLayout';

export const Routes = () => {
  const { isAnalyticsShow, isManageAdminsShow, isSupportShow, isFeatureFlagsShow } = useGetSidebarConfig();

  const availableRoute = useMemo(() => {
    switch (true) {
      case isAnalyticsShow:
        return '/analytics';
      case isSupportShow:
        return '/support/users';
      case isManageAdminsShow:
        return '/manage-admins';

      default:
        return '/no-permission';
    }
  }, [isAnalyticsShow, isManageAdminsShow, isSupportShow]);

  return (
    <AppLayout>
      <Switch>
        <Route path="/support" element={isSupportShow ? <Outlet /> : <Navigate to="/no-permission" />}>
          <Route path="users">
            <Route path="" element={<Users />} />
            <Route path=":userId/details" element={<UserProfile />} />
            <Route path=":userId/leads-info" element={<LeadsInfo />} />
            <Route path=":userId/linkedin" element={<Linkedin />} />
            <Route path=":userId/twitter" element={<Twitter />} />
            <Route path=":userId/email-integration" element={<EmailIntegration />} />
            <Route path=":userId/team-info" element={<TeamInformation />} />
            <Route path=":userId/cloud" element={<Cloud />} />
            <Route
              path=":userId/feature-flags"
              element={isFeatureFlagsShow ? <FeatureFlagsInfo /> : <Navigate to="/no-permission" />}
            />

            <Route path="*" element={<Navigate to="/support/users" />} />
          </Route>
          <Route path="teams">
            <Route path="" element={<Teams />} />
            <Route path=":teamId/general-information" element={<GeneralInformation />} />
            <Route path=":teamId/members" element={<Members />} />
            <Route path=":teamId/stripe-info" element={<StripeInfo />} />
            <Route path=":teamId/invites" element={<Invites />} />
            <Route path=":teamId/referrals" element={<Referrals />} />
            <Route path=":teamId/whitelabel" element={<TeamWhiteLabel />} />

            <Route path="*" element={<Navigate to="/support/teams" />} />
          </Route>
          <Route path="cancellations" element={<Cancellations />} />
          <Route path="subscription-pauses" element={<SubscriptionPauses />} />
          <Route path="access-code">
            <Route path="" element={<AccessCode />} />
            <Route path=":codeId" element={<AccessCodeDetails />} />
          </Route>

          <Route
            path="feature-flags"
            element={isFeatureFlagsShow ? <FeatureFlags /> : <Navigate to="/no-permission" />}
          />
        </Route>

        <Route path="/manage-admins" element={isManageAdminsShow ? <Outlet /> : <Navigate to="/no-permission" />}>
          <Route path="" element={<ManageAdmins />} />
          <Route path=":userId" element={<AdminDetails />} />

          <Route path="*" element={<Navigate to="/manage-admins" />} />
        </Route>

        <Route path="/analytics/*" element={isAnalyticsShow ? <General /> : <Navigate to="/no-permission" />} />

        <Route path="/li-actions">
          <Route path="users-affected" element={<UsersAffected />} />
          <Route path="actions" element={<Actions />} />
          <Route path="actions-per-user" element={<ActionsPerUser />} />

          <Route index path="*" element={<Navigate to="/li-actions/users-affected" />} />
        </Route>

        <Route path="/no-permission" element={<NoPermission />} />
        <Route path="*" element={<Navigate to={availableRoute} />} />
      </Switch>
    </AppLayout>
  );
};

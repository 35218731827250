import { useMutation, useQueryClient } from 'react-query';

import { toggleUserBlock } from 'src/api';

export const useToggleUserBlock = (userId: number) => {
  const queryClient = useQueryClient();

  const { mutate, ...rest } = useMutation(['toggle-block', userId], () => toggleUserBlock(userId), {
    onSuccess: () => {
      queryClient
        .invalidateQueries(['get-user-info', userId], { refetchInactive: true, exact: false }, {})
        .catch(console.error);
    },
  });

  return { toggleBlock: mutate, ...rest };
};

import { PropsWithChildren, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import styled from '@emotion/styled';

import { COOKIE_TOKEN_NAME, MAIN_APP_URL } from 'src/constants';
import { useAppSelector } from 'src/hooks';
import { AppLoading } from 'src/layouts/_components';
import { useGetUserAccount } from 'src/reactQueries/users/useGetUserAccount';
import { updateCancellationsRequest, updateTeamsRequest, updateUsersRequest } from 'src/store';
import { SideBar } from './SideBar';

const AppLayoutContainer = styled.div`
  min-height: 100vh;
  background-color: #f2f2f2;
`;

const AppLayoutContent = styled.div`
  padding-left: var(--sidebar-width);
`;

export const AppLayout = ({ children }: PropsWithChildren) => {
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { usersRequest } = useAppSelector(({ user }) => user);
  const { teamsRequest } = useAppSelector(({ team }) => team);
  const { cancellationsRequest } = useAppSelector(({ cancellation }) => cancellation);
  const isAuth = cookies.get(COOKIE_TOKEN_NAME);

  const { isLoading, error } = useGetUserAccount();

  useEffect(() => {
    if (searchParams.get('saveSearchParams')) {
      searchParams.delete('saveSearchParams');
      const userParams = localStorage.getItem('user-params') || '';
      const teamParams = localStorage.getItem('team-params') || '';
      const cancellationParams = localStorage.getItem('cancellation-params') || '';

      setSearchParams(searchParams);
      localStorage.removeItem('user-params');
      localStorage.removeItem('team-params');
      localStorage.removeItem('cancellation-params');
      dispatch(updateUsersRequest(JSON.parse(userParams)));
      dispatch(updateTeamsRequest(JSON.parse(teamParams)));
      dispatch(updateCancellationsRequest(JSON.parse(cancellationParams)));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('user-params', JSON.stringify(usersRequest));
  }, [usersRequest]);

  useEffect(() => {
    localStorage.setItem('team-params', JSON.stringify(teamsRequest));
  }, [teamsRequest]);

  useEffect(() => {
    localStorage.setItem('cancellation-params', JSON.stringify(cancellationsRequest));
  }, [cancellationsRequest]);

  if (!isAuth || error) {
    location.assign(MAIN_APP_URL);

    return null;
  }

  if (isLoading) {
    return <AppLoading />;
  }

  return (
    <AppLayoutContainer>
      <SideBar />
      <AppLayoutContent>{children}</AppLayoutContent>
    </AppLayoutContainer>
  );
};

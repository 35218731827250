import { CSSProperties, MouseEvent, PropsWithChildren, ReactNode } from 'react';
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import MuiTableCell, { TableCellProps } from '@mui/material/TableCell';

import { Tooltip } from 'src/components';
import { TJustifyContent, TWhiteSpace } from 'src/types';

const StyledTableCell = styled(MuiTableCell)`
  padding: 10px;
`;

const InnerContent = styled(Box, {
  shouldForwardProp: (prop) => isPropValid(prop),
})<{ whiteSpace: string }>`
  display: inline-block;
  font-family: 'ProximaSoft', sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  white-space: ${({ whiteSpace }) => whiteSpace};
  text-overflow: ellipsis;
  overflow: hidden;
  color: #2f3a3e;
`;

interface ITableCellProps extends TableCellProps {
  tooltip?: ReactNode;
  tooltipPlacement?: 'top' | 'left';
  preventClick?: boolean;
  contentStyle?: CSSProperties;
  justifyContent?: TJustifyContent;
  maxWidth?: string;
  whiteSpace?: TWhiteSpace;
}

export const TableCell = ({
  children,
  tooltip,
  tooltipPlacement = 'top',
  preventClick,
  contentStyle,
  justifyContent,
  maxWidth,
  whiteSpace = 'nowrap',
  ...restProps
}: PropsWithChildren<ITableCellProps>) => {
  return (
    <StyledTableCell variant="body" {...restProps}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent={justifyContent}
        maxWidth={maxWidth}
        {...(preventClick && { onClick: (event: MouseEvent<HTMLDivElement>) => event.stopPropagation() })}
      >
        <Tooltip title={tooltip} placement={tooltipPlacement}>
          <InnerContent maxWidth={maxWidth} whiteSpace={whiteSpace} style={contentStyle}>
            {children}
          </InnerContent>
        </Tooltip>
      </Box>
    </StyledTableCell>
  );
};

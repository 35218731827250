import { reduce, uniqBy } from 'lodash';
import { useMemo } from 'react';
import styled from '@emotion/styled';

import {
  Block,
  BlockHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Typography,
} from 'src/components';
import { Plan } from 'src/enums';
import { separateNumWithComma, sortByOrder } from 'src/helpers';
import { useGetUsersSummary } from 'src/reactQueries';

const StyledTable = styled(Table)`
  border-collapse: separate;
`;

const StyledTableHeadCell = styled(TableHeadCell)`
  padding: 14px 10px !important;
`;

const labels: Record<string, string> = {
  [Plan.BASIC]: 'Basic Users',
  [Plan.PRO]: 'Pro Users',
  [Plan.TEAMS]: 'Teams Users',
  [Plan.INDIVIDUAL]: 'Individual Users',
  [Plan.STARTER]: 'Starter Users',
  [Plan.PERSONAL]: 'Personal Users',
  [Plan.BUSINESS]: 'Business Users',
  [Plan.ENTERPRISE]: 'Enterprise Users',
  [Plan.AGENCY]: 'Agency Users',
  LTD: 'LTD Users',
  total: 'Total',
};

const emptySummary = Object.keys(labels).map((label) => ({
  label,
  customers: 0,
  pausedCustomers: 0,
  pausedUsers: 0,
  users: 0,
}));

export const Subscriptions = () => {
  const { usersSummary = [], isLoading } = useGetUsersSummary();

  const totalUsersSummary = useMemo(() => {
    const totalSummary = reduce(
      usersSummary,
      (acc, item) => {
        if (labels[item.label] && item.label !== 'LTD') {
          acc.customers += item.customers || 0;
          acc.pausedCustomers += item.pausedCustomers || 0;
          acc.pausedUsers += item.pausedUsers || 0;
          acc.users += item.users || 0;
        }

        return acc;
      },
      { label: 'total', customers: 0, pausedCustomers: 0, pausedUsers: 0, users: 0 },
    );

    return sortByOrder(uniqBy([...usersSummary, totalSummary, ...emptySummary], 'label'), 'label', Object.keys(labels));
  }, [usersSummary]);

  return (
    <Block>
      <BlockHeader py="0" height="64px" bgcolor="white">
        <Typography medium fontSize="20px">
          Subscriptions
        </Typography>
      </BlockHeader>
      <StyledTable
        placeholder={!usersSummary?.length && 'No user summary found'}
        processing={isLoading}
        disabled={isLoading}
      >
        <TableHead secondary>
          <TableRow>
            <StyledTableHeadCell secondary />
            <StyledTableHeadCell justifyContent="flex-end" secondary>
              Customers
            </StyledTableHeadCell>
            <StyledTableHeadCell justifyContent="flex-end" secondary>
              Users
            </StyledTableHeadCell>
            <StyledTableHeadCell justifyContent="flex-end" secondary>
              Paused Customers
            </StyledTableHeadCell>
            <StyledTableHeadCell justifyContent="flex-end" secondary>
              Paused Users
            </StyledTableHeadCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {totalUsersSummary.map(
            ({ label, customers, pausedCustomers, pausedUsers, users }) =>
              labels[label] && (
                <TableRow key={label}>
                  <TableCell>{labels[label]}</TableCell>
                  <TableCell justifyContent="flex-end">
                    <Typography>{separateNumWithComma(customers)}</Typography>
                  </TableCell>
                  <TableCell justifyContent="flex-end">
                    <Typography>{separateNumWithComma(users)}</Typography>
                  </TableCell>
                  <TableCell justifyContent="flex-end">
                    <Typography>{separateNumWithComma(pausedCustomers)}</Typography>
                  </TableCell>
                  <TableCell justifyContent="flex-end">
                    <Typography>{separateNumWithComma(pausedUsers) || 0}</Typography>
                  </TableCell>
                </TableRow>
              ),
          )}
        </TableBody>
      </StyledTable>
    </Block>
  );
};

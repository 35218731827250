import { combineReducers, configureStore, PreloadedState } from '@reduxjs/toolkit';

import cancellation from './cancellation.slice';
import modal from './modal.slice';
import subscriptionPauses from './subscription-pauses.slice';
import team from './team.slice';
import toast from './toast.slice';
import user from './user.slice';

export const rootReducer = combineReducers({ modal, toast, user, team, cancellation, subscriptionPauses });

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
};

export * from './cancellation.slice';
export * from './modal.slice';
export * from './subscription-pauses.slice';
export * from './team.slice';
export * from './toast.slice';
export * from './user.slice';

export type RootState = ReturnType<typeof store.getState>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = typeof store.dispatch;

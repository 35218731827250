import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useDispatch } from 'react-redux';

dayjs.extend(utc);

import {
  Link,
  ShowMoreText,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TablePagination,
  TableRow,
  Typography,
} from 'src/components';
import { resolveMembership } from 'src/helpers';
import { useAppSelector } from 'src/hooks';
import { useGetPauseReasons } from 'src/reactQueries';
import { updateSubscriptionPausesRequest } from 'src/store';
import { ITablePagination, TCancellationsSortFields, TSortOrder } from 'src/types';

export const PauseReasonsTable = () => {
  const dispatch = useDispatch();
  const { sortField, sortOrder, fromDate, page, perPage, toDate, reason, search } = useAppSelector(
    ({ subscriptionPauses }) => subscriptionPauses.subscriptionPausesRequest,
  );

  const { data, isLoading } = useGetPauseReasons({
    fromDate,
    toDate,
    sortField,
    sortOrder,
    page,
    perPage,
    reason,
    search,
  });
  const { pauseReasons = [], total } = data ?? {};

  const sortingProps = {
    sortField,
    sortOrder,
    onSortFieldChange: (sortField: TCancellationsSortFields, sortOrder: TSortOrder) =>
      dispatch(updateSubscriptionPausesRequest({ sortField, sortOrder })),
  };

  const paginationChangeHandler = (pagination: ITablePagination) =>
    dispatch(updateSubscriptionPausesRequest(pagination));

  return (
    <>
      <Table placeholder={!total && 'No paused subscriptions found'} processing={isLoading} disabled={isLoading}>
        <TableHead>
          <TableRow>
            <TableHeadCell secondary width="110px" name="createdAt" sorting={sortingProps}>
              Date
            </TableHeadCell>
            <TableHeadCell secondary width="180px" name="userName" sorting={sortingProps}>
              User
            </TableHeadCell>
            <TableHeadCell secondary width="150px" name="teamName" sorting={sortingProps}>
              Team
            </TableHeadCell>
            <TableHeadCell secondary width="150px" name="plan" sorting={sortingProps}>
              Plan paused
            </TableHeadCell>
            <TableHeadCell secondary width="150px" name="seats" sorting={sortingProps}>
              Number of seats
            </TableHeadCell>
            <TableHeadCell secondary width="210px" name="reason" sorting={sortingProps}>
              Reason
            </TableHeadCell>
            <TableHeadCell secondary name="description" sorting={sortingProps}>
              More
            </TableHeadCell>
            <TableHeadCell secondary width="160px" name="membership" sorting={sortingProps}>
              LinkedIn Membership
            </TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {pauseReasons.map(
            ({
              createdAt,
              userName,
              userId,
              teamId,
              teamName,
              description,
              hasLinkedInPremium,
              hasLinkedInSalesNavigator,
              reason,
              plan,
              seats,
            }) => (
              <TableRow key={createdAt} hoverBgColor="#ebecf2">
                <TableCell>{dayjs(createdAt).utc().format('DD MMM, YYYY')}</TableCell>

                <TableCell>
                  <Link to={`/support/users/${userId}/details`}>{userName}</Link>
                </TableCell>
                <TableCell>
                  <Link to={`/support/teams/${teamId}/general-information`}>{teamName}</Link>
                </TableCell>

                <TableCell>
                  <Typography color="#002F53">{plan}</Typography>
                </TableCell>

                <TableCell>
                  <Typography color="#002F53">{seats}</Typography>
                </TableCell>

                <TableCell>
                  <Typography color="#002F53">{reason}</Typography>
                </TableCell>

                <TableCell whiteSpace="normal" contentStyle={{ width: '100%' }}>
                  <ShowMoreText text={description} />
                </TableCell>

                <TableCell justifyContent="center">
                  {resolveMembership({ hasLinkedInPremium, hasLinkedInSalesNavigator })}
                </TableCell>
              </TableRow>
            ),
          )}
        </TableBody>
      </Table>
      <TablePagination page={page} perPage={perPage} total={total} onPaginationChange={paginationChangeHandler} />
    </>
  );
};

import { CSSProperties } from 'react';
import styled from '@emotion/styled';

import { ReactComponent as CloseIcon } from 'src/assets/icons/close-icon.svg';
import errorIcon from 'src/assets/icons/toast/error.png';
import infoIcon from 'src/assets/icons/toast/info.png';
import successIcon from 'src/assets/icons/toast/success.png';
import { TToastType } from 'src/types';

const Container = styled.div`
  align-items: center;
  background-color: #333333;
  border-radius: 2px;
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  color: white;
  display: flex;
  font-size: 11px;
  justify-content: space-between;
  position: relative;
  width: 300px;
`;

const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 10px 60px 10px 10px;
  width: 100%;
`;

const IconPlaceholder = styled.div`
  display: flex;
  justify-content: center;
  width: 50px;
`;

const Message = styled.div`
  flex: 3;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
`;

const CloseButton = styled.div`
  align-items: center;
  background-color: #444;
  border-radius: 0 2px 2px 0;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  min-width: 50px;
  position: absolute;
  right: 0;
  top: 0;
`;

const Icon = styled.img`
  width: 32px;
  height: 32px;
`;

const getToastIcon = (type: TToastType) => {
  switch (type) {
    case 'error':
      return errorIcon;
    case 'success':
      return successIcon;
    default:
      return infoIcon;
  }
};

const ToastTemplate = ({
  message,
  type,
  style,
  close,
}: {
  message: string;
  type: TToastType;
  close?: () => void;
  style?: CSSProperties;
}) => {
  const icon = getToastIcon(type);

  return (
    <Container style={style}>
      <ContentWrapper>
        <IconPlaceholder>
          <Icon src={icon} />
        </IconPlaceholder>
        <Message>{message}</Message>
      </ContentWrapper>
      <CloseButton onClick={close}>
        <CloseIcon color="#ffffff" />
      </CloseButton>
    </Container>
  );
};

export default ToastTemplate;

import dayjs from 'dayjs';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IGetPauseReasonsRequest } from 'src/types';

export const DEFAULT_SUBSCRIPTION_PAUSES_REQUEST: Required<IGetPauseReasonsRequest> = {
  page: 1,
  perPage: 10,
  fromDate: dayjs().format('YYYY-MM-01'),
  toDate: dayjs().format('YYYY-MM-DD'),
  sortField: 'createdAt',
  sortOrder: 'DESC',
  search: '',
  reason: null,
};

const initialState = {
  subscriptionPausesRequest: DEFAULT_SUBSCRIPTION_PAUSES_REQUEST,
};

export const subscriptionPausesSlice = createSlice({
  name: 'cancellation',
  initialState,
  reducers: {
    updateSubscriptionPausesRequest: (state, action: PayloadAction<Partial<IGetPauseReasonsRequest>>) => {
      state.subscriptionPausesRequest = { ...state.subscriptionPausesRequest, ...action.payload };
    },
  },
});

export const { updateSubscriptionPausesRequest } = subscriptionPausesSlice.actions;

export default subscriptionPausesSlice.reducer;

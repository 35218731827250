import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import { Block, Button, InfoLabel, InfoLabelWrapper } from 'src/components';
import { UserDetailsLayout } from 'src/containers';
import { ActionsTable } from 'src/containers/Support/_components';
import { useGetUserEmailInfo, useRemoveUserEmailIntegration } from 'src/reactQueries';

export const EmailIntegration = () => {
  const { userId } = useParams();
  const USER_ID = Number(userId);

  const { emailInfo } = useGetUserEmailInfo(USER_ID);
  const { activeCampaigns, emailsSent, gmailEmail, outlookEmail, smtpEmail } = emailInfo ?? {};

  const { removeEmailIntegration, isLoading: isRemovingIntegration } = useRemoveUserEmailIntegration(USER_ID);

  const connectedEmail = gmailEmail || outlookEmail || smtpEmail;
  const isConnected = !!connectedEmail;

  const activeIntegration = useMemo(() => {
    if (gmailEmail) return 'Google';
    if (outlookEmail) return 'Outlook';
    if (smtpEmail) return 'SMTP';
  }, [gmailEmail, outlookEmail, smtpEmail]);

  return (
    <UserDetailsLayout>
      <Block p="24px">
        <InfoLabelWrapper>
          <InfoLabel label="Connected">
            <Box display="flex" alignItems="center" justifyContent="flex-start" gap="10px">
              {isConnected ? 'Yes' : 'No'}{' '}
              {isConnected && (
                <Button
                  disabled={isRemovingIntegration}
                  variant="whiteBlue"
                  size={12}
                  lineHeight="14px"
                  onClick={() => removeEmailIntegration()}
                >
                  Remove integration
                </Button>
              )}
            </Box>
          </InfoLabel>
          <InfoLabel label="Gmail/Outlook/SMTP Integration" noneValue={!isConnected}>
            {connectedEmail} ({activeIntegration})
          </InfoLabel>
          <InfoLabel disablePlaceholder label="Number of Emails Sent">
            {emailsSent || 0}
          </InfoLabel>
          <InfoLabel disablePlaceholder label="Email Active Campaigns">
            {activeCampaigns || 0}
          </InfoLabel>
        </InfoLabelWrapper>
      </Block>
      <ActionsTable title="Last 10 Email Actions" type="email" userId={USER_ID} />
    </UserDetailsLayout>
  );
};

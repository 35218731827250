import { Link, LinkProps } from 'react-router-dom';
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';

const StyledLink = styled(Link, {
  shouldForwardProp: (prop) => isPropValid(prop),
})<{ isActive?: boolean; disabled?: boolean }>`
  font-size: 14px;
  color: ${(props) => (props.isActive ? '#272a79' : '#8788b6')};
  line-height: 44px;
  margin-right: 20px;
  font-weight: ${(props) => (props.isActive ? '600' : '400')};

  ${(props) => props.isActive && 'border-bottom: 2px solid #2361fc;'}

  ${(props) => props.disabled && 'color: #d3d3d3; cursor: not-allowed !important;'}
`;

interface IHeaderTabLink extends LinkProps {
  pathname?: string;
}

export const HeaderTabLink = ({ children, pathname, to, hidden, ...restProps }: IHeaderTabLink) => {
  if (hidden) {
    return null;
  }

  return (
    <StyledLink to={to} isActive={pathname === to} {...restProps}>
      {children}
    </StyledLink>
  );
};

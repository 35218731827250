import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import { Button, Input, ModalBody, ModalFooter, Tooltip, Typography } from 'src/components';
import { useAppSelector } from 'src/hooks';
import { closeModal } from 'src/store/modal.slice';

export const ModalCreateAccessCode = () => {
  const dispatch = useDispatch();
  const { onConfirm } = useAppSelector((state) => state.modal);
  const [codeName, setCodeName] = useState('');
  const [acceleratorName, setAcceleratorName] = useState('');

  const confirmHandler = () => {
    onConfirm?.({ accelerator: acceleratorName, code: codeName });
    dispatch(closeModal());
  };

  const closeHandler = () => {
    dispatch(closeModal());
  };

  return (
    <>
      <ModalBody>
        <Box display="grid" gridTemplateColumns="1fr 1fr" gap="20px">
          <Box>
            <Typography mb="8px">
              Access Code Name{' '}
              <Tooltip title="Access code (the code the user will input to redeem 90 days trial)">
                <FontAwesomeIcon icon={faQuestionCircle} />
              </Tooltip>
            </Typography>
            <Input
              name="code-name"
              placeholder="Enter name"
              value={codeName}
              onChange={(event) => setCodeName(event.target.value)}
            />
          </Box>
          <Box>
            <Typography mb="8px">
              Accelerator Name{' '}
              <Tooltip title="Accelerator Name (for analytics)">
                <FontAwesomeIcon icon={faQuestionCircle} />
              </Tooltip>
            </Typography>
            <Input
              name="code-name"
              placeholder="Enter name"
              value={acceleratorName}
              onChange={(event) => setAcceleratorName(event.target.value)}
            />
          </Box>
        </Box>
      </ModalBody>
      <ModalFooter alignToTheEnd>
        <Button onClick={closeHandler} variant="whiteBlue">
          Cancel
        </Button>
        <Button onClick={confirmHandler}>Create</Button>
      </ModalFooter>
    </>
  );
};

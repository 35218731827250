import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import { Block, InfoLabel, Select, SmallCard, Spinner } from 'src/components';
import { useEnableUserFeatureFlag, useGetFeatureFlags, useGetUserFeatureFlags } from 'src/reactQueries';
import { openModal } from 'src/store';
import { ISelectOption } from 'src/types';
import { UserDetailsLayout } from './UserDetailsLayout';

export const FeatureFlagsInfo = () => {
  const dispatch = useDispatch();

  const { userId } = useParams();
  const USER_ID = Number(userId);

  const { data: userFeatureFlags, isLoading: isLoadingUserFeatureFlags } = useGetUserFeatureFlags(USER_ID);
  const { data: featureFlags, isLoading: isLoadingFeatureFlags } = useGetFeatureFlags();

  const { enableUserFeatureFlag, isLoading } = useEnableUserFeatureFlag(USER_ID);

  const options = useMemo(() => {
    return (
      featureFlags
        ?.filter(({ uniqueKey }) => !userFeatureFlags?.some((flag) => flag.uniqueKey === uniqueKey))
        .map(({ id, name, uniqueKey }) => ({ value: String(id), label: `${name} (${uniqueKey})` })) || []
    );
  }, [featureFlags, userFeatureFlags]);

  const onSelectChange = ({ value }: ISelectOption) => {
    dispatch(
      openModal({
        headerText: 'Enable feature flag.',
        descriptionText: 'Are you sure you want to enable this feature flag?',
        onConfirm: () => enableUserFeatureFlag({ featureId: Number(value), enabled: true }),
      }),
    );
  };

  const onFeatureFlagClick = (id: number) => {
    if (isLoading) return;

    dispatch(
      openModal({
        headerText: 'Disable feature flag.',
        descriptionText: 'Are you sure you want to disable this feature flag?',
        onConfirm: () => enableUserFeatureFlag({ featureId: Number(id), enabled: false }),
      }),
    );
  };

  return (
    <UserDetailsLayout>
      {isLoadingUserFeatureFlags || isLoadingFeatureFlags ? (
        <Spinner />
      ) : (
        <Block p="24px">
          <InfoLabel label="Available feature flags">
            <Select options={options} onChange={onSelectChange} isDisabled={isLoading} />
          </InfoLabel>

          <InfoLabel label="Enabled feature flags" mt="20px">
            <Box display="flex" flexWrap="wrap" gap="16px" mt="4px">
              {userFeatureFlags?.length
                ? userFeatureFlags?.map(({ name, uniqueKey, id }) => (
                    <SmallCard
                      key={id}
                      label={uniqueKey}
                      minWidth="128px"
                      onClick={() => onFeatureFlagClick(id)}
                      sx={{
                        cursor: isLoading ? 'not-allowed' : 'pointer',
                        transition: '0.2s',
                        '&:hover': { opacity: '0.7' },
                      }}
                      addCrossIcon
                    >
                      {name}
                    </SmallCard>
                  ))
                : '-'}
            </Box>
          </InfoLabel>
        </Block>
      )}
    </UserDetailsLayout>
  );
};

import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getTeamStripeInfo } from 'src/api';
import { IGetTeamStripeInfoReq, IGetTeamStripeInfoRes } from 'src/types';

export const useGetTeamStripeInfo = <TData extends IGetTeamStripeInfoRes>(
  teamId: number,
  params: IGetTeamStripeInfoReq,
  options?: UseQueryOptions<IGetTeamStripeInfoRes, AxiosError, TData>,
) => {
  const { data, ...rest } = useQuery<IGetTeamStripeInfoRes, AxiosError, TData>(
    ['get-team-stripe-info', teamId, JSON.stringify(params)],
    () => getTeamStripeInfo(teamId, params),
    options,
  );

  return { teamStripeInfo: data, ...rest };
};

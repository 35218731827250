import styled from '@emotion/styled';
import { Breadcrumbs as BreadcrumbsMui, BreadcrumbsProps } from '@mui/material';

const StyledBreadcrumbs = styled(BreadcrumbsMui)`
  & li * {
    color: #8c8c8c;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.02em;
  }
  & li:last-of-type * {
    color: #000000;
  }

  & .MuiBreadcrumbs-separator {
    color: #8c8c8c;
  }
`;

export const Breadcrumbs = (props: BreadcrumbsProps) => {
  return <StyledBreadcrumbs {...props} />;
};

import { isUndefined } from 'lodash';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { faExternalLinkAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import { Block, BlockFooter, Button, InfoLabel, InfoLabelWrapper } from 'src/components';
import { Link } from 'src/components/Link';
import { UserDetailsLayout } from 'src/containers';
import { ModalTypes } from 'src/enums';
import { useDeleteTeamMember, useGetUserInfo } from 'src/reactQueries';
import { useGetUserTeamInfo } from 'src/reactQueries/users/useGetUserTeamInfo';
import { openModal } from 'src/store';

const NO_TEAM_MESSAGE = 'User does not have a team';

export const TeamInformation = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const USER_ID = Number(userId);

  const { userInfo } = useGetUserInfo(USER_ID);
  const { teamInfo } = useGetUserTeamInfo(USER_ID);
  const {
    companyName,
    isTrialExtended,
    phone,
    plan,
    recentTeams,
    role,
    seats,
    teamId,
    teamName,
    referralTeam,
    referredTeams,
    referralDiscount,
  } = teamInfo ?? {};

  const { deleteMember } = useDeleteTeamMember(teamId ?? -1);

  const onRemoveFromTeam = () =>
    dispatch(
      openModal({
        headerText: `Remove ${userInfo?.name} from ${teamName} team`,
        descriptionText: `Are you sure you want to remove ${userInfo?.name} from the ${teamName} team?`,
        onConfirm: () => deleteMember(USER_ID),
      }),
    );

  const onExtend = () =>
    dispatch(
      openModal({
        type: ModalTypes.EXTEND_TRIAL,
        headerText: 'Extend trial',
        params: {
          teamId: teamId,
        },
      }),
    );

  return (
    <UserDetailsLayout>
      <Block>
        <Box p="24px">
          <InfoLabelWrapper>
            <InfoLabel label="Current Active Team" noneValue={!teamName}>
              <Link to={`/support/teams/${teamId}/general-information`}>{teamName}</Link>
            </InfoLabel>
            <InfoLabel label="Removed Teams" noneValue={!recentTeams?.length}>
              {recentTeams?.map(({ teamName, teamId }) => (
                <Link key={teamId} to={`/support/teams/${teamId}/general-information`}>
                  {teamName}
                </Link>
              ))}
            </InfoLabel>
            <InfoLabel label="Current Plan">{plan}</InfoLabel>
            <InfoLabel label="Role">{role}</InfoLabel>
            <InfoLabel disablePlaceholder label="Number of Seats">
              {seats || 0}
            </InfoLabel>
            <InfoLabel label="Is trial extended?" noneValue={isUndefined(isTrialExtended)}>
              {isTrialExtended ? 'Yes' : 'No'}
            </InfoLabel>
            <InfoLabel label="Company">{companyName}</InfoLabel>
            <InfoLabel label="Phone #">{phone}</InfoLabel>
            <InfoLabel label="Referred by" noneValue={!referralTeam?.teamId}>
              <Link to={`/support/teams/${referralTeam?.teamId}/general-information`}>
                {referralTeam?.teamName}
                {referralTeam?.isDeactivated && ' (deactivated)'}
              </Link>
            </InfoLabel>
            <InfoLabel label="Referral teams" noneValue={!referredTeams?.length}>
              {referredTeams?.map(({ teamName, teamId, isDeactivated }) => {
                return (
                  <Link key={teamId} to={`/support/teams/${teamId}/general-information`}>
                    {teamName}
                    {isDeactivated && ' (deactivated)'}
                  </Link>
                );
              })}
            </InfoLabel>
            <InfoLabel label="Referral discount">{referralDiscount}</InfoLabel>
            {/*<InfoLabel label="Industry">{industry}</InfoLabel>*/}
          </InfoLabelWrapper>
        </Box>
        <BlockFooter justifyContent="space-between">
          <Box display="flex" gap="48px">
            <Link
              fontSize="16px"
              color="#337ab7"
              underline={false}
              disabled={!teamId}
              tooltip={!teamId && NO_TEAM_MESSAGE}
              onClick={onExtend}
            >
              Extend Trial
            </Link>
            <Link
              fontSize="16px"
              color="#337ab7"
              underline={false}
              disabled={!teamId}
              tooltip={!teamId && NO_TEAM_MESSAGE}
              to={`/support/teams/${teamId}/stripe-info`}
            >
              Stripe Subs
            </Link>

            <Link
              fontSize="16px"
              color="#337ab7"
              underline={false}
              disabled={!teamId}
              tooltip={!teamId && NO_TEAM_MESSAGE}
              to={`/support/teams/${teamId}/general-information`}
            >
              <FontAwesomeIcon icon={faExternalLinkAlt} size="xs" /> See Team Details
            </Link>
          </Box>
          <Button
            disabled={!teamId}
            tooltip={!teamId && NO_TEAM_MESSAGE}
            variant="red"
            gap="10px"
            onClick={onRemoveFromTeam}
          >
            <FontAwesomeIcon icon={faTrashAlt} /> Remove from the team
          </Button>
        </BlockFooter>
      </Block>
    </UserDetailsLayout>
  );
};

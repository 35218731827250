import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getTeamInvites } from 'src/api';
import { IGetTeamInvitesReq, IGetTeamInvitesRes } from 'src/types';

export const useGetTeamInvites = <TData extends IGetTeamInvitesRes>(
  teamId: number,
  params: IGetTeamInvitesReq,
  options?: UseQueryOptions<IGetTeamInvitesRes, AxiosError, TData>,
) => {
  const { data, ...rest } = useQuery<IGetTeamInvitesRes, AxiosError, TData>(
    ['get-team-invites', teamId, JSON.stringify(params)],
    () => getTeamInvites(teamId, params),
    options,
  );

  return { teamInvites: data, ...rest };
};

import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getUserFeatureFlags } from 'src/api/';
import { IFeatureFlag } from 'src/types';

export const useGetUserFeatureFlags = <TData extends IFeatureFlag[]>(
  userId: number,
  options?: UseQueryOptions<IFeatureFlag[], AxiosError, TData>,
) => {
  return useQuery<IFeatureFlag[], AxiosError, TData>(
    ['get-user-feature-flags', userId],
    () => getUserFeatureFlags(userId),
    options,
  );
};

import dayjs from 'dayjs';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { faArrowRight, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import { useAppSelector } from 'src//hooks';
import { Block, Button, DataPicker, Input, Select, Typography } from 'src/components';
import { subscriptionPausesOptions } from 'src/constants';
import { SubscriptionPauseReasons } from 'src/enums';
import { updateSubscriptionPausesRequest } from 'src/store';
import { PauseReasonsStats } from './PauseReasonsStats';
import { PauseReasonsTable } from './PauseReasonsTable';
import { SubscriptionPauseDurationStats } from './SubscriptionPauseDurationStats';
import { SubscriptionPausesLayout } from './SubscriptionPausesLayout';

const StyledInput = styled(Input)`
  height: 34px;
  margin-top: 0;
`;

export const SubscriptionPauses = () => {
  const dispatch = useDispatch();

  const { fromDate, toDate } = useAppSelector(({ subscriptionPauses }) => subscriptionPauses.subscriptionPausesRequest);
  const [queryDateTo, setQueryDateTo] = useState(toDate);
  const [queryDateFrom, setQueryDateFrom] = useState(fromDate);
  const [searchInput, setSearchInput] = useState('');
  const [selectedReason, setSelectedReason] = useState<SubscriptionPauseReasons | 'all'>('all');

  const onApplyFilters = () =>
    dispatch(
      updateSubscriptionPausesRequest({
        toDate: queryDateTo,
        fromDate: queryDateFrom,
        search: searchInput,
        reason: selectedReason === 'all' ? null : selectedReason,
        page: 1,
      }),
    );

  return (
    <SubscriptionPausesLayout>
      <Block p="16px" display="flex" alignItems="center" gap="8px">
        <Typography whiteSpace="pre" fontSize="16px">
          Filter by Date:
        </Typography>
        <Box display="flex" alignItems="center" gap="20px">
          <DataPicker
            type="date"
            value={queryDateFrom}
            placeholder="from"
            max={queryDateTo}
            onChange={(e) => e.target.value && setQueryDateFrom(e.target.value)}
          />
          <FontAwesomeIcon icon={faArrowRight} />
          <DataPicker
            type="date"
            value={queryDateTo}
            placeholder="to"
            max={dayjs().format('YYYY-MM-DD')}
            min={queryDateFrom}
            onChange={(e) => e.target.value && setQueryDateTo(e.target.value)}
          />
        </Box>
        <Box display="flex" alignItems="center" gap="8px">
          <Typography>Reason:</Typography>
          <Select
            minWidth="170px"
            placeholder="Select reason"
            options={subscriptionPausesOptions}
            onChange={(nextValue) => setSelectedReason(nextValue.value)}
            value={selectedReason}
          />
        </Box>
        <StyledInput
          name="input"
          placeholder="Search by user name, email or ID"
          icon={faSearch}
          value={searchInput}
          onChange={(event) => setSearchInput(event.target.value)}
        />
        <Button size={22} onClick={onApplyFilters}>
          Apply
        </Button>
      </Block>
      <Box display="grid" gridTemplateColumns="1fr 1fr" gap="16px">
        <PauseReasonsStats />
        <SubscriptionPauseDurationStats />
      </Box>

      <PauseReasonsTable />
    </SubscriptionPausesLayout>
  );
};

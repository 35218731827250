import styled from '@emotion/styled';
import MuiTableHead, { TableHeadProps } from '@mui/material/TableHead';

import { propsBlackList } from 'src/helpers';

interface StyledMuiTableHeadProps {
  secondary: boolean;
  hidden: boolean;
}

const BLACK_LIST_PROPS = ['secondary', 'hidden'];
const StyledMuiTableHead = styled(MuiTableHead, propsBlackList(BLACK_LIST_PROPS))<StyledMuiTableHeadProps>`
  ${({ secondary }) => secondary && '  border-top: 1px solid #e0e0e0; background-color: #f9fbfc;'}
  ${({ hidden }) => hidden && 'display: none;'}
`;

interface ITableHeadProps extends TableHeadProps {
  secondary?: boolean;
  hidden?: boolean;
}

export const TableHead = ({ children, secondary = false, hidden = false, ...restProps }: ITableHeadProps) => {
  return (
    <StyledMuiTableHead secondary={secondary} hidden={hidden} {...restProps}>
      {children}
    </StyledMuiTableHead>
  );
};

import { useMemo, useState } from 'react';
import styled from '@emotion/styled';

import {
  Block,
  BlockHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Typography,
} from 'src/components';
import { separateNumWithComma, sortTable } from 'src/helpers';
import { useAppSelector } from 'src/hooks';
import { useGetSubscriptionPauseDurationStats } from 'src/reactQueries';
import { TSortOrder } from 'src/types';

const StyledTable = styled(Table)`
  border-collapse: separate;
`;

const StyledTableHeadCell = styled(TableHeadCell)`
  padding: 14px 10px !important;
`;

type TSortFields = 'seats' | 'value';

export const SubscriptionPauseDurationStats = () => {
  const { fromDate, toDate } = useAppSelector(({ subscriptionPauses }) => subscriptionPauses.subscriptionPausesRequest);

  const { durationStats = [], isLoading } = useGetSubscriptionPauseDurationStats({ toDate, fromDate });

  const [sortOrder, setSortOrder] = useState<TSortOrder>('DESC');
  const [sortField, setSortField] = useState<TSortFields>('value');

  const sortedStats = useMemo(
    () => sortTable(durationStats, sortField, sortOrder),
    [sortField, sortOrder, durationStats],
  );

  const sortingProps = {
    sortField,
    sortOrder,
    onSortFieldChange: (field: TSortFields, order: TSortOrder) => {
      setSortField(field);
      setSortOrder(order);
    },
  };

  return (
    <Block>
      <BlockHeader py="0" height="64px" bgcolor="white">
        <Typography medium fontSize="20px">
          Pause Duration stats
        </Typography>
      </BlockHeader>
      <StyledTable
        placeholder={!durationStats?.length && 'No user summary found'}
        processing={isLoading}
        disabled={isLoading}
      >
        <TableHead secondary>
          <TableRow>
            <StyledTableHeadCell secondary width="70%" />
            <StyledTableHeadCell name="teams" sorting={sortingProps} secondary justifyContent="flex-end">
              Customers
            </StyledTableHeadCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {sortedStats.map(({ teams, duration }) => (
            <TableRow key={duration}>
              <TableCell>
                <Typography>{duration / (24 * 60 * 60)} days</Typography>
              </TableCell>
              <TableCell justifyContent="flex-end">
                <Typography>{separateNumWithComma(teams)}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </StyledTable>
    </Block>
  );
};

import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import { FormInput } from 'src/components';
import { Accordion, AccordionDetails, AccordionSummary, ColorInput } from 'src/containers/Support/_components';
import { useGetTeamWhiteLabel } from 'src/reactQueries';
import { AlfPreview } from './AlfPreview';

interface IBrandingAttachment {
  url?: string;
  name?: string;
  file?: File;
  isRequired?: boolean;
}

export interface IBrandingForm {
  companyName: string;
  favicon: IBrandingAttachment;
  logo: IBrandingAttachment;
  logoIcon: IBrandingAttachment;
  menuFontColor: string;
  titleColor: string;
  accentColor: string;
  secondaryColor: string;
}

const getNameFromUrl = (url?: string) =>
  url?.replace('https://di1x3nx76kt7l.cloudfront.net/', '').replace(/-\d+\./, '.');

export const Branding = () => {
  const { teamId } = useParams();
  const TEAM_ID = Number(teamId);
  const [expanded, setExpanded] = useState(false);

  const { whitelabel } = useGetTeamWhiteLabel(TEAM_ID);
  const { companyName, favicon, logo, logoIcon, theme } = whitelabel ?? {};

  const defaultValues: Partial<IBrandingForm> = {
    companyName: companyName,
    logo: {
      url: logo,
      isRequired: !logo,
      name: getNameFromUrl(logo),
    },
    favicon: {
      url: favicon,
      isRequired: !favicon,
      name: getNameFromUrl(favicon),
    },
    logoIcon: {
      url: logoIcon,
      isRequired: !logoIcon,
      name: getNameFromUrl(logoIcon),
    },
    accentColor: theme?.accentColor || '#f47632',
    menuFontColor: theme?.menuFontColor || '#002f53',
    secondaryColor: theme?.secondaryColor || '#2361fc',
    titleColor: theme?.titleColor || '#262a79',
  };

  const form = useForm<IBrandingForm>({ defaultValues });
  const { register, watch, setValue } = form;

  return (
    <Accordion expanded={expanded} onChange={() => setExpanded((prev) => !prev)}>
      <AccordionSummary expanded={expanded} title="Branding" status={whitelabel?.companyName ? 'applied' : 'pending'} />

      <AccordionDetails>
        <Box display="flex" gap="50px" justifyContent="space-between">
          <Box display="flex" flexDirection="column">
            <Box maxWidth="388px">
              <FormInput
                label="Company Name"
                name="companyName"
                placeholder="MeetAlfred"
                description="We'll use this name to display company name on the app."
                register={register}
                disabled
              />
            </Box>

            <Box mt="24px" minWidth="800px">
              <Box display="grid" gridTemplateColumns="1fr 1fr 1fr 1fr" gap="24px">
                <ColorInput
                  label="Menu Color"
                  color={watch('menuFontColor')}
                  onColorChange={(color) => setValue('menuFontColor', color)}
                />
                <ColorInput
                  label="Title Color"
                  color={watch('titleColor')}
                  onColorChange={(color) => setValue('titleColor', color)}
                />
                <ColorInput
                  label="Accent Color"
                  color={watch('accentColor')}
                  onColorChange={(color) => setValue('accentColor', color)}
                />
                <ColorInput
                  label="Secondary Color"
                  color={watch('secondaryColor')}
                  onColorChange={(color) => setValue('secondaryColor', color)}
                />
              </Box>
            </Box>
          </Box>
          <AlfPreview watch={watch} />
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

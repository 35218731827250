import dayjs from 'dayjs';
import { useState } from 'react';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import { Block, BlockHeader, DataPicker, InfoLabel, InfoLabelWrapper, Typography } from 'src/components';
import { separateNumWithComma } from 'src/helpers';
import { useGetAnalyticSummary, useGetSubscribersAnalytic } from 'src/reactQueries';

export const GeneralStats = () => {
  const today = dayjs().format('YYYY-MM-DD');
  const firstMonthDay = dayjs().format('YYYY-MM-01');
  const [dateFrom, setDateFrom] = useState(firstMonthDay);
  const [dateTo, setDateTo] = useState(today);

  const { subscribersAnalytic } = useGetSubscribersAnalytic();
  const { summary } = useGetAnalyticSummary({
    filter: 'custom',
    fromDate: dateFrom,
    toDate: dateTo,
  });

  return (
    <Box display="grid" gridTemplateColumns="1fr 2fr" gap="18px">
      <Block>
        <BlockHeader bgcolor="#fff" py="0" height="64px">
          <Typography medium fontSize="20px">
            Subscribers
          </Typography>
        </BlockHeader>
        <InfoLabelWrapper columns={2} p="16px">
          <InfoLabel reverse label="Customers">
            <Typography bold fontSize="36px">
              {separateNumWithComma(subscribersAnalytic?.customers || 0)}
            </Typography>
          </InfoLabel>
          <InfoLabel reverse label="Users">
            <Typography bold fontSize="36px">
              {separateNumWithComma(subscribersAnalytic?.users || 0)}
            </Typography>
          </InfoLabel>
        </InfoLabelWrapper>
      </Block>
      <Block>
        <BlockHeader bgcolor="#fff" py="0" height="64px">
          <Typography medium fontSize="20px">
            Trials & Cancelations
          </Typography>
          <Box display="flex" gap="25px">
            <Box display="flex" alignItems="center" gap="8px">
              <DataPicker
                type="date"
                value={dateFrom}
                placeholder="from"
                max={dateTo}
                onChange={(e) => e.target.value && setDateFrom(e.target.value)}
              />
              <FontAwesomeIcon icon={faArrowRight} />
              <DataPicker
                type="date"
                value={dateTo}
                placeholder="to"
                max={today}
                min={dateFrom}
                onChange={(e) => e.target.value && setDateTo(e.target.value)}
              />
            </Box>
          </Box>
        </BlockHeader>
        <InfoLabelWrapper columns={5} p="16px">
          <InfoLabel reverse label="Total Trial Users">
            <Typography bold fontSize="36px">
              {separateNumWithComma(summary?.trial) || 0}
            </Typography>
          </InfoLabel>
          <InfoLabel reverse label="Total Customers">
            <Box display="flex" alignItems="flex-end" gap="10px">
              <Typography bold fontSize="36px">
                {separateNumWithComma(summary?.customers) || 0}
              </Typography>
            </Box>
          </InfoLabel>
          <InfoLabel reverse label="Total Users">
            <Typography bold fontSize="36px">
              {separateNumWithComma(summary?.users) || 0}
            </Typography>
          </InfoLabel>
          <InfoLabel reverse label="Customers Canceled">
            <Typography bold fontSize="36px">
              {separateNumWithComma(summary?.cancelledCustomers) || 0}
            </Typography>
          </InfoLabel>
          <InfoLabel reverse label="Users Canceled">
            <Typography bold fontSize="36px">
              {separateNumWithComma(summary?.cancelledUsers) || 0}
            </Typography>
          </InfoLabel>
        </InfoLabelWrapper>
      </Block>
    </Box>
  );
};

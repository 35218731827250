import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { cssTransition, toast, ToastContainer } from 'react-toastify';
import 'animate.css/animate.min.css';

import { RootState } from 'src/store';
import ToastTemplate from './ToastTemplate';

const bounce = cssTransition({
  enter: 'zoomIn',
  exit: 'zoomOut',
});

export const Toast = () => {
  const [currToastNum, setCurrToastQty] = useState(0);

  const { trace } = useSelector((state: RootState) => state.toast);
  useEffect(() => {
    const nextToastNum = trace.length;

    if (trace.length && nextToastNum > currToastNum) {
      setCurrToastQty(nextToastNum);

      toast(
        ({ closeToast }) => {
          return <ToastTemplate message={trace[0].message} close={closeToast} type={trace[0].type} />;
        },
        {
          autoClose: trace[0].autoCloseTime ?? 3000,
          delay: trace[0].delay,
          transition: bounce,
        },
      );
    }
  }, [currToastNum, trace]);

  return (
    <ToastContainer
      closeButton={false}
      closeOnClick={false}
      draggable={false}
      hideProgressBar
      newestOnTop={false}
      pauseOnFocusLoss={false}
      pauseOnHover={false}
      position="top-right"
      rtl={false}
      toastStyle={{
        padding: 0,
        minHeight: 'fit-content',
        maxWidth: '300px',
        backgroundColor: '#333333',
      }}
      bodyStyle={{
        padding: 0,
      }}
    />
  );
};

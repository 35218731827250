import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import Cookies from 'universal-cookie';

import { impersonateUser } from 'src/api';
import { APP_DOMAIN, COOKIE_TOKEN_NAME, MAIN_APP_URL } from 'src/constants';
import { showToast } from 'src/store';
import { IImpersonateUser } from 'src/types';

const cookies = new Cookies();

export const usePostImpersonateUser = () => {
  const dispatch = useDispatch();

  const { mutate, ...rest } = useMutation(['impersonate-user'], (data: IImpersonateUser) => impersonateUser(data), {
    onSuccess: (data) => {
      cookies.set(COOKIE_TOKEN_NAME, data.authToken, { path: '/', domain: APP_DOMAIN });
      location.assign(MAIN_APP_URL ?? '');
    },
    onError: (err) => {
      dispatch(
        showToast({
          type: 'error',
          message: (err as Error)?.message ?? 'Something went wrong',
        }),
      );
    },
  });

  return { impersonateUser: mutate, ...rest };
};

import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getNumberOfAffectedUsers } from 'src/api';
import { IGetLinkedInActionsStats, ILinkedInActionsStats } from 'src/types';

export const useGetNumberOfAffectedUsers = <TData extends ILinkedInActionsStats>(
  params: IGetLinkedInActionsStats,
  options?: UseQueryOptions<ILinkedInActionsStats, AxiosError, TData>,
) => {
  return useQuery<ILinkedInActionsStats, AxiosError, TData>(
    ['get-affected-users-number', JSON.stringify(params)],
    () => getNumberOfAffectedUsers(params),
    options,
  );
};

import { useEffect, useState } from 'react';

import { ILinkedInActionsStats, TGetLinkedInActionsFields, TSortOrder } from '../types';

export const useSortLinkedinActionsStats = (data?: ILinkedInActionsStats) => {
  const [sortOrder, setSortOrder] = useState<TSortOrder>('ASC');
  const [triggerRender, setTriggerRender] = useState(false);
  const [sortField, setSortField] = useState<TGetLinkedInActionsFields>('date');

  useEffect(() => {
    const orderFactor = sortOrder === 'DESC' ? -1 : 1;

    data?.data.sort((a, b) => {
      if (sortField === 'date') {
        return orderFactor * (new Date(a.date).getTime() - new Date(b.date).getTime());
      } else if (sortField === 'actions') {
        return orderFactor * (a.actions - b.actions);
      } else if (sortField === 'successActions') {
        return orderFactor * ((a.successActions || 0) - (b.successActions || 0));
      } else if (sortField === 'verificationActions') {
        return orderFactor * ((a.verificationActions || 0) - (b.verificationActions || 0));
      } else if (sortField === 'failedActions') {
        return orderFactor * ((a.failedActions || 0) - (b.failedActions || 0));
      } else if (sortField === 'users') {
        return orderFactor * (a.users - b.users);
      }

      return orderFactor * (a.userId - b.userId);
    });
    setTriggerRender(!triggerRender);
  }, [sortField, sortOrder, data]);

  const sortingProps = {
    sortField,
    sortOrder,
    onSortFieldChange: (field: TGetLinkedInActionsFields, order: TSortOrder) => {
      setSortField(field);
      setSortOrder(order);
    },
  };

  return { sortingProps };
};

import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { createCode } from 'src/api';
import { showToast } from 'src/store';
import { ICreateCodeReq, IGetCodesReq, IGetCodesRes } from 'src/types';

export const useCreateCode = (allCodesQueryKeys: IGetCodesReq) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const getAllCodesKeys = ['get-all-codes', JSON.stringify(allCodesQueryKeys)];

  const { mutate, ...rest } = useMutation(['create-code'], (data: ICreateCodeReq) => createCode(data), {
    onMutate: async (vars) => {
      await queryClient.cancelQueries(getAllCodesKeys);

      const prevData = queryClient.getQueryData(getAllCodesKeys);

      queryClient.setQueriesData<IGetCodesRes | undefined>(getAllCodesKeys, (data) => {
        if (data) {
          return {
            ...data,
            codes: [...data.codes, { ...vars, id: -1, isActive: true }],
          };
        }

        return data;
      });

      return { prevData };
    },
    onSuccess() {
      queryClient.invalidateQueries(getAllCodesKeys);
    },
    onError: (err, _vars, context) => {
      queryClient.setQueryData(getAllCodesKeys, context?.prevData);

      dispatch(
        showToast({
          type: 'error',
          message: (err as Error)?.message ?? 'Something went wrong',
        }),
      );
    },
  });

  return { createCode: mutate, ...rest };
};

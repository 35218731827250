import { Table, TableBody, TableHead, TableHeadCell, TableRow } from 'src/components';
import { useGetFeatureFlags } from 'src/reactQueries';
import { FeatureFlagsTableRow } from './FeatureFlagsTableRow';

export const FeatureFlagsTable = () => {
  const { data, isLoading } = useGetFeatureFlags();

  return (
    <Table placeholder={!data && 'No feature flags found.'} processing={isLoading} disabled={isLoading}>
      <TableHead>
        <TableRow>
          <TableHeadCell secondary width="100px">
            Key
          </TableHeadCell>

          <TableHeadCell secondary>Name</TableHeadCell>

          <TableHeadCell secondary>Unique Key</TableHeadCell>

          <TableHeadCell secondary>Enabled for Admins</TableHeadCell>

          <TableHeadCell secondary>Enabled for All Users</TableHeadCell>
        </TableRow>
      </TableHead>

      <TableBody>{data?.map((flag) => <FeatureFlagsTableRow key={flag.id} {...flag} />)}</TableBody>
    </Table>
  );
};

import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { useDispatch } from 'react-redux';

import { changeUserPassword } from 'src/api';
import { showToast } from 'src/store';

export const useChangeUserPassword = (userId: number, options?: UseMutationOptions<void, AxiosError, string>) => {
  const dispatch = useDispatch();

  const { mutate, ...rest } = useMutation<void, AxiosError, string>(
    ['change-password', userId],
    (password: string) => changeUserPassword(userId, password),
    {
      ...options,
      onSuccess: (data, variables, context) => {
        options?.onSuccess?.(data, variables, context);

        dispatch(
          showToast({
            type: 'success',
            message: 'Password was successfully updated.',
          }),
        );
      },
      onError: (error, variables, context) => {
        options?.onError?.(error, variables, context);

        dispatch(
          showToast({
            type: 'error',
            message: (error as Error)?.message ?? 'Something went wrong',
          }),
        );
      },
    },
  );

  return { changePassword: mutate, ...rest };
};

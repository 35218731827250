interface IResolveMembership {
  hasLinkedInPremium?: boolean;
  hasLinkedInSalesNavigator?: boolean;
}

export const resolveMembership = ({ hasLinkedInPremium, hasLinkedInSalesNavigator }: IResolveMembership) => {
  if (hasLinkedInSalesNavigator) {
    return 'Sales navigator';
  }
  if (hasLinkedInPremium) {
    return 'Premium';
  }

  return 'Basic';
};

import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import logo from 'src/assets/logo/logo_orange.svg';
import { Avatar, Tooltip } from 'src/components';
import { APP_DOMAIN, COOKIE_TOKEN_NAME, LANDING_URL, MAIN_APP_URL } from 'src/constants';
import { useGetSidebarConfig } from 'src/hooks';
import { IconHolder, SideBarIcon, SideBarMenu } from 'src/layouts/_components';
import { useGetUserAccount } from 'src/reactQueries/users/useGetUserAccount';

const cookies = new Cookies();

const AppLayoutSidebar = styled.div`
  width: var(--sidebar-width);
  height: 100vh;
  background-color: #002f53;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 0;
  left: 0;
  z-index: 10;
`;

const Logo = styled.img`
  width: 36px;
  height: 24px;
  margin: 18px auto 12px;
`;

const AvatarWrapper = styled.div`
  position: fixed;
  bottom: 18px;
  display: flex;
  width: var(--sidebar-width);
  justify-content: center;
`;

export const SideBar = () => {
  const location = useLocation();
  const queryClient = useQueryClient();

  const { userMe } = useGetUserAccount();
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const { sidebarConfig } = useGetSidebarConfig();

  const logout = () => {
    queryClient.removeQueries();
    cookies.remove(COOKIE_TOKEN_NAME, { domain: APP_DOMAIN, path: '/' });
    cookies.remove('realImpersonateUser', { domain: APP_DOMAIN, path: '/' });
    localStorage.removeItem('user');
    window.location.href = LANDING_URL;
  };

  return (
    <AppLayoutSidebar>
      <Box display="flex" flexDirection="column" gap="18px">
        <Logo src={logo} />
        {sidebarConfig.map(({ icon, activeIcon, menu, path, disabled = false }, index) => {
          const active = location.pathname.startsWith(path);
          const menuIcon = active && activeIcon ? activeIcon : icon;
          const routePath = menu?.list ? `${path}/${menu?.list[0].to}` : path;
          const iconLink = path ? routePath : '';
          const expandable = !!menu?.list?.length;

          if (disabled) {
            return (
              <Tooltip key={icon} title="You don't have permission" placement="left">
                <IconHolder>
                  <SideBarIcon icon={menuIcon} disabled={disabled} />
                </IconHolder>
              </Tooltip>
            );
          }

          if (!menu) {
            return (
              <IconHolder key={icon}>
                <SideBarIcon icon={menuIcon} active={active} disabled={disabled} linkTo={!disabled && iconLink} />
              </IconHolder>
            );
          }

          return (
            <IconHolder
              key={icon}
              onMouseOver={() => setSelectedIndex(index)}
              onMouseLeave={() => setSelectedIndex(-1)}
            >
              <SideBarIcon icon={menuIcon} active={active} expandable={expandable} linkTo={iconLink} />

              {index === selectedIndex && <SideBarMenu menu={menu} path={path} titleLink={iconLink} />}
            </IconHolder>
          );
        })}
      </Box>

      <AvatarWrapper>
        <IconHolder onMouseOver={() => setSelectedIndex(999)} onMouseLeave={() => setSelectedIndex(-1)}>
          <Avatar size="36px" src={userMe?.profilePictureUrl} pointer />

          {selectedIndex === 999 && (
            <SideBarMenu
              openTop
              menu={{
                list: [
                  {
                    label: 'Log out',
                    onClick: logout,
                  },
                  {
                    label: 'Back to Alfred',
                    onClick: () => window.location.assign(MAIN_APP_URL),
                  },
                ],
              }}
            />
          )}
        </IconHolder>
      </AvatarWrapper>
    </AppLayoutSidebar>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RecordAny = Record<string, any>;

export const sortByOrder = <K extends RecordAny>(objectArray: K[], labelKey: keyof K, order: string[]) => {
  return objectArray.sort((a, b) => {
    const aIndex = order.indexOf(a[labelKey]);
    const bIndex = order.indexOf(b[labelKey]);

    switch (true) {
      case aIndex === -1 && bIndex === -1:
        return 0;
      case aIndex === -1:
        return 0;
      case bIndex === -1:
        return 0;
      default:
        return aIndex - bIndex;
    }
  });
};

type TAddLabelTitlesResponse = ({
  title: string;
} & RecordAny)[];

export const addLabelTitles = (objectArray: RecordAny[], labels: Record<string, string>, labelKey: string) => {
  const sortOrder = Object.keys(labels);

  const sordedObject = sortByOrder(objectArray, labelKey, sortOrder);

  return sordedObject.map((object) => ({ ...object, title: labels[object[labelKey]] })) as TAddLabelTitlesResponse;
};

export const transformAnalyticMonthData = <K extends RecordAny>(objectArray?: K[], groupKey?: keyof K) => {
  if (!objectArray?.length || !groupKey) {
    return [];
  }

  const keys = Object.keys(objectArray[0]).filter((key) => key !== groupKey);

  return keys.map((key) => {
    const newObj = {
      [groupKey]: key,
    };

    objectArray.forEach((obj) => {
      const newObjectKey = String(obj[groupKey])?.toLowerCase();

      newObj[newObjectKey] = obj[key];
    });

    return newObj;
  });
};

import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { AppLayout } from 'src/layouts';

const Wrapper = styled(Box)`
  padding-top: 220px;
  padding-bottom: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: radial-gradient(rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%);
`;

const Title = styled.h3`
  font-family: 'ProximaSoft', sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 40px;
  margin-top: 50px;
  margin-bottom: 30px;
`;

export const NoPermission = () => {
  return (
    <AppLayout>
      <Wrapper>
        <Title>You don’t have permission to view the current page</Title>
      </Wrapper>
    </AppLayout>
  );
};

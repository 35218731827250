import { PropsWithChildren } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import { Breadcrumbs, Button, HeaderContainer, HeaderTitle, TabLink, TabsContainer, Typography } from 'src/components';
import { ModalTypes, Plan } from 'src/enums';
import { convertPermissionToObject } from 'src/helpers';
import { useGetTeamInfo, useGetUserAccount } from 'src/reactQueries';
import { openModal } from 'src/store';

export const TeamDetailsLayout = ({ children }: PropsWithChildren) => {
  const { teamId } = useParams();
  const { teamInfo } = useGetTeamInfo(Number(teamId));
  const { userMe } = useGetUserAccount();

  const { customPlans } = convertPermissionToObject(userMe?.adminPermissions ?? []);

  const dispatch = useDispatch();

  const onManageCustomPlan = () => {
    dispatch(
      openModal({
        type: ModalTypes.MANAGE_CUSTOM_PLAN,
        headerText: 'Manage Custom Plan',
        params: {
          teamId: Number(teamId),
        },
      }),
    );
  };

  const TEAM_DETAILS_TABS = [
    { label: 'General Information', to: 'general-information' },
    { label: 'Members', to: 'members' },
    { label: 'Stripe Info', to: 'stripe-info' },
    { label: 'Invites', to: 'invites' },
    { label: 'Referrals', to: 'referrals' },
    {
      label: 'White Label',
      to: 'whitelabel',
      hide: ![Plan.ENTERPRISE, Plan.AGENCY, Plan.TEAMS].includes(teamInfo?.plan as Plan),
    },
  ];

  return (
    <>
      <HeaderContainer>
        <Breadcrumbs>
          <Link to="/support">Support</Link>
          <Link to="/support/teams">Teams</Link>
          <Typography>Team Details</Typography>
        </Breadcrumbs>

        <Box display="flex" alignItems="center">
          <HeaderTitle my="8px">Teams</HeaderTitle>
          <Box ml="auto">
            {customPlans && teamInfo?.hasCustomPlan && <Button onClick={onManageCustomPlan}>Manage Custom Plan</Button>}
          </Box>
        </Box>

        <TabsContainer>
          {TEAM_DETAILS_TABS.map(({ label, to, hide }) => {
            const path = `/support/teams/${teamId}/${to}`;

            return (
              hide || (
                <TabLink key={to} isActive={path === location.pathname} to={path}>
                  {label}
                </TabLink>
              )
            );
          })}
        </TabsContainer>
      </HeaderContainer>
      <Box m="16px" display="grid" gap="16px">
        {children}
      </Box>
    </>
  );
};

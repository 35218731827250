import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';

import {
  Breadcrumbs,
  HeaderContainer,
  HeaderTitle,
  Table,
  TableBody,
  TableHead,
  TableHeadCell,
  TablePagination,
  TableRow,
  Typography,
} from 'src/components';
import { TeamsFilters } from 'src/containers/Support/Teams/TeamsFilters';
import { TeamsTableRow } from 'src/containers/Support/Teams/TeamsTableRow';
import { useAppSelector, useDebounce } from 'src/hooks';
import { useGetAllTeams } from 'src/reactQueries';
import { updateTeamsRequest, updateUsersRequest } from 'src/store';
import { ITablePagination, TSortOrder, TTeamsSortFields } from 'src/types';

export const Teams = () => {
  const dispatch = useDispatch();
  const teamsRequest = useAppSelector(({ team }) => team.teamsRequest);
  const { membersCount, page, perPage, search, seats, sortField, sortOrder } = teamsRequest;

  const debouncedSearch = useDebounce(search, 500);
  const debouncedSeats = useDebounce(seats, 500);
  const debouncedMembers = useDebounce(membersCount, 500);

  const { allTeams, isLoading } = useGetAllTeams({
    ...teamsRequest,
    search: debouncedSearch,
    seats: debouncedSeats,
    membersCount: debouncedMembers,
  });
  const { teams = [], total } = allTeams ?? {};

  useEffect(() => {
    dispatch(updateUsersRequest({ page: 1 }));
  }, [debouncedSearch, debouncedSeats, debouncedMembers]);

  const sortingProps = {
    sortField,
    sortOrder,
    onSortFieldChange: (sortField: TTeamsSortFields, sortOrder: TSortOrder) => {
      dispatch(updateTeamsRequest({ sortOrder, sortField }));
    },
  };

  const paginationChangeHandler = (pagination: ITablePagination) => {
    dispatch(updateTeamsRequest(pagination));
  };

  return (
    <>
      <HeaderContainer pb="15px">
        <Breadcrumbs>
          <Link to="/support">Support</Link>
          <Typography>Teams</Typography>
        </Breadcrumbs>
        <Box mt="8px">
          <HeaderTitle>Teams</HeaderTitle>
        </Box>
      </HeaderContainer>
      <Box m="16px" display="grid" gap="16px">
        <TeamsFilters />

        <Table placeholder={!total && 'No teams found'} processing={isLoading} disabled={isLoading}>
          <TableHead>
            <TableRow>
              <TableHeadCell secondary width="128px" name="name" sorting={sortingProps}>
                Team Name
              </TableHeadCell>
              <TableHeadCell secondary name="owner" sorting={sortingProps}>
                Owner
              </TableHeadCell>
              <TableHeadCell secondary width="142px" name="subscription" sorting={sortingProps}>
                Subscription
              </TableHeadCell>
              <TableHeadCell secondary width="74px" name="hasPaidPlan" sorting={sortingProps}>
                Is active subs?
              </TableHeadCell>
              <TableHeadCell secondary width="80px" name="isTrialTeam" sorting={sortingProps}>
                Is trial team?
              </TableHeadCell>
              <TableHeadCell secondary width="80px" name="isSubscriptionPaused" sorting={sortingProps}>
                Is paused?
              </TableHeadCell>
              <TableHeadCell secondary width="64px" name="seats" sorting={sortingProps}>
                Seats
              </TableHeadCell>
              <TableHeadCell secondary width="70px" name="membersCount" sorting={sortingProps}>
                Members
              </TableHeadCell>
              <TableHeadCell secondary width="180px" name="stripeId" sorting={sortingProps}>
                Stripe ID
              </TableHeadCell>
              <TableHeadCell secondary width="65px" name="trialEndsAt" sorting={sortingProps}>
                Trial Days
              </TableHeadCell>
              <TableHeadCell secondary width="87px" name="currency" sorting={sortingProps}>
                Currency
              </TableHeadCell>
              <TableHeadCell secondary width="84px" name="isTrialExtended" sorting={sortingProps}>
                Is Trial Extended?
              </TableHeadCell>
              <TableHeadCell secondary width="112px" name="nextInvoiceCreatedAt" sorting={sortingProps}>
                Next Invoice
              </TableHeadCell>
              <TableHeadCell secondary width="207px" name="" sorting={sortingProps}>
                Actions
              </TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teams.map((team) => (
              <TeamsTableRow key={team.id} team={team} />
            ))}
          </TableBody>
        </Table>

        <TablePagination page={page} perPage={perPage} total={total} onPaginationChange={paginationChangeHandler} />
      </Box>
    </>
  );
};
